//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_PAISES = "obtenerPaises";
export const OBTENER_PAISES_FUENTES_BILATERALES = "obtenerPaisesFuentesBilaterales";
export const OBTENER_PAIS = "obtenerPais";
export const REGISTRAR_PAIS = "registrarPais";
export const ACTUALIZAR_PAIS = "actualizarPais";

// mutation types
export const SET_PAISES = "setPaises";
export const SET_PAIS = "setPais";

const state = {
  paises: [],
  pais: []
};

const getters = {
  obtnenerPaises(state) {
    return state.paises;
  }
};


const actions = {
  async [OBTENER_PAISES](context) {
    return new Promise(resolve => {
        
            ApiService.query("apiconsume/obtener?endpoint=Pais/all/1", '')
            .then(({ data }) => {
                context.commit(SET_PAISES, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: error
              })
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
    });
  },

  async [OBTENER_PAISES_FUENTES_BILATERALES](context) {
    return new Promise(resolve => {
        
            ApiService.query("apiconsume/obtener?endpoint=Pais/FuenteBilateral", '')
            .then(({ data }) => {
                context.commit(SET_PAISES, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: error
              })
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
    });
  },

  

  async [OBTENER_PAIS](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=api/Institucion/`)
        .then(({ data }) => {
          context.commit(SET_PAIS, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una pais directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PAIS](context, datosInstitucion) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=api/Institucion`,
        datosInstitucion
      )
        .then(({ data }) => {
          context.commit(SET_PAIS, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_PAIS](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=api/Institucion/`,
        datos.datosInstitucion
      )
        .then(({ data }) => {
          context.commit(SET_PAIS, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_PAISES](state, data) {
    state.paises = data;
  },

  [SET_PAIS](state, data) {
    state.pais = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
