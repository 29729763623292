//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_OBJETIVOS_ESTRATEGICOS_PGG =
  "obtenerObjetivosEstrategicosPGG";
export const ELIMINAR_OBJETIVO_ESTRATEGICO_PGG =
  "eliminarObjetivoEstrategicoPGG";
export const OBTENER_OBJETIVO_ESTRATEGICO_PGG = "obtenerObjetivoEstrategicoPGG";
export const REGISTRAR_OBJETIVO_ESTRATEGICO_PGG =
  "registrarObjetivoEstrategicoPGG";
export const ACTUALIZAR_OBJETIVO_ESTRATEGICO_PGG =
  "actualizarObjetivoEstrategicoPGG";

// mutation types
export const SET_OBJETIVO_ESTRATEGICO_PGG = "setObjetivosEstrategicosPGG";
export const SET_OBJETIVOS_ESTRATEGICOS_PGG = "setObjetivoEstrategicoPGG";

const state = {
  objetivosEstrategicosPGG: {},
  objetivoEstrategicoPGG: []
};

const getters = {
  obtnenerObjetivosEstrategicosPGG(state) {
    return state.objetivosEstrategicosPGG;
  }
};

const actions = {
  async [OBTENER_OBJETIVOS_ESTRATEGICOS_PGG](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ObjetivosEstrategicosPGG/all/${parametros.estadoId}/${parametros.metaPggId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_OBJETIVO_ESTRATEGICO_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_OBJETIVO_ESTRATEGICO_PGG](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=ObjetivosEstrategicosPGG/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_OBJETIVOS_ESTRATEGICOS_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_OBJETIVO_ESTRATEGICO_PGG](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=ObjetivosEstrategicosPGG/`)
        .then(({ data }) => {
          context.commit(SET_OBJETIVOS_ESTRATEGICOS_PGG, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una Pggs directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_OBJETIVO_ESTRATEGICO_PGG](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=ObjetivosEstrategicosPGG`, datos)
        .then(({ data }) => {
          context.commit(SET_OBJETIVOS_ESTRATEGICOS_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_OBJETIVO_ESTRATEGICO_PGG](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=ObjetivosEstrategicosPGG/`, datos)
        .then(({ data }) => {
          context.commit(SET_OBJETIVOS_ESTRATEGICOS_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_OBJETIVO_ESTRATEGICO_PGG](state, data) {
    state.objetivoEstrategicoPGG = data;
  },

  [SET_OBJETIVOS_ESTRATEGICOS_PGG](state, data) {
    state.objetivosEstrategicosPGG = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
