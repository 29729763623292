//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_COBERTURAS_CSS = "obtenerCoberturas";
export const REGISTRAR_COBERTURA_CSS = "registrarCobertura";
export const ELIMINAR_COBERTURA_CSS = "eliminarCoberturaCss";

//Cobertura de alianzas
export const OBTENER_COBERTURAS_ALIANZAS = "obtenerCoberturasAlianzas";

export const SET_COBERTURAS_CSS = "setCoberturas";
export const SET_COBERTURA_CSS = "setRCobertura";

const state = {
    coberturas: [],
    cobertura: []
};

const getters = {
  obtnenerCoberturasCSS(state) {
    return state.coberturas;
  }
};


const actions = {

    async [OBTENER_COBERTURAS_CSS](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
     
            ApiService.query(`apiconsume/obtener?endpoint=Cobertura/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_COBERTURAS_CSS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


    async [OBTENER_COBERTURAS_ALIANZAS](context, parametros){
      return new Promise(resolve => {

          let id = parametros.id;
   
          ApiService.query(`apiconsume/obtener?endpoint=Cobertura/all/alianza/1/${id}`)
          .then(({ data }) => {
              context.commit(SET_COBERTURAS_CSS, data.data);
              
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
              
              //context.commit(SET_ERROR, response.data.errors);
          });
              
      });
  },


   /**
   * Registrar un cobertura
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_COBERTURA_CSS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=Cobertura`, datos)
            .then(({ data }) => {
                context.commit(SET_COBERTURA_CSS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  
  async [ELIMINAR_COBERTURA_CSS](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=Cobertura/`)
          .then(({ data }) => {
              context.commit(SET_COBERTURA_CSS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
}

};

const mutations = {
  [SET_COBERTURAS_CSS](state, data) {
    state.coberturas = data;
  },

  [SET_COBERTURA_CSS](state, data) {
    state.cobertura = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

