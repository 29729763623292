//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_ESTRATEGIAS_CNSC = "obtenerEstrategias";
export const OBTENER_ESTRATEGIAS_COOPERANTE_CNSC = "obtenerEstrategiasCooperante";
export const OBTENER_ESTRATEGIA_CNSC = "obtenerEstrategia";

export const REGISTRAR_ESTRATEGIA_CNSC = "registrarEstrategia";
export const ACTUALIZAR_ESTRATEGIA_CNSC = "actualizarEstrategia";
export const VALIDAR_ESTRATEGIA_CNSC = "validarEstrategiaCNSC";


// mutation types
export const SET_ESTRATEGIAS_CNSC = "setEstrategias";
export const SET_ESTRATEGIA_CNSC = "setEstrategia";


const state = {
  estrategias: [],
  estrategia: {}
};

const getters = {
  obtnenerEstrategias(state) {
    return state.estrategias;
  }
};

const actions = {
  async [OBTENER_ESTRATEGIAS_CNSC](context, parametros) {
    return new Promise(resolve => {
        let endpoint = `EstrategiasCNSC/all/${parametros.estadoId}/${parametros.tipoFuenteCooperante}/${parametros.fuentesBilateralesId}/${parametros.agenciasId}/${parametros.fuentesMultilateralesId}/${parametros.cooperanteId}`;
        

      ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
        .then(({ data }) => {
          context.commit(SET_ESTRATEGIAS_CNSC, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },


  //Obtener las estrategias de un usuario cooperante
  //Tipo fuente Cooperante y Fuente Cooperante BI  o Multi
  async [OBTENER_ESTRATEGIAS_COOPERANTE_CNSC](context, parametros) {
    return new Promise(resolve => {
        let endpoint = `EstrategiasCNSC/Cooperante/all/${parametros.estadoId}/${parametros.tipoFuenteCooperante}/${parametros.fuenteCooperanteId}`;
        

      ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
        .then(({ data }) => {
          context.commit(SET_ESTRATEGIAS_CNSC, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [OBTENER_ESTRATEGIA_CNSC](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=EstrategiasCNSC/`)
        .then(({ data }) => {
          context.commit(SET_ESTRATEGIA_CNSC, data.data);
          //console.log(this.Estrategias)
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });;
        });
    });
  },

  

  /**
   * Registrar una institución
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_ESTRATEGIA_CNSC](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EstrategiasCNSC`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_ESTRATEGIA_CNSC, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [ACTUALIZAR_ESTRATEGIA_CNSC](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=EstrategiasCNSC/`, datos)
        .then(({ data }) => {
          context.commit(SET_ESTRATEGIA_CNSC, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [VALIDAR_ESTRATEGIA_CNSC](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=EstrategiasCNSC/Estado/`, datos)
        .then(({ data }) => {
          context.commit(SET_ESTRATEGIA_CNSC, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },


  
 

};

const mutations = {
  [SET_ESTRATEGIAS_CNSC](state, data) {
    state.estrategias = data;
  },

  [SET_ESTRATEGIA_CNSC](state, data) {
    state.estrategia = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
