//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_AGENCIAS_BILATERALES = "obtenerAgenciasBilaterales";
export const OBTENER_AGENCIA_BILATERAL = "obtenerAgenciaBilateral";
export const REGISTRAR_AGENCIA_BILATERAL = "registrarAgenciaBilateral";
export const ACTUALIZAR_AGENCIA_BILATERAL = "actualizarAgenciaBilateral";

// mutation types
export const SET_AGENCIAS_BILATERALES = "setAgenciasBilaterales";
export const SET_AGENCIA_BILATERAL = "setAgenciasBilateral";

const state = {
  agenciasBilaterales: [],
  agenciaBilateral: []
};

const getters = {
  obtnenerAgenciasBilaterales(state) {
    return state.agenciasBilaterales;
  }
};


const actions = {
  async [OBTENER_AGENCIAS_BILATERALES](context, parametros) {
    return new Promise(resolve => {
        
        let endpoint = `Agencias`;
        if(parametros && parametros.estadoId && parametros.fuentesBilateralesId){
            endpoint = `Agencias/all/${parametros.estadoId}/${parametros.fuentesBilateralesId}`;
        }


      ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
        .then(({ data }) => {
          context.commit(SET_AGENCIAS_BILATERALES, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
              status: 400,
              message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },


  
  async [OBTENER_AGENCIA_BILATERAL](context, id) {
    return new Promise(resolve => {

      //context.commit(SET_AGENCIA_BILATERAL, agenciaBilateral);

      

      
      ApiService.query(`apiconsume/edit/${id}?endpoint=Agencias/`)
        .then(({ data }) => {
          context.commit(SET_AGENCIA_BILATERAL, data.data);
          //console.log(this.agenciasBilaterales)
          resolve(data);
        })
        .catch(error => {
          resolve({
          status: 500,
          message:error,
          others: id,
          data: error
        });
        });
    });
  },

 

  /**
   * Registrar una institución
   * @param {*} context
   * @param {*} datosagenciaBilateral
   * @returns
   */
  async [REGISTRAR_AGENCIA_BILATERAL](context, datosagenciaBilateral) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=Agencias`,
        datosagenciaBilateral
      )
        .then(({ data }) => {
          context.commit(SET_AGENCIA_BILATERAL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
              status: 400,
              message: error.message
          });
        });
    });
  },

  async [ACTUALIZAR_AGENCIA_BILATERAL](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=Agencias/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_AGENCIA_BILATERAL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
              status: 400,
              message: error.message
          });
        });
    });
  }
};

const mutations = {
  [SET_AGENCIAS_BILATERALES](state, data) {
    state.agenciasBilaterales = data;
  },

  [SET_AGENCIA_BILATERAL](state, data) {
    state.agenciaBilateral = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
