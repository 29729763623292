export const Role = {
  Administrador: "SCO$ADMINISTRADOR",
  SubAdministrador: "SCO$SUBADMINISTRADOR",
  SeguimientoCNS: "SCO$SEGUIMIENTOCNS",
  SeguimientoCSS: "SCO$SEGUIMIENTOCSS",
  SeguimientoAlianzas: "SCO$SEGUIMIENTOALIANZAS",
  SeguimientoCNSC: "SCO$SEGUIMIENTOCNSC",
  SeguimientoActoresLocales: "SCO$SEGUIMIENTOACTLOCALES",
  SeguimientoCooperacionDescentralizada: "SCO$SEGUIMIENTOCOODESC",

  //Externos institucionales
  InstitucionCns: "SCO$INSTCNS",
  InstitucionCSSD: "SCO$INSTCSSD",
  InstitucionCSSC: "SCO$INSTCSSC",
  InstitucionAlianzas: "SCO$INSTALIANZAS",

  /*----*/
  AdminAlianzas: "SCO$ADMINALIANZAS",

  UnidadEjecutora: "SCO$UNIDADEJECUTORA",
  NorteSur: "NorteSur",
  CooperanteBi: "SCO$COOPERANTEBI",
  CooperanteMulti: "SCO$COOPERANTEMULTI",

  SurSur: "SurSur",
  CCCI: "CCCI",
  Especialista: "Especialista",
  Udaf: "SCO$UDAF"
};
