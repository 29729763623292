//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_ARTICULACIONES = "obtenerArticulaciones";
export const OBTENER_ARTICULACION = "obtenerArticulacion";
export const REGISTRAR_ARTICULACION = "registrarArticulacion";
export const ACTUALIZAR_ARTICULACION = "actualizarAticulacion";

// mutation types
export const SET_ARTICULACIONES = "setArticulaciones";
export const SET_ARTICULACION = "setArticulacion";

const state = {
  articulaciones: [],
  articulacion: {},
};

const getters = {
  obtnenerArticulaciones(state) {
    return state.articulaciones;
  },
};

const actions = {
  async [OBTENER_ARTICULACIONES](context, parametros) {
    return new Promise((resolve) => {
      ApiService.query(
        `apiconsume/obtener?endpoint=Articulaciones/all/${parametros.estadoId}/${parametros.negociacionId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_ARTICULACIONES, data.data);

          resolve(data);
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: error,
            data: null,
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /*
   * Obtiene los datos de articulaciones de la iniciativa id
   */
  async [OBTENER_ARTICULACION](context, id) {
    return new Promise((resolve) => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=Articulaciones/`)
        .then(({ data }) => {
          context.commit(SET_ARTICULACION, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch((error) => {
          resolve(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una negociacion directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_ARTICULACION](context, datos) {
    return new Promise((resolve) => {
      ApiService.post(`apiconsume/create?endpoint=Articulaciones`, datos)
        .then(({ data }) => {
          context.commit(SET_ARTICULACION, data.data);
          resolve(data);
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error,
          });
        });
    });
  },

  async [ACTUALIZAR_ARTICULACION](context, datos) {
    let id = datos.id;

    return new Promise((resolve) => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=Articulaciones/`,
        datos.datosItem
      )
        .then(({ data }) => {
          context.commit(SET_ARTICULACION, data.data);
          resolve(data);
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error,
          });
        });
    });
  },
};

const mutations = {
  [SET_ARTICULACIONES](state, data) {
    state.articulaciones = data;
  },

  [SET_ARTICULACION](state, data) {
    state.articulacion = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
