//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const REGISTRAR_SOLICITUD_CAMBIO_ESTADO_CNS =
  "registrarSolicitudCambioEstadoCns";

export const OBTENER_SOLICITUDES_CAMBIO_ESTADO_CNS =
  "obtenerSolicitudesCambioEstadoCns";

export const OBTENER_SOLICITUD_CAMBIO_ESTADO_CNS =
  "obtenerSolicitudCambioEstadoCns";

export const ELIMINAR_SOLICITUD_CAMBIO_ESTADO_CNS =
  "eliminarSolicitudCambioEstadoCns";

export const OPERAR_SOLICITUD_CAMBIO_ESTADO_CNS =
  "operarSolicitudCambioEstadoCns";

export const SET_SOLICITUD_CAMBIO_ESTADO_CNS = "setSolicitudCambioEstadoCns";
export const SET_SOLICITUDES_CAMBIO_ESTADO_CNS = "setSolicitudesCambioEstadoCn";

const state = {
  solicitudesCambioEstadoCns: [],
  solicitudCambioEstadoCns: {}
}

const actions = {
  /**
   * Obtener las solicitudes cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_SOLICITUDES_CAMBIO_ESTADO_CNS](context, parametros) {
    return new Promise(resolve => {
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/SolicitudesEstadoCNS/all/${parametros.tipoSolicitud}/${estadoId}`
      )
        .then(({ data }) => {
          context.commit(SET_SOLICITUDES_CAMBIO_ESTADO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Obtener planificacion (periodos) anual por id
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_SOLICITUD_CAMBIO_ESTADO_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=/SolicitudesEstadoCNS/${id}`
      )
        .then(({ data }) => {
          context.commit(SET_SOLICITUD_CAMBIO_ESTADO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar solicitud cambio estado cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_SOLICITUD_CAMBIO_ESTADO_CNS](context, parametros) {
    let datos = parametros;
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=SolicitudesEstadoCNS`, datos)
        .then(({ data }) => {
          context.commit(SET_SOLICITUD_CAMBIO_ESTADO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [OPERAR_SOLICITUD_CAMBIO_ESTADO_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=SolicitudesEstadoCNS/Operar/${parametros.solicitudId}`,
        parametros
      )
        .then(({ data }) => {
          context.commit(SET_SOLICITUD_CAMBIO_ESTADO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar una solicitud de cambio de estado
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_SOLICITUD_CAMBIO_ESTADO_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=SolicitudesEstadoCNS/`
      )
        .then(({ data }) => {
          context.commit(SET_SOLICITUD_CAMBIO_ESTADO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  }
};

const mutations = {
  [SET_SOLICITUD_CAMBIO_ESTADO_CNS](state, data) {
    state.solicitudesCambioEstadoCns = data;
  },

  [SET_SOLICITUDES_CAMBIO_ESTADO_CNS](state, data) {
    state.solicitudCambioEstadoCns = data;
  }
};

export default {
  state,
  actions,
  mutations
};
