//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_ROLES = "obtenerRoles";

export const OBTENER_ROL = "obtenerRol";


// mutation types
export const SET_ROLES = "setRoles";
export const SET_ROL = "setRol";

const state = {
  roles: [],
  rol: []
};

const getters = {
  obtenerRoles(state) {
    return state.roles;
  }
};


const actions = {
  async [OBTENER_ROLES](context, parametros) {
    return new Promise(resolve => {
        
            ApiService.query(`apiconsume/obtener?endpoint=Roles/all/${parametros.estadoId}/${parametros.tipoRol}`, '')
            .then(({ data }) => {
                context.commit(SET_ROLES, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: error
              })
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
    });
  },

  

  async [OBTENER_ROL](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=api/Roles/`)
        .then(({ data }) => {
          context.commit(SET_ROL, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },



};

const mutations = {
  [SET_ROLES](state, data) {
    state.roles = data;
  },

  [SET_ROL](state, data) {
    state.rol = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
