//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_PNDS = "obtenerPnds";
export const ELIMINAR_PNDS = "eliminarPnd";
export const OBTENER_PND = "obtenerPnd";
export const REGISTRAR_PND = "registrarPnd";
export const ACTUALIZAR_PND = "actualizarPnd";

// mutation types
export const SET_PNDS = "setPnds";
export const SET_PND = "setPnd";

const state = {
  pnd: {},
  pnds: []
};

const getters = {
  obtnenerPnd(state) {
    return state.pnd;
  }
};

const actions = {
  async [OBTENER_PNDS](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=Pnd/all/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PNDS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_PNDS](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=Pnd/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_PND, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_PND](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=Pnd/`)
        .then(({ data }) => {
          context.commit(SET_PND, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una Pnds directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PND](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=Pnd`, datos)
        .then(({ data }) => {
          context.commit(SET_PND, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_PND](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=Pnd/`, datos)
        .then(({ data }) => {
          context.commit(SET_PND, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_PNDS](state, data) {
    state.pnds = data;
  },

  [SET_PND](state, data) {
    state.pnd = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
