//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_METAS_ODS = "obtenerMetasODS";
export const OBTENER_META_ODS = "obtenerMetaOds";
export const REGISTRAR_META_ODS = "registrarMetaOds";
export const ELIMINAR_META_ODS = "eliminarMetaOds";
export const ACTUALIZAR_META_ODS = "actualizarMetaOds";

// mutation types
export const SET_METAS_ODS = "setMetasODS";
export const SET_META_ODS = "setMetaOds";

const state = {
  metaOds: {},
  metasOds: []
};

const getters = {
  obtnenerMetaODS(state) {
    return state.metaOds;
  }
};

const actions = {
  async [OBTENER_METAS_ODS](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=MetasODS/all/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_METAS_ODS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_META_ODS](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=MetasODS/`)
        .then(({ data }) => {
          context.commit(SET_META_ODS, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una MetasODS directa
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_META_ODS](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=MetasODS`, datos)
        .then(({ data }) => {
          context.commit(SET_META_ODS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_META_ODS](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=MetasODS/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_META_ODS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_META_ODS](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=MetasODS/`, datos)
        .then(({ data }) => {
          context.commit(SET_META_ODS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_METAS_ODS](state, data) {
    state.metasOds = data;
  },

  [SET_META_ODS](state, data) {
    state.metaOds = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
