//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_ASIGNACIONES_ESPECIALISTA_CDES =
  "obtenerasignacionesSeguimientoCoDesc";
export const OBTENER_ASIGNACIONES_ADMINISTRADOR_CDES =
  "obtenerAsignacionesSeguimientoAdministradorCoDesc";
export const OBTENER_ASIGNACION_ESPECIALISTA_CDES = "obtenerasignacionSeguimientoCoDesc";
export const REGISTRAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_CDES =
  "registrarAsignacionSeguimientoCoDesc";
export const ACTUALIZAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_CDES =
  "actualizarAsignacionSeguimientoCoDesc";
export const ELIMINAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_CDES =
  "eliminarAsignacionSeguimientoCoDesc";

export const SET_ASIGNACIONES_SEGUIMIENTOS_CDES = "setAsignacioneseguimientoCoDesc";
export const SET_ASIGNACION_SEGUIMIENTO_CDES = "setAsignacionSeguimientoCoDesc";

const state = {
  asignacionSeguimientoCdes: {},
  asignacionesSeguimientoCdes: []
};

const getters = {
  obtnenerasignacionSeguimientoCdes(state) {
    return state.asignacionesSeguimientoCdes;
  }
};

const actions = {
  /*
   * Obtener las asignados especificas de un usuario
   */
  async [OBTENER_ASIGNACIONES_ESPECIALISTA_CDES](context, parametros) {
    return new Promise(resolve => {
      let id = parametros.proyectoId;
      ApiService.query(
        `apiconsume/obtener?endpoint=AsignacionSeguimientoCoDesc/Especialista/0/${id}`
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACIONES_SEGUIMIENTOS_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /*
   * Obtener todas las asignados para el administrador 
   */
  async [OBTENER_ASIGNACIONES_ADMINISTRADOR_CDES](context, parametros) {
    return new Promise(resolve => {
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=AsignacionSeguimientoCoDesc/all/${estadoId}`
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACIONES_SEGUIMIENTOS_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /*
   *Obtener la asignacion especifica de un especialista
   */
  async [OBTENER_ASIGNACION_ESPECIALISTA_CDES](context, usuario) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=AsignacionSeguimientoCoDesc/Usuario/${usuario}`
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACION_SEGUIMIENTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
        });
    });
  },

  /**
   * Registrar una Asignacion de seguimiento
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_CDES](context, parametros) {
    console.log(parametros)
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=AsignacionSeguimientoCoDesc`, datos)
        .then(({ data }) => {
          context.commit(SET_ASIGNACION_SEGUIMIENTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [ACTUALIZAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_CDES](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=AsignacionSeguimientoCoDesc/${parametros.id}`,
        parametros
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACION_SEGUIMIENTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /*
   * Eliminar un AsignacionSeguimientoCoDesc
   */
  async [ELIMINAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_CDES](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=AsignacionSeguimientoCoDesc/`
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACION_SEGUIMIENTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  }
};

const mutations = {
  [SET_ASIGNACIONES_SEGUIMIENTOS_CDES](state, data) {
    state.asignacionesSeguimientoCdes = data;
  },

  [SET_ASIGNACION_SEGUIMIENTO_CDES](state, data) {
    state.asignacionSeguimientoCdes = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
