//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_ENMIENDAS_FECHAS_CNS = "obtenerenmiEndasFechasCns";
export const REGISTRAR_ENMIENDA_FECHAS_CNS = "registrarEnmiendaFechasCns";
export const ELIMINAR_ENMIENDA_FECHAS_CNS = "eliminarEnmiendaFechasCns";
export const OBTENER_ENMIENDA_FECHAS_CNS = "obtenerEnmiendaFechasCns";
export const ACTUALIZAR_ESTADO_ENMIENDA_FECHA_CNS =
  "actualizarEstadoEnmiendaFechasCns";
export const CONFIRMAR_ENMIENDA_FECHAS_CNS = "validarEnmiendasFechasCns";

export const SET_ENMIENDAS_FECHAS_CNS = "setEnmiendasFechasCns";
export const SET_ENMIENDA_FECHAS_CNS = "setEnmiendaFechasCns";

const state = {
  enmiendaFechas: {},
  enmiendasFechas: []
};

const getters = {
  obtnenerEnmiendasFechasCns(state) {
    return state.enmiendasFechas;
  }
};

const actions = {
  //OBTENER LOS MECANISMOS DE EJECUCION REGISTRADOS DEL PROYECTO
  async [OBTENER_ENMIENDAS_FECHAS_CNS](context, parametros) {
    return new Promise(resolve => {
      let id = parametros.id;
      let estadoId = parametros.estadoId;
      let rol = parametros.rol;
      //console.log(id + " " + estadoId);

      ApiService.query(
        `apiconsume/obtener?endpoint=EnmiendaFechasCNS/all/${estadoId}/${id}/${rol}`
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDAS_FECHAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //REGUSTRAR MECANISMO DE EJECUCION
  async [REGISTRAR_ENMIENDA_FECHAS_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EnmiendaFechasCNS`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_FECHAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //Obtener el avance del proyecto CNS
  async [OBTENER_ENMIENDA_FECHAS_CNS](context, parametros) {
    let id = parametros.id;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=EnmiendaFechasCNS/`)
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_FECHAS_CNS, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /*
   * Eliminar un mecanismo de ejecucion del proyecto
   */
  async [ELIMINAR_ENMIENDA_FECHAS_CNS](context, datos) {
    let id = datos.id;
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=EnmiendaFechasCNS/`)
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_FECHAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /*
   * Actualizar el estado de una enmienda de fechas
   */
  async [ACTUALIZAR_ESTADO_ENMIENDA_FECHA_CNS](context, datos) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${datos.estadoId}?endpoint=EnmiendaFechasCNS/ActualizarEstado/${datos.id}/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_FECHAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [CONFIRMAR_ENMIENDA_FECHAS_CNS](context, datos) {
    let id = datos.id;
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=EnmiendaFechasCNS/Confirmar/`, datos)
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_FECHAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },
};

const mutations = {
  [SET_ENMIENDA_FECHAS_CNS](state, data) {
    state.enmiendaFechas = data;
  },

  [SET_ENMIENDAS_FECHAS_CNS](state, data) {
    state.enmiendasFechas = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
