const ID_TOKEN_KEY = "id_token";
const ID_ROLES_KEYS = "id_roles";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};


export const getRoles = () => {
  return window.localStorage.getItem(ID_ROLES_KEYS);
};

export const saveRoles = roles => {
  window.localStorage.setItem(ID_ROLES_KEYS, JSON.stringify(roles));
};

export const destroyRoles = () => {
  window.localStorage.removeItem(ID_ROLES_KEYS);
};

/**** Quitar al implementar la autenticación **********/
export const saveUserData = data => {
  window.localStorage.setItem("data",  JSON.stringify(data));
};

export const removeUserData = () => {
  window.localStorage.removeItem("data");
};

export const getUserData = () => {
  return window.localStorage.getItem("data");
};

/**** Quitar al implementar la autenticación **********/

export default { getToken, saveToken, destroyToken, saveRoles, getRoles, destroyRoles, saveUserData, removeUserData, getUserData };
