//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_ENMIENDAS_MONTOS_CNS = "obtenerEnmiendasMontosCns";
export const REGISTRAR_ENMIENDA_MONTOS_CNS = "registrarEnmiendaMontosCns";
export const ELIMINAR_ENMIENDA_MONTOS_CNS = "eliminarEnmiendaMontosCns";
export const OBTENER_ENMIENDA_MONTOS_CNS = "obtenerEnmiendaMontosCns";
export const VALIDAR_ENMIENDA_MONTOS_CNS = "validarEnmiendaMontosCns";
export const ACTUALIZAR_ESTADO_ENMIENDA_MONTOS_CNS =
  "actualizarEstadoEnmiendaMontosCns";

export const OBTENER_DATOS_FINANCIEROS_ENMIENDA_CNS =
  "obtenerDatosFinancierosEnmiendaCns";
export const REGISTRAR_DATO_FINANCIERO_ENMIENDA_CNS =
  "registrarDatosFinancierosEnmiendaCns";

export const ELIMINAR_DATO_FINANCIERO_ENMIENDA_CNS =
  "eliminarDatoFinancieroEnmiendaMontosCns";

export const OBTENER_SUBDIVISIONES_FC_ENMIENDA_CNS =
  "obtenerSubdivisionesFCMontosEnmiendaCns";

export const REGISTRAR_SUBDIVISION_FC_ENMIENDA_CNS =
  "registrarSubdivisionFCMontosEnmiendaCns";
export const ELIMINAR_SUBDIVISION_FC_ENMIENDA_CNS =
  "eliminarSubdivisionFCEnmiendasMontosCns";

export const OBTENER_SUBDIVISIONES_CP_ENMIENDA_CNS =
  "obtenerSubdivisionContrapartidaEnmiendaMontosCns";

export const REGISTRAR_SUBDIVISION_CP_ENMIENDA_CNS =
  "registrarSubdivisionContrapartidaEnmiendaMontosCns";

export const ELIMINAR_SUBDIVISION_CP_ENMIENDA_CNS =
  "eliminarSubdivisionContrapartidaEnmiendaMontosCns";

export const SET_ENMIENDAS_MONTOS_CNS = "setEnmiendasMontosCns";
export const SET_ENMIENDA_MONTOS_CNS = "setEnmiendaMontosCns";
export const SET_DATOS_FINANCIEROS_ENMIENDA_MONTOS_CNS ="setDatosFinancierosEnmiendaMontosCns";
export const SET_DATO_FINANCIERO_ENMIENDA_MONTOS_CNS =
  "setDatosFinancierosEnmiendaMontosCns";

export const SET_SUBDIVISIONES_FC_ENMIENDA_MONTOS_CNS =
  "setSubdivisionesFuenteCooperanteEnmiendaMontosCns";
export const SET_SUBDIVISION_FC_ENMIENDA_MONTOS_CNS =
  "setSubdivisionFuenteCooperanteEnmiendaMontosCns";

const state = {
  enmiendaMontos: {},
  enmiendasMontos: [],
  datoFinancieroEnmienda: {},
  datosFinancierosEnmienda: [],
  subdivisionFuenteCooperanteEnmienda: {},
  subdivisionesFuenteCooperanteEnmienda: []
};

const getters = {
  obtnenerEnmiendasMontosCns(state) {
    return state.enmiendasMontos;
  }
};

const actions = {
  //OBTENER LOS MECANISMOS DE EJECUCION REGISTRADOS DEL PROYECTO
  async [OBTENER_ENMIENDAS_MONTOS_CNS](context, parametros) {
    return new Promise(resolve => {
      let id = parametros.id;
      let estadoId = parametros.estadoId;
      //console.log(id + " " + estadoId);
      ApiService.query(
        `apiconsume/obtener?endpoint=EnmiendasMontosCns/all/${estadoId}/${id}`
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDAS_MONTOS_CNS, data.data);
          resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

    //REGUSTRAR MECANISMO DE EJECUCION
    async [REGISTRAR_ENMIENDA_MONTOS_CNS](context, parametros){
        return new Promise(resolve => {
          ApiService.post(`apiconsume/create?endpoint=EnmiendasMontosCns`, parametros.datos)
          .then(({ data }) => {
              context.commit(SET_ENMIENDA_MONTOS_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  data: error})
              //context.commit(SET_ERROR, response.data.errors);
          });
        });   
    },


     //Obtener el avance del proyecto CNS

    async [OBTENER_ENMIENDA_MONTOS_CNS](context, parametros) {

        let id = parametros.id;
        // console.log(datos)
        
        return new Promise(resolve => {

        ApiService.query(`apiconsume/edit/${id}?endpoint=EnmiendasMontosCns/`)
            .then(({ data }) => {
                context.commit(SET_ENMIENDA_MONTOS_CNS,data.data);
                //console.log(this.instituciones)
                resolve(data)
            })
            .catch((error) => {
                
                resolve({
                    status: 400,
                    data: error})
                //context.commit(SET_ERROR, response.data.errors);
            });
        });
    },


    async [VALIDAR_ENMIENDA_MONTOS_CNS](context, datos) {
        let id = datos.id;
    
        return new Promise(resolve => {
          ApiService.update(
            `apiconsume/update/${id}?endpoint=EnmiendasMontosCns/Validar/`, datos)
            .then(({ data }) => {
              context.commit(SET_ENMIENDA_MONTOS_CNS, data.data);
              resolve(data);
            })
            .catch(error => {
              resolve({
                  status: 400,
                  message: error.message
              });
            });
        });
      },
  

    /*
  * Eliminar un mecanismo de ejecucion del proyecto
  */
    async [ELIMINAR_ENMIENDA_MONTOS_CNS](context, datos){
    
    let id = datos.id;
      return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=EnmiendasMontosCns/`)
          .then(({ data }) => {
              context.commit(SET_ENMIENDA_MONTOS_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
      });
  },  

  //Obtener los datos financieros de la enmienda de montos
  async [OBTENER_DATOS_FINANCIEROS_ENMIENDA_CNS](context, parametros){
    return new Promise(resolve => {

        let id = parametros.id;
 
        ApiService.query(`apiconsume/obtener?endpoint=DatosFinancierosEnmiendaMontos/all/${parametros.estadoId}/${id}`)
        .then(({ data }) => {
            context.commit(SET_DATOS_FINANCIEROS_ENMIENDA_MONTOS_CNS, data.data);
            
            resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
            
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
},




  /**
  * Registrar un datoFinancieroCns
  * @param {*} context
  * @param {*} parametros
  * @returns
  */
  async [REGISTRAR_DATO_FINANCIERO_ENMIENDA_CNS](context, parametros) {

    let datos = parametros.datos;
    

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=DatosFinancierosEnmiendaMontos`, datos)
            .then(({ data }) => {
                context.commit(SET_DATO_FINANCIERO_ENMIENDA_MONTOS_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


  async [ELIMINAR_DATO_FINANCIERO_ENMIENDA_CNS](context, id){
      
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=DatosFinancierosEnmiendaMontos/`)
          .then(({ data }) => {
              context.commit(SET_DATO_FINANCIERO_ENMIENDA_MONTOS_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  

  },


  /**************************************** SUBDIVISION FUENTE COOPERANTE *******************************************/

    //Obtener los datos financieros de la enmienda de montos
    async [OBTENER_SUBDIVISIONES_FC_ENMIENDA_CNS](context, parametros){
      return new Promise(resolve => {
  
          let id = parametros.id;
   
          ApiService.query(`apiconsume/obtener?endpoint=SubdivisionEnmiendaFuenteCooperante/all/${parametros.estadoId}/${id}`)
          .then(({ data }) => {
              context.commit(SET_SUBDIVISIONES_FC_ENMIENDA_MONTOS_CNS, data.data);
              
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
              
              //context.commit(SET_ERROR, response.data.errors);
          });
              
      });
  },
  
  
  
  
    /**
    * Registrar un datoFinancieroCns
    * @param {*} context
    * @param {*} parametros
    * @returns
    */
    async [REGISTRAR_SUBDIVISION_FC_ENMIENDA_CNS](context, parametros) {
  
      let datos = parametros.datos;
      
  
      return new Promise(resolve => {
          ApiService.post(`apiconsume/create?endpoint=SubdivisionEnmiendaFuenteCooperante`, datos)
              .then(({ data }) => {
                  context.commit(SET_SUBDIVISION_FC_ENMIENDA_MONTOS_CNS, data.data);
                  resolve(data)
              })
              .catch((error) => {
                  resolve({
                      status: 400,
                      message: error.message
                  });
              });
      });   
    },
  
  
    async [ELIMINAR_SUBDIVISION_FC_ENMIENDA_CNS](context, id){
        
      return new Promise(resolve => {
        ApiService.delete(`apiconsume/delete/${id}?endpoint=SubdivisionEnmiendaFuenteCooperante/`)
            .then(({ data }) => {
                context.commit(SET_SUBDIVISION_FC_ENMIENDA_MONTOS_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });  
  
    },


      /**************************************** SUBDIVISION CONTRAPARTIDA *******************************************/

    //Obtener los datos financieros de la enmienda de montos
    async [OBTENER_SUBDIVISIONES_CP_ENMIENDA_CNS](context, parametros){
      return new Promise(resolve => {
  
          let id = parametros.id;
   
          ApiService.query(`apiconsume/obtener?endpoint=SubdivisionEnmiendaContrapartida/all/${parametros.estadoId}/${id}`)
          .then(({ data }) => {
              context.commit(SET_SUBDIVISIONES_FC_ENMIENDA_MONTOS_CNS, data.data);
              
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
              
              //context.commit(SET_ERROR, response.data.errors);
          });
              
      });
  },
  
  
  
  
    /**
    * Registrar un datoFinancieroCns
    * @param {*} context
    * @param {*} parametros
    * @returns
    */
    async [REGISTRAR_SUBDIVISION_CP_ENMIENDA_CNS](context, parametros) {
  
      let datos = parametros.datos;
      
  
      return new Promise(resolve => {
          ApiService.post(`apiconsume/create?endpoint=SubdivisionEnmiendaContrapartida`, datos)
              .then(({ data }) => {
                  context.commit(SET_SUBDIVISION_FC_ENMIENDA_MONTOS_CNS, data.data);
                  resolve(data)
              })
              .catch((error) => {
                  resolve({
                      status: 400,
                      message: error.message
                  });
              });
      });   
    },

  async [ELIMINAR_SUBDIVISION_CP_ENMIENDA_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=SubdivisionEnmiendaContrapartida/`
      )
        .then(({ data }) => {
          context.commit(SET_SUBDIVISION_FC_ENMIENDA_MONTOS_CNS, data.data);
          resolve(data)
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /*
   * Actualizar el estado de una enmienda de montos
   */
  async [ACTUALIZAR_ESTADO_ENMIENDA_MONTOS_CNS](context, datos) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${datos.estadoId}?endpoint=EnmiendasMontosCns/ActualizarEstado/${datos.id}/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_MONTOS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  }
};

const mutations = {
  [SET_ENMIENDA_MONTOS_CNS](state, data) {
    state.enmiendaMontos = data;
  },

  [SET_ENMIENDAS_MONTOS_CNS](state, data) {
    state.enmiendasMontos = data;
  },

  [SET_DATO_FINANCIERO_ENMIENDA_MONTOS_CNS](state, data) {
    state.datoFinancieroEnmienda = data;
  },

  [SET_DATOS_FINANCIEROS_ENMIENDA_MONTOS_CNS](state, data) {
    state.datosFinancierosEnmienda = data;
  },

  [SET_SUBDIVISIONES_FC_ENMIENDA_MONTOS_CNS](state, data) {
    state.subdivisionesFuenteCooperanteEnmienda = data;
  },

  [SET_SUBDIVISION_FC_ENMIENDA_MONTOS_CNS](state, data) {
    state.subdivisionFuenteCooperanteEnmienda = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
