//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_DEPARTAMENTOS = "obtenerDepartamentos";
export const OBTENER_DEPARTAMENTOS_PAIS = "obtenerDepartamentosPais";
export const OBTENER_DEPARTAMENTO = "obtenerDepartamento";
export const REGISTRAR_DEPARTAMENTO = "registrarDepartamento";
export const ACTUALIZAR_DEPARTAMENTO = "actualizarDepartamento";

// mutation types
export const SET_DEPARTAMENTOS = "setDepartamento";
export const SET_DEPARTAMENTO = "setDepartamento";

const state = {
    departamentos: [],
    departamento: []
};

const getters = {
  obtnenerDepartamentos(state) {
    return state.departamentos;
  }
};


const actions = {

  //Obtener departamentos por pais
  async [OBTENER_DEPARTAMENTOS_PAIS](context, paisId){
    return new Promise(resolve => {

      ApiService.query(`apiconsume/obtener?endpoint=Departamentos/all/1/${paisId}`)
        .then(({ data }) => {
          context.commit(SET_DEPARTAMENTOS, data.data);

          resolve(data);
        })
        .catch(error => {
            resolve({
                status: 500,
                message: `Ha ocurrido un error en la transacción`,
                data: error
            });
        });
    });

  },

  /*
  async [OBTENER_DEPARTAMENTO](context, id) {
    return new Promise(resolve => {

      context.commit(SET_DEPARTAMENTO, departamento);

      resolve({
        status: 200,
        message: "Ok",
        others: id,
        data: departamento
      });

      
      ApiService.query(`apiconsume/edit/${id}?endpoint=api/departamento/`)
        .then(({ data }) => {
          context.commit(SET_DEPARTAMENTO, data.data);
          //console.log(this.unidadesEjecutoras)
          resolve(data);
        })
        .catch(error => {
          resolve(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },*/

 

  /**
   * Registrar una institución
   * @param {*} context
   * @param {*} datosdepartamento
   * @returns
   */
  /*async [REGISTRAR_DEPARTAMENTO](context, datosdepartamento) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=UnidadesEjecutoras`,
        datosdepartamento
      )
        .then(({ data }) => {
          context.commit(SET_DEPARTAMENTO, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_DEPARTAMENTO](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=UnidadesEjecutoras/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_DEPARTAMENTO, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }*/
};

const mutations = {
  [SET_DEPARTAMENTOS](state, data) {
    state.departamentos = data;
  },

  [SET_DEPARTAMENTO](state, data) {
    state.departamento = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
