//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_PGGS = "obtenerPggs";
export const ELIMINAAR_PGG = "eliminarPgg";
export const OBTENER_PGG = "obtenerPgg";
export const REGISTRAR_PGG = "registrarPgg";
export const ACTUALIZAR_PGG = "actualizarPgg";

// mutation types
export const SET_PGGS = "setPggs";
export const SET_PGG = "setPgg";

const state = {
  pgg: {},
  pggs: []
};

const getters = {
  obtnenerPgg(state) {
    return state.pgg;
  }
};

const actions = {
  async [OBTENER_PGGS](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=PGG/all/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PGGS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAAR_PGG](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=PGG/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_PGG](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=PGG/`)
        .then(({ data }) => {
          context.commit(SET_PGG, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una Pggs directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PGG](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=PGG`, datos)
        .then(({ data }) => {
          context.commit(SET_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_PGG](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=PGG/`, datos)
        .then(({ data }) => {
          context.commit(SET_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_PGGS](state, data) {
    state.pggs = data;
  },

  [SET_PGG](state, data) {
    state.pgg = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
