import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
//import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

import VueMask from "v-mask";
import VueCryptojs from "vue-cryptojs";

import JwtService from "@/core/services/jwt.service";
import RoleRedirect from "@/helpers/roleredirect.js";

import * as GmapVue from "gmap-vue";

import excel from "vue-excel-export";

//import VueHtml2Canvas from 'vue-html2canvas';

/*------------------------ CAMBIAR A TRUE CUANDO SEA PRODUCCIÓN -----------------------*/
Vue.config.productionTip = true; // <--------------------------------------------------/
/*-------------------------------------------------------------------------------------*/

Vue.prototype.$apiUrl = Vue.config.productionTip
  ? "https://if.sicoopera.gt:8443"
  : "http://localhost:5236";

// API service init
ApiService.init();

//Vue.use(VueMask,VueCryptojs);

Vue.use(GmapVue, {
  load: {
    key: "AIzaSyBkTFZrMbnocoK-vFKVlqA--rsfU13Dgbs"
  },
  installComponents: true
});

[VueMask, VueCryptojs, excel /*VueHtml2Canvas*/].forEach(x => Vue.use(x));

// Remove this to disable mock API
//MockService.init();

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  let authorized = String(to.meta.authorize); //
  let authorizedRoles = new Array();
  authorizedRoles = authorized.split(",");

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(res => {
    let token = JwtService.getToken();
    //?Validar que existan roles asignados al usuario

    //?Validar que la ruta requiera autorizacion
    if (authorize) {
      let roles = res[0].roles.map(a => a.nombreRol);
      //Validar que exista el token almacenado, sino, devolverlo al login

      if (!token) {
        return next({ path: "/login" });
      }

      if (roles) {
        //?Validar que los roles asignados al usuario estén en los roles que requiere la ruta
        if (!authorizedRoles.some(item => roles.includes(item))) {
          return next({ path: "/unauthorized" });
        }
      } else {
        return next({ path: "/login" });
      }
    }
    //?Validar que la ruta solicitada sea login
    if (to.path === "/login") {
      if (token) {
        if (res.status === 200) {
          let roles = res[0].roles.map(a => a.nombreRol);
          let newRoute = RoleRedirect.getRouteByRole(roles);
          //console.log(newRoute);
          return next({ name: newRoute });
        } else {
          next();
        }
      }
      next();
    }
    next();
    //!Obtener los roles del usuario
    //let roles = JwtService.getRoles();
  });

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
