//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_DATOS_FINANCIEROS_CNS = "obtenerDatosFinancierosCns";
export const REGISTRAR_DATO_FINANCIERO_CNS = "registrarDatoFinancieroCns";
export const ELIMINAR_DATO_FINANCIERO_CNS = "eliminarDatoFinancieroCns";

export const OBTENER_DISTRIBUCIONES_FUENTE_COOPERANTE_CNS = "obtenerDistribucionesFuenteCooperanteCns";
export const REGISTRAR_DISTRIBUCION_FUENTE_COOPERANTE_CNS = "registrarDistribucionFuenteCooperanteCns";
export const ELIMINAR_DISTRIBUCION_FUENTE_COOPERANTE_CNS = "eliminarDistribucionFuenteCooperante";

export const OBTENER_PROYECCIONES_DESEMBOLSOS_CNS = "obtnerProyeccionesDesembolsosCns";
export const REGISTRAR_PROYECCION_DESEMBOLSOS_CNS = "registrarProyeccionDesembolsoCns";
export const ELIMINAR_PROYECCION_DESEMBOLSO_CNS = "eliminarProyeccionDesembolsoCns";


export const OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS = "obtenerMontosSuscritosDeptoCns";
export const REGISTRAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS = "registrarMontoSuscritoDeptoCns";
export const ELIMINAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS = "eliminarMontoSuscritoDeptoCns";
export const OBTENER_MONTO_SUSCRITO_DEPARTAMENTO_CNS = "obtenerMontoSuscritoTerritorioDeptoCns";
export const ACTUALIZAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS = "actualizarMontoSuscritoTerritorioDeptoCns";

export const REGISTRAR_EJECUCION_ACTIVIDAD_CNS = "registrarEjecucionActividadCns";
export const OBTENER_EJECUCIONES_ACTIVIDAD_CNS = "obtenerEjecucionesActividadCns";
export const ACTUALIZAR_EJECUCION_ACTIVIDAD_CNS = "actualizarEjecucionActividadFuenteCooperanteCns";

export const REGISTRAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS = "registrarEjecucionActividadContraPartidaCns";
export const OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS = "obtenerEjecucionesActividadContraPartidaCns";
export const ACTUALIZAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS = "actualizarEjecucionActividadContrapartidaCns";

export const ELIMINAR_EJECUCION_ACTIVIDAD_CNS = "eliminarEjecucionActividadCns";
export const ELIMINAR_EJECUCION_CONTRAPARTIDA_CNS = "eliminarEjecucionContrapartidaCns";

export const REGISTRAR_EJECUCION_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS = "registrarEjecucionActividadDeptoMuniCns";
export const OBTENER_EJECUCIONES_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS = "obtenerEjecuionesActividadDeptoMuniCns";
export const ELIMINAR_EJECUCION_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS = "eliminarEjecucionActividadDeptoMuniCns";

export const SET_DATOS_FINANCIEROS_CNS = "setDatosFinancierosCns";
export const SET_DATO_FINANCIERO_CNS = "setDatoFinancieroCns";

const state = {
    datosFinancierosCns: [],
    datoFinancieroCns: []
};

const getters = {
  obtnenerDatosFinancierosCns(state) {
    return state.datosFinancierosCns;
  }
};


const actions = {

    async [OBTENER_DATOS_FINANCIEROS_CNS](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
     
            ApiService.query(`apiconsume/obtener?endpoint=DatosFinancierosProyectosCNS/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_DATOS_FINANCIEROS_CNS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar un datoFinancieroCns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_DATO_FINANCIERO_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=DatosFinancierosProyectosCNS`, datos)
            .then(({ data }) => {
                console.log(data)
                context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },



  async [OBTENER_PROYECCIONES_DESEMBOLSOS_CNS](context, parametros){
      return new Promise(resolve => {

        let id = parametros.id;

        ApiService.query(`apiconsume/obtener?endpoint=ProyeccionesDesembolsos/all/1/${id}`)
        .then(({ data }) => {
            context.commit(SET_DATOS_FINANCIEROS_CNS, data.data);
            
            resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
            
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },

  /**
   * Registrar la proyeccion de desembolsos para cns
   * @param {*} context 
   * @param {*} parametros 
   */
  async [REGISTRAR_PROYECCION_DESEMBOLSOS_CNS](context, parametros){
    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=ProyeccionesDesembolsos`, datos)
            .then(({ data }) => {
                context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


  /*
  * Eliminar una proyeccion de desembolso
  */
    async [ELIMINAR_PROYECCION_DESEMBOLSO_CNS](context, id){
    
        return new Promise(resolve => {
        ApiService.delete(`apiconsume/delete/${id}?endpoint=ProyeccionesDesembolsos/`)
            .then(({ data }) => {
                context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
        });
    },  
  

  
  async [ELIMINAR_DATO_FINANCIERO_CNS](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=DatosFinancierosProyectosCNS/`)
          .then(({ data }) => {
              context.commit(SET_DATO_FINANCIERO_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
  
},


/**
 * Obtener los registros de distribucion de monto por fuente cooperante
 * @param {*} context 
 * @param {*} parametros 
 * @returns 
 */

async [OBTENER_DISTRIBUCIONES_FUENTE_COOPERANTE_CNS](context, parametros){
    return new Promise(resolve => {

      let id = parametros.id;

      ApiService.query(`apiconsume/obtener?endpoint=DistMontosFuenteCooperanteCNS/all/1/${id}`)
      .then(({ data }) => {
          context.commit(SET_DATOS_FINANCIEROS_CNS, data.data);
          
          resolve(data)
      })
      .catch((error) => {
          resolve({
              status: 400,
              message: error.message
          });
          
          //context.commit(SET_ERROR, response.data.errors);
      });
          
  });
},


 /**
   * Registrar una distribucion de monto por fuente cooperante
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_DISTRIBUCION_FUENTE_COOPERANTE_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=DistMontosFuenteCooperanteCNS`, datos)
            .then(({ data }) => {
                context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


   
  async [ELIMINAR_DISTRIBUCION_FUENTE_COOPERANTE_CNS](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=DistMontosFuenteCooperanteCNS/`)
          .then(({ data }) => {
              context.commit(SET_DATO_FINANCIERO_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
  
},


/**
   * Registrar monto suscrito por departamento
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
 async [REGISTRAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=MontosSuscritoPorDepto`, datos)
            .then(({ data }) => {
                context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  /*
  * Eliminar registro de monto suscrito por departamento
  */
  async [ELIMINAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=MontosSuscritoPorDepto/`)
          .then(({ data }) => {
              context.commit(SET_DATO_FINANCIERO_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
  
},


    
    async [OBTENER_MONTO_SUSCRITO_DEPARTAMENTO_CNS](context, id){
        return new Promise(resolve => {
     

            ApiService.query(`apiconsume/edit/${id}?endpoint=MontosSuscritoPorDepto/`)
                .then(({ data }) => {
                    context.commit(SET_DATO_FINANCIERO_CNS,data.data);
                    //console.log(this.instituciones)
                    resolve(data)
                })
                .catch((error) => {
                    
                    resolve(error)
                    //context.commit(SET_ERROR, response.data.errors);
                });
          });
    },

    async [ACTUALIZAR_MONTO_SUSCRITO_DEPARTAMENTO_CNS](context, parametros){
        let datos = parametros.datos;
        let id = datos.id;

        return new Promise(resolve => {
          ApiService.update(
            `apiconsume/update/${id}?endpoint=MontosSuscritoPorDepto/`,
            datos
          )
            .then(({ data }) => {
              context.commit(SET_DATO_FINANCIERO_CNS, data.data);
              resolve(data);
            })
            .catch(error => {
              resolve({
                status: 400,
                message: error.message
            });
            });
        });
    },

  /**
 * Obtener los registros de montos suscritos por depto
 * @param {*} context 
 * @param {*} parametros 
 * @returns 
 */

async [OBTENER_MONTOS_SUSCRITOS_DEPTO_CNS](context, parametros){
    return new Promise(resolve => {

      let id = parametros.id;

      ApiService.query(`apiconsume/obtener?endpoint=MontosSuscritoPorDepto/all/1/${id}`)
      .then(({ data }) => {
          context.commit(SET_DATOS_FINANCIEROS_CNS, data.data);
          
          resolve(data)
      })
      .catch((error) => {
          resolve({
              status: 400,
              message: error.message
          });
          
          //context.commit(SET_ERROR, response.data.errors);
      });
          
  });
},



/**
   * Registrar ejecuciones por actividad proyectos cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
 async [REGISTRAR_EJECUCION_ACTIVIDAD_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=EjecucionesPorActividadCns`, datos)
            .then(({ data }) => {
                context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


  /**
   * Actualizar la ejecucion por actividad fuente cooperante proyectos cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [ACTUALIZAR_EJECUCION_ACTIVIDAD_CNS](context, parametros) {

        let datos = parametros.datos;
        let id = datos.id;


        return new Promise(resolve => {
            ApiService.update(`apiconsume/update/${id}?endpoint=EjecucionesPorActividadCns/`, datos)
                .then(({ data }) => {
                    context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });   
    },



  



   /*
  * Eliminar registro de ejecucion fuente cooperante
  */
   async [ELIMINAR_EJECUCION_ACTIVIDAD_CNS](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=EjecucionesPorActividadCns/`)
          .then(({ data }) => {
              context.commit(SET_DATO_FINANCIERO_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
  
},



  /**
   * Registrar ejecuciones por actividad proyectos cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
 async [REGISTRAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=ContrapartidasActividades`, datos)
            .then(({ data }) => {
                context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


    /**
   * Actualizar la ejecucion por actividad contrapartida proyectos cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [ACTUALIZAR_EJECUCION_ACTIVIDAD_CONTRAPARTIDA_CNS](context, parametros) {

        let datos = parametros.datos;
        
        let id = datos.id;
        return new Promise(resolve => {
            ApiService.update(`apiconsume/update/${id}?endpoint=ContrapartidasActividades/`, datos)
                .then(({ data }) => {
                    context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });   
    },


  /*
  * Eliminar registro de ejecucion contrapartida
  */
  async [ELIMINAR_EJECUCION_CONTRAPARTIDA_CNS](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=ContrapartidasActividades/`)
          .then(({ data }) => {
              context.commit(SET_DATO_FINANCIERO_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
  
},

  



    /**
 * Obtener las ejecuciones por actividad cns
 * @param {*} context 
 * @param {*} parametros 
 * @returns 
 */

async [OBTENER_EJECUCIONES_ACTIVIDAD_CNS](context, parametros){
    return new Promise(resolve => {

      let id = parametros.id;

      ApiService.query(`apiconsume/obtener?endpoint=EjecucionesPorActividadCns/all/1/${id}`)
      .then(({ data }) => {
          context.commit(SET_DATOS_FINANCIEROS_CNS, data.data);
          
          resolve(data)
      })
      .catch((error) => {
          resolve({
              status: 400,
              message: error.message
          });
          
          //context.commit(SET_ERROR, response.data.errors);
      });
          
  });
},


    /**
 * Obtener las ejecuciones por actividad cns
 * @param {*} context 
 * @param {*} parametros 
 * @returns 
 */

     async [OBTENER_EJECUCIONES_ACTIVIDAD_CONTRAPARTIDA_CNS](context, parametros){
        return new Promise(resolve => {
    
          let id = parametros.id;
    
          ApiService.query(`apiconsume/obtener?endpoint=ContrapartidasActividades/all/1/${id}`)
          .then(({ data }) => {
              context.commit(SET_DATOS_FINANCIEROS_CNS, data.data);
              
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
              
              //context.commit(SET_ERROR, response.data.errors);
          });
              
      });
    },


    
    /**
   * Registrar ejecuciones por actividad para departamento y municipio proyectos cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [REGISTRAR_EJECUCION_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=ActividadTerritorioCNS`, datos)
            .then(({ data }) => {
                context.commit(SET_DATO_FINANCIERO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


 /**
 * Obtener las ejecuciones por actividad para departamento y municipio proyectos cns
 * @param {*} context 
 * @param {*} parametros 
 * @returns 
 */

       async [OBTENER_EJECUCIONES_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS](context, parametros){
        return new Promise(resolve => {
    
          let id = parametros.id;
    
          ApiService.query(`apiconsume/obtener?endpoint=ActividadTerritorioCNS/all/${parametros.estadoId}/${id}`)
          .then(({ data }) => {
              context.commit(SET_DATOS_FINANCIEROS_CNS, data.data);
              
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
              
              //context.commit(SET_ERROR, response.data.errors);
          });
              
      });
    },


      /*
  * Eliminar ejecucion por actividad para departamento y municipio proyectos cns
  */
  async [ELIMINAR_EJECUCION_ACTIVIDAD_DEPARTAMENTO_MUNICIPIO_CNS](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=ActividadTerritorioCNS/`)
          .then(({ data }) => {
              context.commit(SET_DATO_FINANCIERO_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
  
},






};

const mutations = {
  [SET_DATOS_FINANCIEROS_CNS](state, data) {
    state.datosFinancierosCns = data;
  },

  [SET_DATO_FINANCIERO_CNS](state, data) {
    state.datoFinancieroCns = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

