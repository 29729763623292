//import axios from "axios";
import ApiService from "@/core/services/api.service";

export const REGISTRAR_SOLICITUD_USUARIOS = "registrarSolicitudUsuarios";
export const OBTENER_SOLICITUDES_USUARIOS = "obtenerSolicitudesRegistradas";
export const OBTENER_SOLICITUD_USUARIOS = "obtenerSolicitudUsuarios";
export const GENERAR_USUARIO_SOLICITUD = "generarUsuarioSolicitud";
export const RECHAZAR_SOLICITUD_USUARIOS = "rechazarSolicitudUsuarios";

export const SET_SOLICITUD_USUARIO = "setSolicitudUsuario";
export const SET_SOLICITUDES_USUARIOS = "setSolicitudesUsuarios";

const state = {
    solicitudUsuarios: [],
    solicitudesUsuarios: [],

};

const getters = {
    obtenerSolicitudesUsuarios(state) {
      return state.solicitudesUsuarios;
    }
};


const actions = {

   /**
   * Registrar solicitud de usuarios
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_SOLICITUD_USUARIOS](context, parametros) {
    //console.log(JSON.parse(parametros.datos))
   // delete parametros.datos.usuarios;
    return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=SolicitudUsuarios`, parametros.datos)
                .then(({ data }) => {
                    context.commit(SET_SOLICITUD_USUARIO, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },


  /**
   * Obtener las solicitudes registradas
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */


  async [OBTENER_SOLICITUDES_USUARIOS](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=SolicitudUsuarios/all/${parametros.estadoId}`, '')
        .then(({ data }) => {
            context.commit(SET_SOLICITUDES_USUARIOS, data.data);
            
            resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error
          });
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },


  async [OBTENER_SOLICITUD_USUARIOS](context, id) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=SolicitudUsuarios/${id}`, '')
        .then(({ data }) => {
            context.commit(SET_SOLICITUD_USUARIO, data.data);
            
            resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error
          });
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },

  /**
   * Generar usuario de solicitud
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [GENERAR_USUARIO_SOLICITUD](context, parametros) {
    //console.log(JSON.parse(parametros.datos))
   // delete parametros.datos.usuarios;
    return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=Users/Solicitud/Asignacion`, parametros.datos)
                .then(({ data }) => {
                    context.commit(SET_SOLICITUD_USUARIO, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },

  /**
   * Rechazar la solicitud de usuario
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */

  async [RECHAZAR_SOLICITUD_USUARIOS](context, parametros){

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update2?endpoint=SolicitudUsuarios/Rechazo/${parametros.id}`, parametros)
                  .then(({ data }) => {
                      context.commit(SET_SOLICITUD_USUARIO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  }


  


};


const mutations = {
    [SET_SOLICITUD_USUARIO](state, data) {
      state.solicitudUsuarios = data;
    },
  
    [SET_SOLICITUDES_USUARIOS](state, data) {
      state.solicitudesUsuarios = data;
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };