//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_RIESGOS_CNS = "obtenerRiesgosCns";
export const REGISTRAR_RIESGO_CNS = "registrarRiesgoCns";
export const ELIMINAR_RIESGO_CNS = "eliminarRiesgoCns";

export const SET_RIESGOS_CNS = "setRiesgosCns";
export const SET_RIESGO_CNS = "setRiesgoCns";

const state = {
    riesgos: [],
    riesgo: []
};

const getters = {
  obtnenerRiesgosCns(state) {
    return state.riesgos;
  }
};


const actions = {

    async [OBTENER_RIESGOS_CNS](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
     
            ApiService.query(`apiconsume/obtener?endpoint=RiesgosProyecto/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_RIESGOS_CNS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar un riesgo
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_RIESGO_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=RiesgosProyecto`, datos)
            .then(({ data }) => {
                context.commit(SET_RIESGO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

   /*
  * Eliminar un riesgo
  */
   async [ELIMINAR_RIESGO_CNS](context, id){
    
    return new Promise(resolve => {
    ApiService.delete(`apiconsume/delete/${id}?endpoint=RiesgosProyecto/`)
        .then(({ data }) => {
            context.commit(SET_RIESGO_CNS, data.data);
            resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
        });
    });
},  

};

const mutations = {
  [SET_RIESGOS_CNS](state, data) {
    state.riesgos = data;
  },

  [SET_RIESGO_CNS](state, data) {
    state.riesgo = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

