//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_PROYECTOS_CNS = "obtenerProyectosCns";
export const OBTENER_PROYECTO_CNS = "obtenerProyectoCns";
export const REGISTRAR_PROYECTO_CNS = "registrarProyectoCns";
export const ACTUALIZAR_PROYECTO_CNS = "actualizarProyectoCns";

export const OBTENER_AVANCE_PROYECTO_CNS = "obtenerAvanceProyectoCns";
export const OBTENER_PROYECTOS_CNS_ENTIDAD = "obtenerProyectosCnsEntidad";
/*********** OBTENER DATOS ESPECIFICOS POR SECCION ***********/

export const OBTENER_DATOS_SECCIONES_PROYECTO = "obtenerDatosSeccionesProyecto";

/*************************************************************/

//Para el especialista
export const OBTENER_PROYECTOS_CNS_USUARIO_ESPECIALISTA =
  "obtenerProyectosUsuarioEspecialista";

//Para la unidad ejecutora
export const OBTENER_PROYECTOS_CNS_USUARIO_INSTITUCION =
  "obtenerProyectosCnsUsuarioInstitucion";
export const OBTENER_CANTIDAD_PROYECTOS_CNS_EXTERNO =
  "obtenercantidadCnsUsuario";
export const OBTENER_CANTIDAD_TOTAL_PROYECTOS_CNS =
  "obtenerCantidadTotalProyectosCNS";

export const OBTENER_PROYECTOS_CNS_EXTERNO = "obtenerProyectosCnsExterno";

export const OBTENER_PROYECTO_CNS_EXTERNO = "obtenerProyectoCnsExterno";
export const REGISTRAR_PROYECTO_CNS_EXTERNO = "registrarProyectoCnsExterno";
export const ACTUALIZAR_PROYECTO_CNS_EXTERNO = "actualizarProyectoCnsExterno";
export const CONFIRMAR_PROYECTO_CNS = "confirmarProyectoCNS";

export const ACTUALIZAR_CUENTA_CONDICIONES_DESEMOLSOS =
  "actualizarCuentaCondicionesDesembolsosCns";
export const ACTUALIZAR_CUENTA_MONTO_TERRITORIO =
  "actualizarCuentaMontoTerritorioCns";

export const ACTUALIZAR_CUENTA_CONDICIONES_DESEMBOLSO_CNS =
  "actualizarCuentaCondicionesDesembolsoCns";
export const ACTUALIZAR_CUENTA_CUENTA_OPINION_TECNIA_CNS =
  "actualizarCuentaOpinionTecnicaCns";

export const ACTUALIZAR_FORMA_OPERAR = "actualizarFormaOperarCns";

export const ACTUALIZAR_SECTOR_BENEFICIADO_CNS =
  "actualizarSectorBeneficiadoCns";
export const ACTUALIZAR_CRS_CNS = "actualizarCRSCns";
export const ACTUALIZAR_VINCULACION_INSTRUMENTOS_INTERNACIONALES =
  "actualizarInstrumentosInternacionalesCns";
export const ACTUALIZAR_POLITICA_PUBLICA_CNS = "actualizarPoliticaPublicaCns";
export const ACTUALIZAR_OTROS_INSTRUMENTOS_CNS =
  "actualizarOtrosInstrumentosCns";

export const ACTUALIZAR_GEO_POSICION_CNS = "actualizarGEOPosicionCns";

export const REPORTE_PROYECTOS_CNS_SICED = "obtenerReporteGeneralCnsSiced";
export const REPORTE_DOCUEMNTOS_CNS = "obtenerReporteDocumentosCNS";

export const ACTUALIZA_FORMA_OPERAR_CNS = "registrarFormaOperarCns";
export const ACTUALIZAR_DATOS_INGRESA_PRESUPUESTO_CNS =
  "actualizarDatosIngresaPresupuestoCns";
// mutation types

//Todos los proyectos sicoopera
export const OBTENER_PROYECTOS_SICOOPERA_INSTITUCION_ENTIDAD =
  "obtenerProyectosSicoopera";

export const SET_PROYECTOS_CNS = "setProyectosCns";
export const SET_CANTIDAD_PROYECTOS_CNS = "setcantidadCns";
export const SET_PROYECTO_CNS = "setProyectoCns";
export const SET_DATOS_SECCION_CNS = "setDatosSeccion";

export const SET_MECANISMOS_EJECUCION_CNS = "setMecanismosEjecucionCns";

const state = {
  proyectos: [],
  proyecto: [],
  datosSeccion: [],
  mecanismosEjecucion: [],
  cantidadCns: 0
};

const getters = {
  obtnenerproyectosCns(state) {
    return state.proyectosCns;
  }
};

const proyecto = {
  id: 1,
  codigo: "CNS-2022-0001",
  nombreProyecto: "Nombre del proyecto 1",
  nombreInstitucion: "SEGEPLAN",
  nombreUnidadEjecutora: "Nombre unidad ejecutora 1",
  unidadesEjecutorasId: 1,
  fechaSolicitudOpinionTecnica: "10/01/2022",
  nombreTipoInstrumento: "Tipo Instrumento 1",
  tiposInstrumentoId: 1,
  tiposFuenteCooperanteId: 1,
  nombreTipoFuenteCooperante: "Nombre tipo fuente cooperante 1",
  agenciasId: 1,
  paisesId: 1,
  tiposCooperacionId: 1,
  modalidadesEjecucionId: 1,
  descripcionProyecto: "Esta es una descripción",
  objetivoGeneral: "Este es el objetivo General",
  objetivoEspecifico1: "Este es el objetivo Especifico 1",
  objetivoEspecifico2: "Este es el objetivo Especifico 2",
  objetivoEspecifico3: "Este es el objetivo Especifico 3",
  sectoresBeneficiadosId: 1,
  correoElectronico: "correo@segeplan.gob.gt",
  nombreSolicitante: "Este es el nombre del solicitante",
  pathDocumentoOt: "proyectoscns/documentos/documentoot01.pdf",
  antecedentes: "Estos son los antecedentes del proyecto",
  problematica: "Esta es la problemática",
  justificacion: "Esta es la justificación del proyecto",
  situacionSinProyecto: "Esta es la descripción de la situación sin proyecto",
  situacionConProyecto: "Esta es la descripción de la situación con proyecto",
  riesgo1: "Este es un ejemplo de riesgo",
  riesgo2: "Este es un ejemplo de riesgo 2",
  riesgo3: "Este es un ejemplo de riesgo 3",
  estado: "Seguimiento",
  estadosId: 2,
  fecha: "10/10/2021"
};

/******************************************** SECCION INTERNA SEGEPLAN DASCD /********************************************/
const actions = {
  async [OBTENER_PROYECTOS_CNS](context, parametros) {
    return new Promise(resolve => {
      let endpoint = "ProyectosCNS";
      if (parametros && parametros.estadoProyectoId) {
        endpoint = `ProyectosCNS/all/${parametros.estadoProyectoId}`;
      }

      ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_PROYECTO_CNS](context, parametros) {
    let id = parametros.id;
    // console.log(datos)

    let endpoint = "ProyectosCNS";

    let seccion = parametros.seccion;
    switch (seccion) {
      case "InformacionAdicional":
        endpoint = "InformacionAdicional";
        break;
    }

    return new Promise(resolve => {
      //Quitar
      if (id) context.commit(SET_PROYECTO_CNS, proyecto);

      ApiService.query(`apiconsume/edit/${id}?endpoint=${endpoint}/`)
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar un proyecto desde la unidad ejecutora
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PROYECTO_CNS](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)

    let endpoint = "ProyectosCNS";

    let seccion = parametros.seccion;
    switch (seccion) {
      case "InformacionAdicional":
        endpoint = "InformacionAdicional";
        break;
    }

    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=${endpoint}`, datos)
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [ACTUALIZAR_PROYECTO_CNS](context, parametros) {
    let id = parametros.datos.id;
    let datos = parametros.datos;

    let endpoint = "ProyectosCNS";

    let seccion = parametros.endpoint;
    switch (seccion) {
      case "InformacionAdicional":
        endpoint = "InformacionAdicional";
        break;
      case "InformacionGeneral":
        endpoint = "ProyectosCNS";
        break;
    }
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=${endpoint}/`, datos)
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /******************************************** SECCION EXTERNA UNIDAD EJECUTORA /********************************************/
  async [OBTENER_PROYECTOS_CNS_USUARIO_INSTITUCION](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCNS/Institucion/Usuario/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /******************************************** SECCION EXTERNA UNIDAD EJECUTORA /********************************************/
  async [OBTENER_PROYECTOS_CNS_USUARIO_ESPECIALISTA](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCNS/Especialista/Usuario/${parametros.estadoProyectoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  ///Obtener la cantidad de proyectos asignados a un usuario institución
  async [OBTENER_CANTIDAD_PROYECTOS_CNS_EXTERNO](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCNS/Institucion/Usuario/Cantidad/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_CANTIDAD_PROYECTOS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  ///Obtener la cantidad total de proyectos del sicoopera
  async [OBTENER_CANTIDAD_TOTAL_PROYECTOS_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCNS/Registrados/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_CANTIDAD_PROYECTOS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_PROYECTOS_CNS_EXTERNO](context) {
    return new Promise(resolve => {
      ApiService.query("apiconsume/obtener?endpoint=ProyectosCNS", "")
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_DATOS_SECCIONES_PROYECTO](context, parametros) {
    //Seccion: define que datos de la sección se requieren
    let seccion = parametros.seccion;

    let endpoint = "ProyectosCNS";

    let id = parametros.id;

    switch (seccion) {
      case "InformacionAdicional":
        endpoint = "InformacionAdicional";
        break;
      case "DocumentosProyecto":
        endpoint = "ProyectoCNSSection5";
        break;
    }

    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=${endpoint}/`)
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //Obtener el avance del proyecto CNS

  async [OBTENER_AVANCE_PROYECTO_CNS](context, parametros) {
    let id = parametros.id;
    // console.log(datos)

    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=ProyectosCNS/Avance/`)
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /******************************************** OBTENER PROYECTOS POR ENTIDAD /********************************************/
  async [OBTENER_PROYECTOS_CNS_ENTIDAD](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCNS/Entidad/${parametros.entidadId}/${parametros.tipoCooperacionId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /******************************************** OBTENER PROYECTOS POR INSTITUCION Y ENTIDAD (TODOS LOS TIPOS DE PROYECTO ) /********************************************/
  async [OBTENER_PROYECTOS_SICOOPERA_INSTITUCION_ENTIDAD](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosSigeaci/all/${parametros.entidadId}/${parametros.unidadEjecutoraId}/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar un proyecto cns desde usuario externo (Unidad ejecutora)
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PROYECTO_CNS_EXTERNO](context, parametros) {
    let id = parametros.id;
    let datos = parametros.datos;
    // console.log(datos)
    let endpoint = "ProyectosCNS";
    //console.log(parametros.seccion);
    let seccion = parametros.seccion;
    switch (seccion) {
      case "InformacionGeneral":
        endpoint = "ProyectoCNSSection2";
        break;
      case "Alcance":
        endpoint = "ProyectoCNSSection3";
        break;
      case "FechasPlazos":
        endpoint = "ProyectoCNSSection4";
        break;
      case "Documentos":
        endpoint = "ProyectoCNSSection5";
        break;
    }
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=${endpoint}/`, datos)
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [CONFIRMAR_PROYECTO_CNS](context, parametros) {
    return new Promise(resolve => {
      let id = parametros.id;
      let estadoId = parametros.estadoId;

      ApiService.update(
        `apiconsume/update2?endpoint=ProyectosCNS/Estado/${id}/${estadoId}`,
        parametros
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_CUENTA_CONDICIONES_DESEMOLSOS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=ProyectosCNS/proyecta/desembolsos/${parametros.id}/${parametros.proyectaDesembolsos}`,
        parametros
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_CUENTA_MONTO_TERRITORIO](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=ProyectosCNS/DetalleMonto/Depto/${parametros.id}`,
        parametros
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //Actualizar el campo cuentaCondicionesDesembolos de cns
  async [ACTUALIZAR_CUENTA_CONDICIONES_DESEMBOLSO_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=ProyectosCNS/Cuenta/Condiciones/Desembolso/${parametros.id}`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //Actualizar el campo cuenta con opinion técnica de cns
  async [ACTUALIZAR_CUENTA_CUENTA_OPINION_TECNIA_CNS](context, parametros) {
    //console.log(parametros.datos)
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=ProyectosCNS/Cuenta/Opinion/Tecnica/${parametros.datos.id}`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //CTUALIZAR EL CAMPO DETALLE FORMA DE OPERAR
  async [ACTUALIZAR_FORMA_OPERAR](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${parametros.id}?endpoint=ProyectosCNS/formaoperar/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //ACTUALIZAR EL CAMPO SECTORESID (SECTOR BENEFICIADO)
  async [ACTUALIZAR_SECTOR_BENEFICIADO_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${parametros.id}?endpoint=ProyectosCNS/Sectores/Beneficiados/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //ACTUALIZAR EL CAMPO CRISID (ESTÁNDAR )
  async [ACTUALIZAR_CRS_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${parametros.id}?endpoint=ProyectosCNS/CRS/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //ACTUALIZAR VINCULACION ESTRATEGICA INSTRUMENTOS INTERNACIONALES ODS, META ODS, OBJETIVO ODS, CAD Y CRS
  async [ACTUALIZAR_VINCULACION_INSTRUMENTOS_INTERNACIONALES](
    context,
    parametros
  ) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${parametros.id}?endpoint=ProyectosCNS/Vinculacion/Instrumentos/Internacional/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //ACTUALIZAR EL CAMPO POLITICA PUBLICA
  async [ACTUALIZAR_POLITICA_PUBLICA_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${parametros.id}?endpoint=ProyectosCNS/Politica/Publica/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //ACTUALIZAR OTROS INSTRUMENTOS
  async [ACTUALIZAR_OTROS_INSTRUMENTOS_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${parametros.id}?endpoint=ProyectosCNS/Otros/Instrumentos/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //ACTUALIZAR LA FORMA DE OPERAR SI NO TIENE CONDICIONES DE DESEMBOLSOS
  async [ACTUALIZA_FORMA_OPERAR_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${parametros.datos.proyectosCNSId}?endpoint=ProyectosCNS/formaoperar/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //ACTUALIZAR SI EL PROYECTO INGRESA A PRESUPUESTO
  async [ACTUALIZAR_DATOS_INGRESA_PRESUPUESTO_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${parametros.datos.proyectosCNSId}?endpoint=ProyectosCNS/DatosIngresaPresupuesto/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //ACTUALIZAR DIRECCION Y GEOPOSICION
  async [ACTUALIZAR_GEO_POSICION_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${parametros.id}?endpoint=ProyectosCNS/Direccion/Posicion/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //REPORTE PROYECTOS SICOOPERA Y SICED
  async [REPORTE_PROYECTOS_CNS_SICED](context, datos) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ReporteGerencialFuenteCooperante/General/ScoSiced`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  //REPORTE DE DOCUMENTOS CNS
  async [REPORTE_DOCUEMNTOS_CNS](context, datos) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ReporteGeneralDocumentosProyecto/General`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  }
};

const mutations = {
  [SET_PROYECTOS_CNS](state, data) {
    state.proyectos = data;
  },

  [SET_PROYECTO_CNS](state, data) {
    state.proyecto = data;
  },

  [SET_DATOS_SECCION_CNS](state, data) {
    state.datosSeccion = data;
  },

  [SET_CANTIDAD_PROYECTOS_CNS](state, data) {
    state.cantidadCns = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
