//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_DOCUMENTOS_CNS = "obtenerDocumentosTipoCNS";
/* export const REGISTRAR_BENEFICIARIO = "registrarBeneficiario";
export const ACTUALIZAR_BENEFICIARIO = "actualizarBeneficiario"; */

/* export const ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS = "actualizarCuentaBeneficiariosDirectosCns";
export const ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNS = "actualizarCuentaInstitucionesBeneficiariasCns"; */

// mutation types
export const SET_DOCUMENTOS_CNS = "setBeneficiariosCNS";
export const SET_BENEFICIARIO = "setBeneficiarioCNS";

export const SET_TIPO_BENEFICIARIO = "setTipoBeneficiarioCNS";

const state = {
    documentosCNS: [],
  beneficiario: [],
  tipoBeneficiario: {}
};

const getters = {
  obtnenerDocumentosCns(state) {
    return state.beneficiarios;
  }
};

/*const beneficiario = {
  id: 1,
  numeroBeneficiados: "beneficiario 1",
  nombreEtnia: 'Indigena 1",
  nombreEstado: "Registrada",
  estadosId: 1
};

*/


const actions = {
  async [OBTENER_DOCUMENTOS_CNS](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=DocumentosCNS/all/${parametros.estadoId}/${parametros.tipoProyectoId}`)
        .then(({ data }) => {
          context.commit(SET_DOCUMENTOS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },


  /**
   * Registrar una condicion
   * @param {*} context
   * @param {*} datos
   * @returns
   */
 /*  async [REGISTRAR_BENEFICIARIO](context, parametros) {
    //console.log(datosEmpresa)
    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=BeneficiariosCNS`, datos)
            .then(({ data }) => {
                context.commit(SET_BENEFICIARIO, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  async [ACTUALIZAR_BENEFICIARIO](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=BeneficiariosCNS/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  }, */

/* 
  async [ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS](context, parametros) {
    
    let id = parametros.datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCNS/Beneficiarios/Directos/`, parametros.datos)
        .then(({ data }) => {
          context.commit(SET_TIPO_BENEFICIARIO, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },


  async [ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNS](context, parametros) {
    
    let id = parametros.datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCNS/Cuenta/Institucion/`, parametros.datos)
        .then(({ data }) => {
          context.commit(SET_TIPO_BENEFICIARIO, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  }, */



  
}

const mutations = {
  [SET_DOCUMENTOS_CNS](state, data) {
    state.documentosCNS = data;
  },

  [SET_BENEFICIARIO](state, data) {
    state.beneficiario = data;
  },

  [SET_TIPO_BENEFICIARIO](state, data){
    state.tipoBeneficiario = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
