import Vue from "vue";
import Router from "vue-router";
import { Role } from "./helpers/role";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/ds",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            authorize: [
              Role.Administrador,
              Role.SubAdministrador,
              Role.SeguimientoCNS,
              Role.SeguimientoCSS,
              Role.SeguimientoAlianzas,
              Role.SeguimientoCNSC,
              Role.SeguimientoActoresLocales,
              Role.SeguimientoCooperacionDescentralizada,
              Role.UnidadEjecutora,
              Role.CooperanteBi,
              Role.CooperanteMulti,
              Role.InstitucionCns,
              Role.InstitucionCSSD,
              Role.InstitucionCSSC,
              Role.InstitucionAlianzas,
              401,
              Role.Alianzas,
              601
            ]
          }
        },
        {
          path: "/dashboard-administrador",
          name: "dashboard-administrador",
          component: () => import("@/view/pages/dashboard/AdminDashboard.vue"),
          meta: {
            authorize: [Role.Administrador, Role.SubAdministrador]
          }
        },
        {
          path: "/dashboard-institucion",
          name: "dashboard-institucion",
          component: () =>
            import("@/view/pages/dashboard/InstitucionDashboard.vue"),
          meta: {
            authorize: [
              Role.InstitucionCns,
              Role.InstitucionCSSD,
              Role.InstitucionCSSC,
              Role.InstitucionAlianzas
            ]
          }
        },

        {
          path: "/dashboard-cooperante",
          name: "dashboard-cooperante",
          component: () =>
            import("@/view/pages/dashboard/CooperanteDashboard.vue"),
          meta: { authorize: [Role.CooperanteBi, Role.CooperanteMulti] }
        },
        {
          path: "/dashboard-seguimiento",
          name: "dashboard-seguimiento",
          component: () =>
            import("@/view/pages/dashboard/SeguimientoDashboard.vue"),
          meta: {
            authorize: [
              Role.SeguimientoCNS,
              Role.SeguimientoCSS,
              Role.SeguimientoAlianzas,
              Role.SeguimientoCNSC,
              Role.SeguimientoActoresLocales,
              Role.SeguimientoCooperacionDescentralizada
            ]
          }
        },
        {
          path: "/dashboard-sur-sur",
          name: "dashboard-sur-sur",
          component: () => import("@/view/pages/dashboard/SurSurDashboard.vue"),
          meta: { authorize: [401] }
        },

        {
          path: "/dashboard-alianzas",
          name: "dashboard-alianzas",
          component: () =>
            import("@/view/pages/dashboard/AlianzasDashboard.vue"),
          meta: { authorize: [Role.InstitucionAlianzas] }
        },
        {
          path: "/dashboard-municipalidad",
          name: "dashboard-municipalidad",
          component: () => import("@/view/pages/dashboard/MuniDashboard.vue"),
          meta: { authorize: [101, 201, 301, 401, 501] }
        },
        {
          path: "/dashboard-utc",
          name: "dashboard-utc",
          component: () => import("@/view/pages/dashboard/UtcDashboard.vue"),
          meta: { authorize: [601] }
        },
        {
          path: "/dashboard-udaf",
          name: "dashboard-udaf",
          component: () => import("@/view/pages/dashboard/UdafDashboard.vue"),
          meta: { authorize: [Role.Udaf] }
        },
        {
          name: "solicitud-usuarios",
          path: "/solicitud/usuarios",
          component: () =>
            import("@/view/pages/solicitudes/SolicitudUsuarios.vue"),
          meta: { horizontalMenu: true }
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue")
        },
        {
          path: "/cooperacion-sur-sur",
          name: "cooperacion-sur-sur",
          component: () =>
            import("@/view/pages/cooperacion-sur-sur/CooperacionSurSur.vue"),
          children: [
            {
              path: "tabs",
              name: "tabs",
              component: () => import("@/view/pages/test/tabs.vue")
            },
            {
              path: "reportes",
              name: "reportes",
              component: () => import("@/view/pages/test/reportes.vue")
            }
          ]
        },
        // Sección Administración
        {
          path: "/administracion",
          name: "administracion",
          component: () =>
            import("@/view/pages/administracion/AdministracionBase.vue"),
          children: [
            {
              path: "usuarios/internos",
              name: "usuarios",
              component: () =>
                import(
                  "@/view/pages/usuarios/AdministracionUsuariosInternos.vue"
                ),
              meta: { authorize: [Role.Administrador] }
            },
            {
              path: "usuarios/externos",
              name: "usuarios",
              component: () =>
                import(
                  "@/view/pages/usuarios/AdministracionUsuariosExternos.vue"
                ),
              meta: { authorize: [Role.Administrador] }
            },
            {
              path: "socios-implementadores",
              name: "socios-implementadores",
              component: () =>
                import(
                  "@/view/pages/administracion/sociosimplementadores/AdminSociosImplementadores.vue"
                )
            },

            {
              path: "usuarios/proyectos",
              name: "usuarios-proyectos",
              component: () =>
                import(
                  "@/view/pages/usuarios/AdministracionUsuariosProyectos.vue"
                ),
              meta: { authorize: [Role.Administrador] }
            },
            {
              path: "usuarios/proyectos-cns/asignacion/especialista",
              name: "usuarios-proyectos-asignacion-especialista",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/seguimiento/AsignacionSeguimientoEspecialistaCns.vue"
                ),
              meta: { authorize: [Role.Administrador, Role.SubAdministrador] }
            },

            {
              path: "cooperantes",
              name: "cooperantes",
              component: () =>
                import(
                  "@/view/pages/administracion/cooperantes/AdminCooperantes.vue"
                )
            },

            {
              path: "estrategias",
              name: "estrategias-cooperantes",
              props: { tipoUsuario: "interno" },
              component: () =>
                import(
                  "@/view/pages/administracion/cooperantes/estrategias/AdminEstrategiasCooperantes.vue"
                )
            },

            {
              path: "estrategiasext",
              name: "estrategias-cooperantes-externo",
              props: { tipoUsuario: "externo" },
              component: () =>
                import(
                  "@/view/pages/administracion/cooperantes/estrategias/AdminEstrategiasCooperantes.vue"
                )
            },

            //Sección de Catálogos
            {
              path: "catalogos",
              name: "catalogos",
              component: () =>
                import("@/view/pages/administracion/AdministracionBase.vue"),
              children: [
                //Sección Instituciones
                {
                  path: "instituciones",
                  name: "instituciones",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/instituciones/AdminInstituciones.vue"
                    )
                },
                //Sección Unidades Ejecutoras
                {
                  path: "unidades-ejecutoras",
                  name: "unidades-ejecutoras",
                  component: () =>
                    import(
                      "@/view/pages/administracion/unidades-ejecutoras/AdminUnidadesEjecutoras.vue"
                    )
                },

                //Sección Fuentes y Agencias bilaterales
                {
                  path: "fuentes-bilaterales",
                  name: "fuentes-bilaterales",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/fuentesbilaterales/AdminFuentesBilaterales.vue"
                    )
                },
                {
                  path: "agencias-bilaterales",
                  name: "agencias-bilaterales",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/AgenciasBilaterales/AdminAgenciasBilaterales.vue"
                    )
                },

                //Sección Fuentes y Agencias multilaterales
                {
                  path: "fuentes-multilaterales",
                  name: "fuentes-multilaterales",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/fuentesmultilaterales/AdminFuentesMultilaterales.vue"
                    )
                },
                {
                  path: "agencias-multilaterales",
                  name: "agencias-multilaterales",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/AgenciasMultilaterales/AdminAgenciasMultilaterales.vue"
                    )
                },

                {
                  path: "actividadessubdivisiones",
                  name: "actividades-subdivisiones",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/ActividadesSubdivision/AdminActividadesSubdivision.vue"
                    )
                },

                {
                  path: "periodos",
                  name: "peridos",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/periodos/AdminPeriodos.vue"
                    )
                },
                {
                  path: "sectoressidcss",
                  name: "sectores-sidcss",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/sectoressidcss/AdminSectoresSidCss.vue"
                    )
                },
                {
                  path: "pnds",
                  name: "prioridades-nacionales-desarrollo",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/Pnd/AdminPnd.vue"
                    )
                },
                {
                  path: "metas-pnd",
                  name: "metas-pnd",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/MetaPnd/AdminMetaPnd.vue"
                    )
                },
                {
                  path: "ejes-katun",
                  name: "ejes-katun",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/EjesKatun/AdminEjesKatun.vue"
                    )
                },
                {
                  path: "prioridades-katun",
                  name: "prioridades-katun",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/EjesKatun/AdminPrioridadesKatun.vue"
                    )
                },
                {
                  path: "pgg",
                  name: "politica-general-gobierno",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/PGG/AdminPggs.vue"
                    )
                },
                {
                  path: "pilares-pgg",
                  name: "pilares-pgg",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/PGG/AdminPilaresPgg.vue"
                    )
                },
                {
                  path: "metas-pgg",
                  name: "metas-pgg",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/PGG/AdminMetasPgg.vue"
                    )
                },
                {
                  path: "objetivos-estrategicos-pgg",
                  name: "objetivos-estrategicos-pgg",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/PGG/AdminObjetivosEstrategicosPgg.vue"
                    )
                },
                {
                  path: "objetivos-sectoriales-pgg",
                  name: "objetivos-sectoriales-pgg",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/PGG/AdminObjetivosSectorialesPgg.vue"
                    )
                },
                {
                  path: "politicas-publicas",
                  name: "politicas-publicas",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/PoliticasPublicas/AdminPoliticaPublica.vue"
                    )
                },
                {
                  path: "ods",
                  name: "objetivos-desarrollo-sostenible",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/Ods/AdminOds.vue"
                    )
                },
                {
                  path: "metas-ods",
                  name: "metas-ods",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/MetasOds/AdminMetasOds.vue"
                    )
                },
                {
                  path: "documentos-aprobacion-donacion",
                  name: "documentos-aprobacion-donacion",
                  component: () =>
                    import(
                      "@/view/pages/administracion/catalogos/TiposAprobacionDonacion/AdminTiposAprobacionDonacion.vue"
                    )
                }
              ]
            }
          ]
        },

        {
          path: "/administracion/usuarios/solicitudes",
          name: "usuarios-solicitudes",
          component: () =>
            import("@/view/pages/solicitudes/SolicitudUsuarios.vue")
        },
        {
          name: "solicitudes-estado-cns",
          path: "/administracion/proyectos-cns/solicitudesestado-cns",
          component: () =>
            import(
              "@/view/pages/administracion/solicitudes-cambio-estado-cns/AdminSolicitudesCambioEstadoCns.vue"
            ),
          meta: {
            authorize: [
              Role.Administrador,
              Role.SubAdministrador,
              Role.SeguimientoCNS
            ]
          }
        },
        {
          path: "administracion/proyectos/tradicionales",
          name: "proyectos",
          component: () =>
            import(
              "@/view/pages/proyectos-tradicionales/AdminProyectosTradicionales.vue"
            )
        },
        {
          path: "administracion/proyectos/tradicionales/registro",
          name: "proyectos-registro",
          component: () =>
            import(
              "@/view/pages/proyectos-tradicionales/RegistroProyectosTradicionales.vue"
            )
        },

        //Proyectos modalidad Cooperacion Norte Sur interno DASCD
        {
          path: "/proyectos/cns",
          name: "proyectos-cns",
          component: () =>
            import("@/view/pages/proyectos-cns/ProyectosCns.vue"),
          children: [
            {
              path: "administracion",
              name: "proyectos-cns-segeplan",
              component: () =>
                import("@/view/pages/proyectos-cns/AdminProyectosCns.vue")
            },

            {
              path: "seguimiento/cartera/:tipoSeguimiento",
              name: "proyectos-cns-segeplan-seguimiento",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/seguimiento/AdminProyectosCnsSeguimiento.vue"
                )
            },
            {
              path: "seguimiento/registro/:codigoProyecto/:proyectoId",
              name: "registro-seguimiento",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/seguimiento/RegistroSeguimientoCns.vue"
                )
            },
            {
              path:
                "administracion/proyectos/cns/detalles/:codigoProyecto/:proyectoId",
              name: "detalle-cns",
              component: () =>
                import("@/view/pages/proyectos-cns/DetalleProyectoCnsSinOt.vue")
            },

            {
              path:
                "administracion/proyectos/cns/enmiendas/componentes/:codigoProyecto/:proyectoId",
              name: "admin-enmiendas-componentes",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/enmiendas/AdminEnmiendasComponentes.vue"
                )
            },

            {
              path:
                "administracion/proyectos/cns/enmiendas/montos/:codigoProyecto/:proyectoId",
              name: "admin-enmiendas-montos",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/enmiendas/AdminEnmiendasMontos.vue"
                )
            },

            {
              path:
                "administracion/proyectos/cns/enmiendas/fechas/:codigoProyecto/:proyectoId",
              name: "admin-enmiendas-fechas",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/enmiendas/AdminEnmiendasFechas.vue"
                )
            },

            {
              path: "iaff/:codigoProyecto/:proyectoId",
              name: "admin-iaff",
              component: () =>
                import("@/view/pages/proyectos-cns/iaff/AdminIaff.vue")
            },
            {
              path: "poa/:codigoProyecto/:proyectoId",
              name: "admin-poa",
              component: () =>
                import("@/view/pages/proyectos-cns/poa/AdminPoa.vue")
            },
            {
              path: "estimacion-presupuesto/admin-dash",
              name: "admin-estimacion-anual-interno-dash",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/estimacion-anual-presupuesto/admin/DashEstimacionAnualAdmin.vue"
                )
            },
            {
              path: "estimacion-presupuesto/:tipoEstimacion/:entidadId",
              name: "admin-estimacion-anual",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/estimacion-anual-presupuesto/AdminEstimacionAnualPresupuestoCns.vue"
                )
            },
            {
              path: "consolidado-estimacion-presupuesto/:entidadId",
              name: "admin-consolidado-estimacion-anual",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/estimacion-anual-presupuesto/ConsolidadoEstimacionAnualCns.vue"
                )
            },
            //Estimacion anual administrador
            {
              path: "estimacion-presupuesto/consolidado-general",
              name: "admin-consolidado-estimacion-anual-interno",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/estimacion-anual-presupuesto/admin/AdminConsolidadoEstimacionAnualInterno.vue"
                )
            }
          ] //End:: proyectos-cns children
        }, //End:: proyectos-cns

        //Proyectos Cooperacion Norte Sur
        {
          path: "/proyectos/cns/externo",
          name: "proyectos-cns-externo",
          component: () =>
            import("@/view/pages/proyectos-cns/ProyectosCns.vue"),
          children: [
            {
              path: "registro-informacion",
              name: "proyectos-cns-administracion",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/externo/AdminProyectosCnsExterno.vue"
                ),
              props: {
                validacionTerritorio: false
              }
            },

            {
              path: "vencimiento-proximo",
              name: "proyectos-cns-vencimiento-proximo",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/externo/AdminProyectosVencimientoProximo.vue"
                ),
              props: {
                validacionTerritorio: false
              }
            },

            {
              path: "validacion-territorio",
              name: "proyectos-cns-validcacion-territorio",
              component: () =>
                import(
                  "@/view/pages/proyectos-cns/externo/AdminProyectosCnsExterno.vue"
                ),
              props: {
                validacionTerritorio: true
              }
            }
          ]
        },

        //Cooperación Sur Sur Interno
        {
          path: "/proyectos/css/dad",
          name: "proyectos-css-dad",
          component: () =>
            import("@/view/pages/cooperacion-sur-sur/CooperacionSurSur.vue"),
          children: [
            {
              path: "negociacion-directa",
              name: "directa-dad",
              component: () =>
                import(
                  "@/view/pages/cooperacion-sur-sur/interno/negociacion-directa/AdminNegociacionDirectaDad.vue"
                )
            },
            {
              path: "negociacion-comixta",
              name: "comixta-dad",
              component: () =>
                import(
                  "@/view/pages/cooperacion-sur-sur/interno/negociacion-comixta/AdminNegociacionComixtaDad.vue"
                )
            }
          ]
        },

        //Cooperación Sur Sur Externo
        {
          path: "/proyectos/css/externo",
          name: "proyectos-css",
          component: () =>
            import("@/view/pages/cooperacion-sur-sur/CooperacionSurSur.vue"),
          children: [
            {
              path: "negociacion-directa",
              name: "directa",
              component: () =>
                import(
                  "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/AdminNegociacionDirectaExterna.vue"
                )
            },
            {
              path: "negociacion-directa/proyectos/:negociacionId",
              name: "negociacion-directa-proyectos",
              component: () =>
                import(
                  "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/AdminIniciativasDirecta.vue"
                )
            },
            {
              path:
                "negociacion-directa/proyectos/:negociacionId/seguimiento/:iniciativaId",
              name: "seguimiento-iniciativa",
              component: () =>
                import(
                  "@/view/pages/cooperacion-sur-sur/externo/negociacion-directa/iniciativas/seguimientos/AdminSeguimientosDirecta.vue"
                )
            },
            {
              path: "negociacion-comixta",
              name: "comixta",
              component: () =>
                import(
                  "@/view/pages/cooperacion-sur-sur/externo/negociacion-comixta/AdminNegociacionComixtaExterna.vue"
                )
            },

            {
              path: "negociacion-comixta/iniciativas/:negociacionId",
              name: "negociacion-comixta-iniciativas",
              component: () =>
                import(
                  "@/view/pages/cooperacion-sur-sur/externo/negociacion-comixta/iniciativas/AdminIniciativasComixta.vue"
                )
            }
          ]
        },

        /**************** MODULO DE ALIANZAS **********************/
        {
          path: "/alianzas",
          name: "alianzas",
          component: () => import("@/view/pages/alianzas/Alianzas.vue"),
          children: [
            {
              path: "registro",
              name: "registro-alianzas",
              component: () =>
                import("@/view/pages/alianzas/AdminAlianzasExterno.vue")
            },

            {
              path: "proyectos/:alianzaId",
              name: "proyectos-alianzas-segeplan",
              component: () =>
                import(
                  "@/view/pages/alianzas/proyectos/AdminProyectosAlianzas.vue"
                )
            }
          ]
        },

        /**************** MODULO DE ALIANZAS INTERNO SEGEPLAN **********************/
        {
          path: "/interno/alianzas",
          name: "alianzas-interno",
          component: () => import("@/view/pages/alianzas/Alianzas.vue"),
          children: [
            {
              path: "bandeja",
              name: "bandeja-alianzas",
              component: () =>
                import(
                  "@/view/pages/alianzas/segeplan/AdminAlianzasInterno.vue"
                )
            }
          ]
        },

        /**************** MODULO DE PROYECTOS COOPERACION CNSC INTERNO SEGEPLAN  **********************/
        {
          path: "/proyectos/cooperacion/interno",
          name: "proyectos-cooperacion-interno",
          component: () =>
            import(
              "@/view/pages/proyectos-cooperacion/ProyectosCooperacion.vue"
            ),
          children: [
            {
              path: "administracion",
              name: "administracion-proyectos-cooperacion-interno",
              component: () =>
                import(
                  "@/view/pages/proyectos-cooperacion/interno/AdminProyectosCooperacionSegeplan.vue"
                )
            },

            {
              path: "proyectos/:alianzaId",
              name: "proyectos-alianzas",
              component: () =>
                import(
                  "@/view/pages/alianzas/segeplan/proyectos/AdminProyectosAlianzasInterno.vue"
                )
            }
          ]
        },

        /**************** MODULO DE PROYECTOS COOPERACION CNSC EXTERNO  **********************/
        {
          path: "/proyectos/cooperacion/externo",
          name: "proyectos-cooperacion-externo",
          component: () =>
            import(
              "@/view/pages/proyectos-cooperacion/ProyectosCooperacion.vue"
            ),
          children: [
            {
              path: "registro",
              name: "registro-proyectos-cooperacion",
              component: () =>
                import(
                  "@/view/pages/proyectos-cooperacion/externo/AdminProyectosCooperacionExterno.vue"
                ),
              meta: { authorize: [Role.CooperanteBi, Role.CooperanteMulti] }
            },

            {
              path: "proyectos/:alianzaId",
              name: "proyectos-alianzas-externo",
              component: () =>
                import(
                  "@/view/pages/alianzas/proyectos/AdminProyectosAlianzas.vue"
                )
            }
          ]
        },

        /***************************** MODULO COOPERACION DESCENTRALIZADA REGISTRO MUNI ***************************/
        {
          path: "/proyectos/cooperacion-descentralizada",
          name: "proyectos-cooperacion-descentralizada",
          component: () =>
            import(
              "@/view/pages/proyectos-cooperacion-descentralizada/ProyectosCooperacionDescentralizada.vue"
            ),
          children: [
            {
              path: "administracion",
              name: "proyectos-descentralizada-admin",
              component: () =>
                import(
                  "@/view/pages/proyectos-cooperacion-descentralizada/interno/AdminProyectosCooperacionSegeplan.vue"
                ),
              props: {
                validacionTerritorio: false
              }
            },
            {
              path: "administracion/asignacion-seguimiento",
              name: "proyectos-descentralizada-asignacion-seguimiento",
              component: () =>
                import(
                  "@/view/pages/proyectos-cooperacion-descentralizada/interno/seguimiento/AsignacionSeguimientoCooperacionDesc.vue"
                ),
              props: {
                validacionTerritorio: false
              }
            },
            {
              path: "administracion/seguimiento",
              name: "proyectos-descentralizada-seguimiento",
              component: () =>
                import(
                  "@/view/pages/proyectos-cooperacion-descentralizada/interno/seguimiento/AdminProyectosCooperacionDescSeguimiento.vue"
                ),
              props: {
                validacionTerritorio: false
              }
            },
            {
              path:
                "administracion/seguimiento/registro/:codigoProyecto/:proyectoId",
              name: "proyectos-descentralizada-registro-seguimiento",
              component: () =>
                import(
                  "@/view/pages/proyectos-cooperacion-descentralizada/interno/seguimiento/AdminSeguimientosCooperacionDescentralizada.vue"
                ),
              props: {
                validacionTerritorio: false
              }
            },
            {
              path: "externo/registro",
              name: "proyectos-descentralizada-registro",
              component: () =>
                import(
                  "@/view/pages/proyectos-cooperacion-descentralizada/externo/AdminProyectosCooperacionDescentralizadaExterno.vue"
                ),
              props: {
                validacionTerritorio: false
              }
            },

            {
              path: "validacion-territorio",
              name: "proyectos-descentralizada-validacion",
              component: () =>
                import(
                  "@/view/pages/proyectos-cooperacion-descentralizada/externo/AdminProyectosCooperacionDescentralizadaExterno.vue"
                ),
              props: {
                validacionTerritorio: true
              }
            }
          ]
        },

        /*********** actores locales interno ************************* */
        {
          path: "/proyectos/actores-locales",
          name: "proyectos-actores-locales",
          component: () =>
            import(
              "@/view/pages/proyectos-actores-locales/ProyectosActoresLocales.vue"
            ),
          children: [
            {
              path: "interno",
              name: "proyectos-actores-locales-interno",
              component: () =>
                import(
                  "@/view/pages/proyectos-actores-locales/interno/AdminProyectosActoresLocalesInterno.vue"
                )
            },
            {
              path: "asignacion-seguimiento",
              name: "asignacion-seguimiento-acl",
              component: () =>
                import(
                  "@/view/pages/proyectos-actores-locales/interno/seguimiento/AsignacionSeguimientoActoresLocales.vue"
                )
              // meta: { authorize: [Role.CooperanteBi, Role.CooperanteMulti] }
            },
            {
              path: "administracion/seguimiento",
              name: "proyectos-actores-locales-seguimiento",
              component: () =>
                import(
                  "@/view/pages/proyectos-actores-locales/interno/seguimiento/AdminProyectosAclSeguimiento.vue"
                ),
              props: {
                validacionTerritorio: false
              }
            },
            {
              path:
                "administracion/seguimiento/registro/:codigoProyecto/:proyectoId",
              name: "proyectos-actores-locales-registro-seguimiento",
              component: () =>
                import(
                  "@/view/pages/proyectos-actores-locales/interno/seguimiento/AdminSeguimientosAcl.vue"
                ),
              props: {
                validacionTerritorio: false
              }
            }
          ]
        },

        /***************************** MODULO ACTORES LOCALES (EXTERNO) ***************************/
        {
          path: "/proyectos/actores-locales/ext",
          name: "proyectos-actores-locales-externo",
          component: () =>
            import(
              "@/view/pages/proyectos-actores-locales/ProyectosActoresLocales.vue"
            ),
          children: [
            {
              path: "registro",
              name: "registro-proyectos-actores-locales",
              component: () =>
                import(
                  "@/view/pages/proyectos-actores-locales/externo/AdminProyectosActoresLocalesExterno.vue"
                ),
              props: {
                registro: true, //Registro,
                validacion: false
              }
            },

            {
              path: "validacion",
              name: "proyectos-actores-locales-externo-validacion",
              component: () =>
                import(
                  "@/view/pages/proyectos-actores-locales/externo/AdminProyectosActoresLocalesExterno.vue"
                ),
              props: {
                registro: false, //Registro,
                validacion: true
              }
            }
          ]
        },

        //Reportes Administración
        {
          path: "/reportes",
          name: "reportes",
          component: () => import("@/view/pages/reportes/Reportes.vue"),
          children: [
            {
              path: "cns/documentos",
              name: "reportes-documentos-cns",
              component: () =>
                import(
                  "@/view/pages/reportes/cns/documentos/ReporteDocumentosCns.vue"
                ),
              props: {
                registro: true, //Registro,
                validacion: false
              }
            },
            {
              path: "cns/general",
              name: "reportes-general-cns",
              component: () =>
                import(
                  "@/view/pages/reportes/cns/ReporteGeneralProyectosCns.vue"
                ),
              props: {
                registro: true, //Registro,
                validacion: false
              }
            },
            {
              path: "cns/reporte-matriz",
              name: "reportes-matriz-cns",
              component: () =>
                import(
                  "@/view/pages/reportes/cns/ReporteMatrizProyectosCns.vue"
                ),
              props: {
                registro: true, //Registro,
                validacion: false
              }
            },
            {
              path: "cooperantes",
              name: "reportes-cooperantes",
              component: () =>
                import(
                  "@/view/pages/reportes/cooperantes/ReporteDocumentosCooperante.vue"
                ),
              props: {
                registro: true, //Registro,
                validacion: false
              }
            }
          ]
        },

        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/view/pages/vue-bootstrap/Alert.vue")
            },
            {
              path: "badge",
              name: "vue-bootstrap-badge",
              component: () => import("@/view/pages/vue-bootstrap/Badge.vue")
            },
            {
              path: "button",
              name: "vue-bootstrap-button",
              component: () => import("@/view/pages/vue-bootstrap/Button.vue")
            },
            {
              path: "button-group",
              name: "vue-bootstrap-button-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonGroup.vue")
            },
            {
              path: "button-toolbar",
              name: "vue-bootstrap-button-toolbar",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonToolbar.vue")
            },
            {
              path: "card",
              name: "vue-bootstrap-card",
              component: () => import("@/view/pages/vue-bootstrap/Card.vue")
            },
            {
              path: "carousel",
              name: "vue-bootstrap-carousel",
              component: () => import("@/view/pages/vue-bootstrap/Carousel.vue")
            },
            {
              path: "collapse",
              name: "vue-bootstrap-collapse",
              component: () => import("@/view/pages/vue-bootstrap/Collapse.vue")
            },
            {
              path: "dropdown",
              name: "vue-bootstrap-dropdown",
              component: () => import("@/view/pages/vue-bootstrap/Dropdown.vue")
            },
            {
              path: "embed",
              name: "vue-bootstrap-embed",
              component: () => import("@/view/pages/vue-bootstrap/Embed.vue")
            },
            {
              path: "form",
              name: "vue-bootstrap-form",
              component: () => import("@/view/pages/vue-bootstrap/Form.vue")
            },
            {
              path: "form-checkbox",
              name: "vue-bootstrap-form-checkbox",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormCheckbox.vue")
            },
            {
              path: "form-file",
              name: "vue-bootstrap-form-file",
              component: () => import("@/view/pages/vue-bootstrap/FormFile.vue")
            },
            {
              path: "form-group",
              name: "vue-bootstrap-form-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormGroup.vue")
            },
            {
              path: "form-input",
              name: "vue-bootstrap-form-input",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormInput.vue")
            },
            {
              path: "form-radio",
              name: "vue-bootstrap-form-radio",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormRadio.vue")
            },
            {
              path: "form-select",
              name: "vue-bootstrap-form-select",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormSelect.vue")
            },
            {
              path: "form-textarea",
              name: "vue-bootstrap-form-textarea",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormTextarea.vue")
            },
            {
              path: "image",
              name: "vue-bootstrap-image",
              component: () => import("@/view/pages/vue-bootstrap/Image.vue")
            },
            {
              path: "input-group",
              name: "vue-bootstrap-input-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/InputGroup.vue")
            },
            {
              path: "jumbotron",
              name: "vue-bootstrap-jumbotron",
              component: () =>
                import("@/view/pages/vue-bootstrap/Jumbotron.vue")
            },
            {
              path: "layout-grid-system",
              name: "vue-bootstrap-layout-grid-system",
              component: () =>
                import("@/view/pages/vue-bootstrap/LayoutGridSystem.vue")
            },
            {
              path: "link",
              name: "vue-bootstrap-link",
              component: () => import("@/view/pages/vue-bootstrap/Link.vue")
            },
            {
              path: "list-group",
              name: "vue-bootstrap-list-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ListGroup.vue")
            },
            {
              path: "media",
              name: "vue-bootstrap-media",
              component: () => import("@/view/pages/vue-bootstrap/Media.vue")
            },
            {
              path: "modal",
              name: "vue-bootstrap-modal",
              component: () => import("@/view/pages/vue-bootstrap/Modal.vue")
            },
            {
              path: "nav",
              name: "vue-bootstrap-nav",
              component: () => import("@/view/pages/vue-bootstrap/Nav.vue")
            },
            {
              path: "navbar",
              name: "vue-bootstrap-navbar",
              component: () => import("@/view/pages/vue-bootstrap/Navbar.vue")
            },
            {
              path: "pagination",
              name: "vue-bootstrap-pagination",
              component: () =>
                import("@/view/pages/vue-bootstrap/Pagination.vue")
            },
            {
              path: "pagination-nav",
              name: "vue-bootstrap-pagination-nav",
              component: () =>
                import("@/view/pages/vue-bootstrap/PaginationNav.vue")
            },
            {
              path: "notify-popover",
              name: "vue-bootstrap-popover",
              component: () => import("@/view/pages/vue-bootstrap/Popover.vue")
            },
            {
              path: "notify-toasts",
              name: "vue-bootstrap-toasts",
              component: () => import("@/view/pages/vue-bootstrap/Toasts.vue")
            },
            {
              path: "notify-tooltip",
              name: "vue-bootstrap-tooltip",
              component: () => import("@/view/pages/vue-bootstrap/Tooltip.vue")
            },
            {
              path: "progress",
              name: "vue-bootstrap-progress",
              component: () => import("@/view/pages/vue-bootstrap/Progress.vue")
            },
            {
              path: "progress-spinner",
              name: "vue-bootstrap-spinner",
              component: () => import("@/view/pages/vue-bootstrap/Spinner.vue")
            },
            {
              path: "table",
              name: "vue-bootstrap-table",
              component: () => import("@/view/pages/vue-bootstrap/Table.vue")
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/view/pages/vue-bootstrap/Tabs.vue")
            }
          ]
        },
        {
          path: "/vuetify",
          name: "vuetify",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "alerts",
              name: "vuetify-alerts",
              component: () => import("@/view/pages/vuetify/Alerts.vue")
            },
            {
              path: "avatars",
              name: "vuetify-avatars",
              component: () => import("@/view/pages/vuetify/Avatars.vue")
            },
            {
              path: "badges",
              name: "vuetify-badges",
              component: () => import("@/view/pages/vuetify/Badges.vue")
            },
            {
              path: "buttons",
              name: "vuetify-buttons",
              component: () => import("@/view/pages/vuetify/Buttons.vue")
            },
            {
              path: "calendars",
              name: "vuetify-calendars",
              component: () => import("@/view/pages/vuetify/Calendars.vue")
            },
            {
              path: "cards",
              name: "vuetify-cards",
              component: () => import("@/view/pages/vuetify/Cards.vue")
            },
            {
              path: "chips",
              name: "vuetify-chips",
              component: () => import("@/view/pages/vuetify/Chips.vue")
            },
            {
              path: "dialog",
              name: "vuetify-dialog",
              component: () => import("@/view/pages/vuetify/Dialog.vue")
            },
            {
              path: "forms-autocompletes",
              name: "vuetify-autocompletes",
              component: () =>
                import("@/view/pages/vuetify/forms/Autocompletes.vue")
            },
            {
              path: "forms-file-inputs",
              name: "vuetify-file-inputs",
              component: () =>
                import("@/view/pages/vuetify/forms/FileInputs.vue")
            },
            {
              path: "forms",
              name: "vuetify-forms",
              component: () => import("@/view/pages/vuetify/forms/Forms.vue")
            },
            {
              path: "forms-selection-controls",
              name: "vuetify-selection-controls",
              component: () =>
                import("@/view/pages/vuetify/forms/SelectionControls.vue")
            },
            {
              path: "forms-selects",
              name: "vuetify-selects",
              component: () => import("@/view/pages/vuetify/forms/Selects.vue")
            },
            {
              path: "forms-textareas",
              name: "vuetify-textareas",
              component: () =>
                import("@/view/pages/vuetify/forms/Textareas.vue")
            },
            {
              path: "forms-text-fields",
              name: "vuetify-text-fields",
              component: () =>
                import("@/view/pages/vuetify/forms/TextFields.vue")
            },
            {
              path: "tables-simple-tables",
              name: "vuetify-simple-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/SimpleTables.vue")
            },
            {
              path: "tables-data-tables",
              name: "vuetify-data-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/DataTables.vue")
            },
            {
              path: "tabs",
              name: "vuetify-tabs",
              component: () => import("@/view/pages/vuetify/Tabs.vue")
            },
            {
              path: "timelines",
              name: "vuetify-timelines",
              component: () => import("@/view/pages/vuetify/Timelines.vue")
            },
            {
              path: "tooltips",
              name: "vuetify-tooltips",
              component: () => import("@/view/pages/vuetify/Tooltips.vue")
            },
            {
              path: "treeview",
              name: "vuetify-treeview",
              component: () => import("@/view/pages/vuetify/Treeview.vue")
            }
          ]
        },
        {
          path: "/custom-wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue")
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue")
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue")
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue")
            }
          ]
        },
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue")
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue")
            }
          ]
        },
        {
          path: "/perfil",
          name: "perfil-usuario",
          component: () => import("@/view/pages/perfil/Perfil.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "profile-1",
              name: "profile-1",
              component: () => import("@/view/pages/profile/Profile-1.vue")
            },
            {
              path: "profile-2",
              name: "profile-2",
              component: () => import("@/view/pages/profile/Profile-2.vue")
            },
            {
              path: "profile-3",
              name: "profile-3",
              component: () => import("@/view/pages/profile/Profile-3.vue")
            },
            {
              path: "profile-4",
              name: "profile-4",
              component: () => import("@/view/pages/profile/Profile-4.vue")
            }
          ]
        }
      ]
    },

    // Rutas de acceso público
    {
      path: "/",
      redirect: "/inicio",
      component: () => import("@/view/layout/PublicLayout"),
      children: [
        {
          path: "/inicio",
          name: "inicio-informacion",
          component: () => import("@/view/pages/dashboard/PublicDashboard.vue")
        },
        {
          path: "/consultas",
          name: "publico-consultas",
          component: () => import("@/view/pages/externo/Externo.vue"),

          children: [
            {
              path: "proyectos/documentos",
              name: "consultas-proyectos-documentos",
              component: () =>
                import(
                  "@/view/pages/externo/reportes/ReporteDocumentosProyectos.vue"
                )
            }

            /*{
              path: "proyectos/fuentes-cooperantes",
              name: "consultas-proyectos-cooperantes",
              component: ()=> import("@/view/pages/externo/reportes/ReporteFuenteCooperanteProyectos.vue")
            },*/

            /* {
              path: "proyectos/fuentes-cooperantes",
              name: "consultas-proyectos-cooperantes",
              component: ()=> import("@/view/pages/externo/reportes/general/ReporteGeneralProyectos.vue")
            },*/
          ]
        },
        {
          path: "/solicitudes",
          name: "publico-solicitudes",
          component: () => import("@/view/pages/externo/Externo.vue"),

          children: [
            {
              path: "usuarios-proyecto",
              name: "solicitud-usuario-proyecto",
              component: () =>
                import(
                  "@/view/pages/externo/solicitud-usuarios/SolicitudUsuariosProyectosExterno.vue"
                )
            }
          ]
        }
      ]
    },

    ////Reportes sin menu
    {
      path: "/reportes/externo",
      name: "reportes-georreferenciacion-externos",
      component: () =>
        import(
          "@/view/pages/externo/reportes/georreferenciacion/Georreferenciacion.vue"
        ),
      children: [
        {
          path: "georreferenciacion/general",
          name: "georreferenciacion-general",
          component: () =>
            import(
              "@/view/pages/externo/reportes/georreferenciacion/GeorreferenciacionGeneral.vue"
            )
        },
        {
          path: "proyectos/fuentes-cooperantes",
          name: "consultas-proyectos-cooperantes",
          component: () =>
            import(
              "@/view/pages/externo/reportes/general/ReporteGeneralProyectos.vue"
            )
        },
        {
          path: "proyectos/cns",
          name: "consultas-proyectos-cns",
          component: () =>
            import(
              "@/view/pages/externo/reportes/general/ReporteGeneralCns.vue"
            )
        },
        {
          path: "cns/documentos",
          name: "reportes-iaff-cns",
          component: () =>
            import(
              "@/view/pages/reportes/cns/documentos/ReporteDocumentosCns.vue"
            ),
          props: {
            registro: true, //Registro,
            validacion: false
          }
        },
        {
          path: "cns/general",
          name: "reportes-general-cns",
          component: () =>
            import(
              "@/view/pages/externo/reportes/general/ReporteGeneralCns.vue"
            ),
          props: {
            registro: true, //Registro,
            validacion: false
          }
        }
      ]
    },

    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "mlogin",
          path: "/mlogin",
          component: () => import("@/view/pages/auth/login_pages/Login")
        },
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    },

    {
      // the 404 route, when none of the above matches
      path: "/testpd",
      name: "test-pdf",
      component: () => import("@/view/pages/test/testpdf.vue")
    },

    {
      // the 404 route, when none of the above matches
      path: "/unauthorized",
      name: "unauthorized",
      component: () => import("@/view/pages/error/Unauthorized.vue")
    }
  ]
});
