//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_COOPERANTES_CNSC = "obtenerCooperantes";
export const OBTENER_COOPERANTE_CNSC = "obtenerCooperante";

export const REGISTRAR_COOPERANTE_CNSC = "registrarCooperante";
export const ACTUALIZAR_COOPERANTE_CNSC = "actualizarCooperante";



// mutation types
export const SET_COOPERANTES_CNSC = "setCooperantes";
export const SET_COOPERANTE_CNSC = "setCooperante";


const state = {
  cooperantes: [],
  cooperante: {}
};

const getters = {
  obtnenerCooperantes(state) {
    return state.cooperantes;
  }
};

const actions = {
  async [OBTENER_COOPERANTES_CNSC](context, parametros) {
    return new Promise(resolve => {
        let endpoint = `Cooperantes/all/${parametros.estadoId}/${parametros.tipoFuenteCooperanteId}`;
        

      ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
        .then(({ data }) => {
          context.commit(SET_COOPERANTES_CNSC, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [OBTENER_COOPERANTE_CNSC](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=Cooperantes/`)
        .then(({ data }) => {
          context.commit(SET_COOPERANTE_CNSC, data.data);
          //console.log(this.Cooperantes)
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });;
        });
    });
  },

  

  /**
   * Registrar una institución
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_COOPERANTE_CNSC](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=Cooperantes`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_COOPERANTE_CNSC, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [ACTUALIZAR_COOPERANTE_CNSC](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=Cooperantes/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_COOPERANTE_CNSC, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },
 

};

const mutations = {
  [SET_COOPERANTES_CNSC](state, data) {
    state.cooperantes = data;
  },

  [SET_COOPERANTE_CNSC](state, data) {
    state.cooperante = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
