//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_INSTITUCIONES = "obtenerInstituciones";
export const OBTENER_INSTITUCION = "obtenerInstitucion";
export const OBTENER_INSTITUCION_DESCRIPCIONES ="obtenerInstitucionDescripciones";
export const REGISTAR_INSTITUCION = "registrarInstitucion";
export const ACTUALIZAR_INSTITUCION = "actualizarInstitucion";
export const RESTABLECER_PASS = "restablecerPassInstitucion";

export const OBTENER_AUTORIDADES_INSTITUCION = "obtenerAutoridadesInstitucion";
export const REGISTRAR_AUTORIDAD_INSTITUCION = "registrarAutoridadInstitucion";
// mutation types
export const SET_INSTITUCIONES = "setInstituciones";
export const SET_INSTITUCION = "setInstitucion";

export const SET_AUTORIDAD_INSTITUCION = "setAutoridadInstitucion";
export const SET_AUTORIDADES_INSTITUCION = "setAutoridadesInstitucion";

const state = {
  instituciones: [],
  institucion: {},
  autoridadInstitucion: [],
  autoridadesInstitucion: {}
};

const getters = {
  obtnenerInstituciones(state) {
    return state.instituciones;
  }
};

const actions = {
  async [OBTENER_INSTITUCIONES](context, parametros) {
    return new Promise(resolve => {
      let endpoint = "Instituciones"
      if(parametros && parametros.estadoId && parametros.nivelInstitucionId && parametros.tipoInstitucionId){
        endpoint = `Instituciones/all/${parametros.estadoId}/${parametros.nivelInstitucionId}/${parametros.tipoInstitucionId}`;
      }

      ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
        .then(({ data }) => {
          context.commit(SET_INSTITUCIONES, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [OBTENER_INSTITUCION](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=Instituciones/`)
        .then(({ data }) => {
          context.commit(SET_INSTITUCION, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });;
        });
    });
  },

  /**
   * Obtener los datos de una institución con los valores que corresponden a cada foranea
   * @param {*} context
   * @param {*} id
   * @returns
   */
  async [OBTENER_INSTITUCION_DESCRIPCIONES](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=api/Institucion/`)
        .then(({ data }) => {
          context.commit(SET_INSTITUCION, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  /**
   * Registrar una institución
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTAR_INSTITUCION](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=Instituciones`,
        datos
      )
        .then(({ data }) => {
          //console.log(data)
          context.commit(SET_INSTITUCION, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [ACTUALIZAR_INSTITUCION](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=Instituciones/`,
        datos
      )
        .then(({ data }) => {
          
          context.commit(SET_INSTITUCION, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  /**
   * Restablecer la contraseña del usuario master de la institucion
   */

  async [RESTABLECER_PASS](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.put(
        `apiconsume/update/${id}?endpoint=api/Institucion/ResetPass/`,
        datos.datosInstitucion
      )
        .then(({ data }) => {
          context.commit(SET_INSTITUCION, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },



  /**
   * Registrar autoridad de la institucion
   * @param {*} context
   * @param {*} datos
   * @returns
   */
   async [REGISTRAR_AUTORIDAD_INSTITUCION](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=AutoridadesInstitucion`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_AUTORIDAD_INSTITUCION, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },


  /**
   * Obtener las autoridades registradas de la institucion
   * @param {*} context
   * @param {*} id
   * @returns
   */
   async [OBTENER_AUTORIDADES_INSTITUCION](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=AutoridadesInstitucion/all/0/`)
        .then(({ data }) => {
          context.commit(SET_AUTORIDADES_INSTITUCION, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },




  

};

const mutations = {
  [SET_INSTITUCIONES](state, data) {
    state.instituciones = data;
  },

  [SET_INSTITUCION](state, data) {
    state.institucion = data;
  },

  [SET_AUTORIDAD_INSTITUCION](state, data){
    state.autoridadInstitucion = data;
  },

  [SET_AUTORIDADES_INSTITUCION](state, data){
    state.autoridadesInstitucion = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
