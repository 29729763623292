//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_FUENTES_MULTILATERALES = "obtenerFuentesMultilaterales";
export const OBTENER_FUENTE_MULTILATERAL = "obtenerFuenteMultilateral";
export const REGISTRAR_FUENTE_MULTILATERAL = "registrarFuenteMultilateral";
export const ACTUALIZAR_FUENTE_MULTILATERAL = "actualizarFuenteMultilateral";

// mutation types
export const SET_FUENTES_MULTILATERALES = "setFuentesMultilaterales";
export const SET_FUENTE_MULTILATERAL = "setFuenteMultilateral";

const state = {
  fuentesMultilaterales: [],
  fuenteMultilateral: []
};

const getters = {
  obtnenerFuentesMultilaterales(state) {
    return state.fuentesMultilaterales;
  }
};


const actions = {
  async [OBTENER_FUENTES_MULTILATERALES](context, parametros) {
    return new Promise(resolve => {
    
        let endpoint = `FuenteMultilateral/all/${parametros.estadoId}`
        ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
            .then(({ data }) => {
            context.commit(SET_FUENTES_MULTILATERALES, data.data);

            resolve(data);
            })
            .catch(error => {
            resolve({
                status: 400,
                message: error.message
            });

            //context.commit(SET_ERROR, response.data.errors);
            });
        });
  },


  
  async [OBTENER_FUENTE_MULTILATERAL](context, id) {
    return new Promise(resolve => {

      //context.commit(SET_FUENTE_MULTILATERAL, FuenteMultilateral);

      

      
      ApiService.query(`apiconsume/edit/${id}?endpoint=FuenteMultilateral/`)
        .then(({ data }) => {
          context.commit(SET_FUENTE_MULTILATERAL, data.data);
          //console.log(this.FuentesMultilaterales)
          resolve(data);
        })
        .catch(error => {
          resolve({
          status: 500,
          message:error,
          others: id,
          data: error
        });
        });
    });
  },

 

  /**
   * Registrar una institución
   * @param {*} context
   * @param {*} datosFuenteMultilateral
   * @returns
   */
  async [REGISTRAR_FUENTE_MULTILATERAL](context, datosFuenteMultilateral) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=FuenteMultilateral`,
        datosFuenteMultilateral
      )
        .then(({ data }) => {
          context.commit(SET_FUENTE_MULTILATERAL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
              status: 400,
              message: error.message
          });
        });
    });
  },

  async [ACTUALIZAR_FUENTE_MULTILATERAL](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=FuenteMultilateral/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_FUENTE_MULTILATERAL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
              status: 400,
              message: error.message
          });
        });
    });
  }
};

const mutations = {
  [SET_FUENTES_MULTILATERALES](state, data) {
    state.fuentesMultilaterales = data;
  },

  [SET_FUENTE_MULTILATERAL](state, data) {
    state.fuenteMultilateral = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
