//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_MECANISMOS_EJECUCION_CNS = "obtenerMecanismosEjecucionCns";
export const REGISTRAR_MECANISMO_EJECUCION_CNS = "registrarMecanismoEjecucionCns";
export const ELIMINAR_MECANISMO_EJECUCION_CNS = "eliminarMecanismoEjecucionCns";

export const SET_MECANISMOS_EJECUCION_CNS = "setMecanismosEjecucionCns";
export const SET_MECANISMO_EJECUCION_CNS = "setMecanismoEjecucionCns";


const state = {
    mecanismoEjecucion: {},
    mecanismosEjecucion: [],
};

const getters = {
  obtnenerMecanismosEjecucionCns(state) {
    return state.mecanismosEjecucion;
  }
};




const actions = {

    //OBTENER LOS MECANISMOS DE EJECUCION REGISTRADOS DEL PROYECTO 
    async [OBTENER_MECANISMOS_EJECUCION_CNS](context, parametros){
        return new Promise(resolve => {

        let id = parametros.id;

        ApiService.query(`apiconsume/obtener?endpoint=MecanismoEjecucionCNS/all/1/${id}`)
        .then(({ data }) => {
            context.commit(SET_MECANISMOS_EJECUCION_CNS, data.data);
            
            resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
            
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
    },

    //REGUSTRAR MECANISMO DE EJECUCION
    async [REGISTRAR_MECANISMO_EJECUCION_CNS](context, parametros){
        return new Promise(resolve => {
          ApiService.post(`apiconsume/create?endpoint=MecanismoEjecucionCNS`, parametros.datos)
          .then(({ data }) => {
              context.commit(SET_MECANISMO_EJECUCION_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  data: error})
              //context.commit(SET_ERROR, response.data.errors);
          });
        });   
    },


    /*
  * Eliminar un mecanismo de ejecucion del proyecto
  */
    async [ELIMINAR_MECANISMO_EJECUCION_CNS](context, id){
    
      return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=MecanismoEjecucionCNS/`)
          .then(({ data }) => {
              context.commit(SET_MECANISMO_EJECUCION_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
      });
  },  


};


const mutations = {
      
    [SET_MECANISMO_EJECUCION_CNS](state, data){
      state.mecanismoEjecucion = data;
    },
  
    [SET_MECANISMOS_EJECUCION_CNS](state, data){
      state.mecanismosEjecucion = data;  
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };
  