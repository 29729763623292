//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const REGISTRAR_SEGUIMIENTO_INICIATIVA = "registrarSeguimientoIniciativa";
export const OBTENER_SEGUIMIENTO_INICIATIVA = "obtenerSeguimientoIniciativa";
export const ACTUALIZAR_SEGUIMIENTO_INICIATIVA = "actualizarSeguimientoIniciativa";
export const OBTENER_SEGUIMIENTOS_INICIATIVA = "obtenerSeguimientosIniciativa";

export const REGISTRAR_EJECUCION_FISICA_SEGUIMIENTO = "registrarEjecucionFisicaSeguimiento";
export const OBTENER_EJECUCIONES_FISICAS_SEGUIMIENTO = "obtenerEjecucionesFisicasSeguimiento";
export const OBTENER_DOCUMENTOS_EJECUCION_FISICA = "obtenerDocumentosEjecucionFisica";

export const REGISTRAR_DOCUMENTO_EJECUCION_FISICA ="registrarDocumentoEjecucionFisicaIniciativa";

export const OBTENER_FOTOGRAFIAS_EJECUCION_FISICA = "obtenerFotografiasEjecucionFisica";
export const REGISTRAR_FOTOGRAFIA_EJECUCION_FISICA = "registrarFotografiaEjecucionFisica";

export const REGISTRAR_APORTE_ACTORES_EFIN = "registrarAporteSeguimientoIniciativa";
export const OBTENER_APORTES_ACTORES_EFIN = "obtenerAportesActoresEfin"
export const ELIMINAR_APORTE_ACTOR_EFIN = "eliminarAporteActorEfin";

export const OBTENER_DOCUMENTOS_SEGUIMIENTO_INICIATIVA = "obtenerDocumentosSeguimientoIniciativa";
export const REGISTRAR_DOCUMENTO_SEGUIMIENTO_INICIATIVA = "registrarDocumentoSeguimientoIniciativa";
export const ELIMINAR_DOCUMENTO_SEGUIMIENTO_INICIATIVA = "eliminarDocumentoSeguimientoIniciativa";

export const OBTENER_RESULTADO_ACTIVIDAD_SEGUIMIENTO = "obtenerResultadoActividadSeguimiento";
export const REGISTRAR_RESULTADO_ACTIVIDAD_SEGUIMIENTO = "registrarResultadoActividadSeguimiento";
export const ACTUALIZAR_RESULTADO_ACTIVIDAD_SEGUIMIENTO = "actualizarResultadoActividadSeguimiento";

export const REGISTRAR_FUNCIONARIOS_ACTIVIDAD_SEGUIMIENTO = "registrarFunctionionariosActividadSeguimiento";
export const OBTENER_FUNCIONARIOS_ACTIVIDAD_SEGUIMIENTO = "obtenerFunctionionarioActividadSeguimiento";

export const REGISTRAR_PARTICIPANTES_ACTIVIDAD_SEGUIMIENTO = "registrarParticipantesActividadSeguimiento";
export const OBTENER_PARTICIPANTES_ACTIVIDAD_SEGUIMIENTO = "obtenerParticipantesActividadSeguimiento";

export const SET_SEGUIMIENTOS_INICIATIVA = "setSeguimientosIniciativa";
export const SET_SEGUIMIENTO_INICIATIVA = "setSeguimientoIniciativa";

const state = {
    seguimientosIniciativa: [],
    seguimientoIniciativa: []
};

const getters = {
  obtnenerSeguimientos(state) {
    return state.seguimientosIniciativa;
  }
};


const actions = {


     /**
   * obtener seguimientos por iniciativa
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_SEGUIMIENTOS_INICIATIVA](context, parametros){
        return new Promise(resolve => {

        let id = parametros.id;

        ApiService.query(`apiconsume/obtener?endpoint=Seguimiento/all/1/${id}`)
        .then(({ data }) => {
            context.commit(SET_SEGUIMIENTOS_INICIATIVA, data.data);
            
            resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
            
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
    },   



    /**
   * Registrar un seguimiento
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_SEGUIMIENTO_INICIATIVA](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=Seguimiento`, datos)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


  async [OBTENER_SEGUIMIENTO_INICIATIVA](context, id) {
    return new Promise(resolve => {
      
        ApiService.query(`apiconsume/edit/${id}?endpoint=Seguimiento/`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTO_INICIATIVA,data.data);
                //console.log(this.instituciones)
                resolve(data)
            })
            .catch((error) => {
                
              resolve({
                status: 500,
                message: "Error",
                data: error
              });
                //context.commit(SET_ERROR, response.data.errors);
            });
    });
  },


  /**
   *  Actualizar datos de seguimiento
   * @param {*} context 
   * @param {*} datos 
   * @returns 
   */
  async [ACTUALIZAR_SEGUIMIENTO_INICIATIVA](context, datos) {
    let id = datos.id;
    let endpoint = datos.endpoint;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=${endpoint}/`,
        datos.datosItem
      )
        .then(({ data }) => {
          context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
          resolve(data);
        })
        .catch(error => {
            resolve({
              status: 500,
              message: error
          })
        });
    });
  },




       /**
   * obtener ejecuciones fisicas
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
        async [OBTENER_EJECUCIONES_FISICAS_SEGUIMIENTO](context, parametros){
            return new Promise(resolve => {
    
            let id = parametros.id;
    
            ApiService.query(`apiconsume/obtener?endpoint=EjecucionFisica/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTOS_INICIATIVA, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
        }, 
  

  //
    /**
   * Registrar EJECUCION FISICA
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
     async [REGISTRAR_EJECUCION_FISICA_SEGUIMIENTO](context, parametros) {

        let datos = parametros.datos;
         
    
        return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=EjecucionFisica`, datos)
                .then(({ data }) => {
                    context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });   
      },



          /**
   * obtener listado de docuemntos de las ejecuciones fisicas
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
           async [OBTENER_DOCUMENTOS_EJECUCION_FISICA](context, parametros){
            return new Promise(resolve => {
    
            let id = parametros.id;
    
            ApiService.query(`apiconsume/obtener?endpoint=DocumentosEjecucionFisica/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTOS_INICIATIVA, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
        }, 
      
      
    /**
   * Registrar Documento de ejecucion FISICA
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
     async [REGISTRAR_DOCUMENTO_EJECUCION_FISICA](context, parametros) {

        let datos = parametros.datos;
        let tipo = parametros.tipo;
        
        let endpoint = "DocumentosEjecucionFisica"
        if(tipo===2) endpoint = "FotografiasEjecucionFisica"; 
         
    
        return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=${endpoint}`, datos)
                .then(({ data }) => {
                    context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });   
      },





    /**
   * obtener listado de fotograficas de las ejecuciones fisicas
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
           async [OBTENER_FOTOGRAFIAS_EJECUCION_FISICA](context, parametros){
            return new Promise(resolve => {
    
            let id = parametros.id;
    
            ApiService.query(`apiconsume/obtener?endpoint=FotografiasEjecucionFisica/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTOS_INICIATIVA, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    }, 


    /**
   * Registrar Documento de ejecucion FISICA
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
     async [REGISTRAR_FOTOGRAFIA_EJECUCION_FISICA](context, parametros) {

        let datos = parametros.datos;
         
    
        return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=FotograficasEjecucionFisica`, datos)
                .then(({ data }) => {
                    context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });


      
    },


       /**
   * Registrar aporte de seguimiento
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_APORTE_ACTORES_EFIN](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=AporteActoresEFIN`, datos)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


    /**
   * obtener listado aportes actores efin
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
     async [OBTENER_APORTES_ACTORES_EFIN](context, parametros){
        return new Promise(resolve => {

        let id = parametros.id;

        ApiService.query(`apiconsume/obtener?endpoint=AporteActoresEFIN/all/1/${id}`)
        .then(({ data }) => {
            context.commit(SET_SEGUIMIENTOS_INICIATIVA, data.data);
            
            resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
            
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
}, 



  //Eliminar aporte presupuesto
  async [ELIMINAR_APORTE_ACTOR_EFIN](context, id){
    
        return new Promise(resolve => {
        ApiService.delete(`apiconsume/delete/${id}?endpoint=AporteActoresEFIN/`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });  

},

 ///////////////////////////////////// DOCUEMENTOS DEL SEGUIMIENTO ////////////////////////////////////////////////


 /**
   * obtener el listado de documentos correspondientes al seguimiento de la iniciativa
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [OBTENER_DOCUMENTOS_SEGUIMIENTO_INICIATIVA](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;

            ApiService.query(`apiconsume/obtener?endpoint=DocumentosInformacionAdicional/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTOS_INICIATIVA, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
        });
    }, 

       /**
   * Registrar documento para el seguimiento de iniciativa
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [REGISTRAR_DOCUMENTO_SEGUIMIENTO_INICIATIVA](context, parametros) {

        let datos = parametros.datos;
        

        return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=DocumentosInformacionAdicional`, datos)
                .then(({ data }) => {
                    context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });   
    },


  /**
   * Eliminar documento de la iniciativa
   * @param {*} context 
   * @param {*} id 
   * @returns 
   */
  async [ELIMINAR_DOCUMENTO_SEGUIMIENTO_INICIATIVA](context, id){
    
        return new Promise(resolve => {
            ApiService.delete(`apiconsume/delete/${id}?endpoint=DocumentosInformacionAdicional/`)
                .then(({ data }) => {
                    context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });  

    },



    /************************************ RESULTADOS DE LA ACTIVIDAD SEGUIMIENTO ********************************************/

    
   /**
   * obtener el resultado registrado de la actividad
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
     async [OBTENER_RESULTADO_ACTIVIDAD_SEGUIMIENTO](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
            let idActividad = parametros.idActividad;

            ApiService.query(`apiconsume/obtener?endpoint=SeguimientoActividades/${id}/${idActividad}`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTOS_INICIATIVA, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
        });
    }, 

    /**
     * Registrar el resultado de una activdad
     */
    async [REGISTRAR_RESULTADO_ACTIVIDAD_SEGUIMIENTO](context, parametros){
        let datos = parametros.datos;
       
        return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=SeguimientoActividades`, datos)
                .then(({ data }) => {
                    context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });   
    },


    /**
     * Actualizar el resultado de una activdad
     */
     async [ACTUALIZAR_RESULTADO_ACTIVIDAD_SEGUIMIENTO](context, datos) {
        let id = datos.id;
        let endpoint = "SeguimientoActividades";
    
        return new Promise(resolve => {
          ApiService.update(
            `apiconsume/update/${id}?endpoint=${endpoint}/`,
            datos.datosItem
          )
            .then(({ data }) => {
              context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
              resolve(data);
            })
            .catch(error => {
                resolve({
                  status: 500,
                  message: error
              })
            });
        });
      },
    

    


//////////////////////////////////// FUNCIONARIOS DE LA ACTIVIDAD EN SEGUIMIENTO ////////////////////////////////////////////


/**
 * Registrar funcionario de la actividad seguimiento
 */
 async [REGISTRAR_FUNCIONARIOS_ACTIVIDAD_SEGUIMIENTO](context, parametros){
    let datos = parametros.datos;
   
    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=FuncionariosActividades`, datos)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
},


 
   /**
   * obtener el resultado registrado de la actividad
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [OBTENER_FUNCIONARIOS_ACTIVIDAD_SEGUIMIENTO](context, parametros){
        return new Promise(resolve => {

            //let id = parametros.id;
            let estadoId = parametros.estadoId;
            let seguimientoActividadesId = parametros.seguimientoActividadesId;

            ApiService.query(`apiconsume/obtener?endpoint=FuncionariosActividades/all/${estadoId}/${seguimientoActividadesId}`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTOS_INICIATIVA, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
        });
    }, 


    /////////////////////////////////// PARTICIPANTES DE LA ACTIVIDAD ////////////////////////////////////
    /**
 * Registrar participante de la actividad seguimiento
 */
 async [REGISTRAR_PARTICIPANTES_ACTIVIDAD_SEGUIMIENTO](context, parametros){
    let datos = parametros.datos;
   
    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=ParticipantesActividades`, datos)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTO_INICIATIVA, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
},


 
   /**
   * obtener los participantes registrados de la actividad
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [OBTENER_PARTICIPANTES_ACTIVIDAD_SEGUIMIENTO](context, parametros){
        return new Promise(resolve => {

            //let id = parametros.id;
            let estadoId = parametros.estadoId;
            let seguimientoActividadesId = parametros.seguimientoActividadesId;

            ApiService.query(`apiconsume/obtener?endpoint=ParticipantesActividades/all/${estadoId}/${seguimientoActividadesId}`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTOS_INICIATIVA, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
        });
    }, 


    


    

};

//end actions 


const mutations = {
    [SET_SEGUIMIENTOS_INICIATIVA](state, data) {
      state.seguimientosIniciativa = data;
    },
  
    [SET_SEGUIMIENTO_INICIATIVA](state, data) {
      state.seguimientoIniciativa = data;
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };
  