//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_PRODUCTOS_ACTIVIDAD = "obtenerProductos";
export const OBTENER_PRODUCTO = "obtenerProducto";
export const REGISTRAR_PRODUCTO_ACTIVIDAD = "registrarProducto";
export const ACTUALIZAR_PRODUCTO = "actualizarProducto";
export const ELIMINAR_PRODUCTO_ACTIVIDAD = "eliminarProductoActividad";

// mutation types
export const SET_PRODUCTOS = "setproductos";
export const SET_PRODUCTO = "setProducto";

const state = {
  productos: [],
  producto: []
};

const getters = {
  obtnenerProductos(state) {
    return state.productos;
  }
};
const Producto = {
  id: 1,
  codigo: "CSSDIR-2022-00001",
  nombreProducto: "Nombre de la Producto 1",
  tipoProducto: "Tipo Producto 1",
  nombrePais: "Guatemala",
  mecanismo: "Mecanismo 1",
  pathDocumentoMecanismo: "negociaciones/directas/doc01.pdf",
  fechaAprobacion: "10/01/2022",
  fechaInicioVigencia: "15/01/2022",
  fechaFinVigencia: "31/12/2022",
  objetivo: "Esta es la descripción de la negociación directa #1",
  estadosId: 1,
  nombreEstado: "Registrada",
  paisesId: 1,
  modalidadId: 1,
  modalidadFinanciamiento: "Modalidad 1"
};


const actions = {
  async [OBTENER_PRODUCTOS_ACTIVIDAD](context, parametros) {
    return new Promise(resolve => {
     
            ApiService.query(`apiconsume/obtener?endpoint=ProductosEsperados/all/${parametros.estadoId}/${parametros.actividadId}`, '')
            .then(({ data }) => {
                context.commit(SET_PRODUCTOS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: error,
                data: null
              });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
    });
  },

  async [OBTENER_PRODUCTO](context, id) {
    return new Promise(resolve => {
      //Quitar
      console.log(id);
      context.commit(SET_PRODUCTO, Producto);

      resolve({
        status: 200,
        message: "Ok",
        data: Producto
      });

      /*
            ApiService.query(`apiconsume/edit/${id}?endpoint=api/Institucion/`)
                .then(({ data }) => {
                    context.commit(SET_PRODUCTO,data.data);
                    //console.log(this.instituciones)
                    resolve(data)
                })
                .catch((error) => {
                    
                    resolve(error)
                    //context.commit(SET_ERROR, response.data.errors);
                });*/
    });
  },

  /**
   * Registrar una negociacion directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PRODUCTO_ACTIVIDAD](context, datos) {
   return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=ProductosEsperados`, datos)
                .then(({ data }) => {
                    context.commit(SET_PRODUCTO, data.data);
                    resolve(data)
                })
                .catch((error) => {
                  resolve({
                    status: 500,
                    message: "Error",
                    data: error
                  });
                });
        });  
  },

  async [ACTUALIZAR_PRODUCTO](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=api/ProductosEsperados/`,
        datos.datosInstitucion
      )
        .then(({ data }) => {
          context.commit(SET_PRODUCTO, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_PRODUCTO_ACTIVIDAD](context, id){
    return new Promise(resolve => {
        ApiService.delete(`apiconsume/delete/${id}?endpoint=ProductosEsperados/`)
            .then(({ data }) => {
                context.commit(SET_PRODUCTO, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });  
  }
};

const mutations = {
  [SET_PRODUCTOS](state, data) {
    state.productos = data;
  },

  [SET_PRODUCTO](state, data) {
    state.producto = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
