const RoleRedirect = {
  getRouteByRole(roles) {
    let routeName = "login";
    ///  console.log(roles)
    if (
      roles.includes("SCO$ADMINISTRADOR") ||
      roles.includes("SCO$SUBADMINISTRADOR")
    ) {
      routeName = "dashboard-administrador";
    } else if (
      roles.includes("SCO$ADMINISTRADOR") ||
      roles.includes("SCO$SUBADMINISTRADOR")
    ) {
      routeName = "dashboard-administrador";
    } else if (roles.includes(601)) {
      routeName = "dashboard-utc";
    } else if (
      roles.includes("SCO$INSTCNS") ||
      roles.includes("SCO$INSTCSSC") ||
      roles.includes("SCO$INSTCSSD") ||
      roles.includes("SCO$INSTALIANZAS")
    ) {
      routeName = "dashboard-institucion";
    } else if (
      roles.includes("SCO$COOPERANTEBI") ||
      roles.includes("SCO$COOPERANTEBI")
    ) {
      routeName = "dashboard-cooperante";
    } else if (roles.includes("SCO$INSALIANZAS")) {
      routeName = "dashboard-alianzas";
    } else if (
      roles.includes("SCO$SEGUIMIENTOCNS") ||
      roles.includes("SCO$SEGUIMIENTOCSS") ||
      roles.includes("SCO$SEGUIMIENTOALIANZAS") ||
      roles.includes("SCO$SEGUIMIENTOCNSC") ||
      roles.includes("SCO$SEGUIMIENTOACTLOCALES") ||
      roles.includes("SCO$SEGUIMIENTOCOODESC")
    ) {
      routeName = "dashboard-seguimiento";
    } else if (roles.includes(401)) {
      routeName = "dashboard-sur-sur";
    } else {
      routeName = "dashboard";
    }

    return routeName;
  }
};
export default RoleRedirect;
