///Asignación de usuarios a proyectos


//import axios from "axios";
import ApiService from "@/core/services/api.service";

export const OBTENER_USUARIOS_PROYECTOS = "obtenerUsuarioSProyectoS";

export const ACTUALIZAR_USUARIO_PROYECTO = "actualizarUsuarioProyecto";


// mutation types
export const SET_USUARIOS_PROYECTOS = "setUsuariosProyectos";
export const SET_USUARIO_PROYECTO = "setUsuarioProyecto";

const state = {
    usuariosProyectos: [],
    usuarioProyecto: {}
};

const getters = {
  obtenerUsuariosProyectos(state) {
    return state.usuariosProyectos;
  }
};



const actions = {


    async [OBTENER_USUARIOS_PROYECTOS](context, parametros) {
        return new Promise(resolve => {
    
            ApiService.query(`apiconsume/obtener?endpoint=AsignacionUsuarioProyectos/all/${parametros.estadoId}`, '')
            .then(({ data }) => {
                context.commit(SET_USUARIOS_PROYECTOS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: "Error",
                data: error
              });
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
      },

/**
   * Actualizar el estado de la asignacion del usuario al proyecto
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */

  async [ACTUALIZAR_USUARIO_PROYECTO](context, parametros){

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update2?endpoint=AsignacionUsuarioProyectos/${parametros.id}`, parametros)
                  .then(({ data }) => {
                      context.commit(SET_USUARIO_PROYECTO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  }


};

const mutations = {
    [SET_USUARIOS_PROYECTOS](state, data) {
      state.usuariosProyectos = data;
    },
  
    [SET_USUARIO_PROYECTO](state, data) {
      state.usuarioProyecto = data;
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };