//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_ODSS = "obtenerOdss";
export const ELIMINAR_ODS = "eliminarOds";
export const OBTENER_ODS = "obtenerOds";
export const REGISTRAR_ODS = "registrarOds";
export const ACTUALIZAR_ODS = "actualizarOds";

// mutation types
export const SET_ODSS = "setOdss";
export const SET_ODS = "setOds";

const state = {
  ods: {},
  odss: []
};

const getters = {
  obtnenerODS(state) {
    return state.ods;
  }
};

const actions = {
  async [OBTENER_ODSS](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ODS/all/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_ODSS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_ODS](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=ODS/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_ODS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_ODS](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=ODS/`)
        .then(({ data }) => {
          context.commit(SET_ODS, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una ODSs directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_ODS](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=ODS`, datos)
        .then(({ data }) => {
          context.commit(SET_ODS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_ODS](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=ODS/`, datos)
        .then(({ data }) => {
          context.commit(SET_ODS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_ODSS](state, data) {
    state.odss = data;
  },

  [SET_ODS](state, data) {
    state.ods = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
