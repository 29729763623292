//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_SOCIOS_INSTRUMENTO_ALIANZA = "obtenerSociosAlianza";
export const OBTENER_SOCIO_INSTRUMENTO_ALIANZA = "obtenerSocioAlianza";
export const REGISTRAR_SOCIO_INSTRUMENTO_ALIANZA = "registrarSocioAlianza";
export const ACTUALIZAR_SOCIO_INSTRUMENTO_ALIANZA = "actualizarSocioAlianza";
export const ELIMINAR_SOCIO_INSTRUMENTO_ALIANZA = "eliminarSocioInstrumentoAlianza";


//Proyectos de la alianza
export const OBTENER_SOCIOS_PROYECTO_ALIANZA = "obtenerSociosProyectoAlianza";

// mutation types
export const SET_SOCIOS_INSTRUMENTO_ALIANZA = "setSociosAlianza";
export const SET_SOCIO_INSTRUMENTO_ALIANZA = "setsScioAlianza";

const state = {
  sociosAlianza: [],
  socioAlianza: []
};

const getters = {
  obtnenerSociosAlianza(state) {
    return state.sociosAlianza;
  }
};

/**
 * Tipo 1 es directa y 2 es comixta
 */
const actions = {
  async [OBTENER_SOCIOS_INSTRUMENTO_ALIANZA](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=SociosAlianza/all/alianza/${parametros.estadoId}/${parametros.alianzaId}`, '')
        .then(({ data }) => {
            context.commit(SET_SOCIOS_INSTRUMENTO_ALIANZA, data.data);
            
            resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error
          });
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },


  /**
   * Obtener los socios del proyecto alianza
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */
  async [OBTENER_SOCIOS_PROYECTO_ALIANZA](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=SociosAlianza/all/${parametros.estadoId}/${parametros.proyectoAlianzaId}`, '')
        .then(({ data }) => {
            context.commit(SET_SOCIOS_INSTRUMENTO_ALIANZA, data.data);
            
            resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error
          });
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },


  

  async [OBTENER_SOCIO_INSTRUMENTO_ALIANZA](context, id) {
    return new Promise(resolve => {
      
        ApiService.query(`apiconsume/edit/${id}?endpoint=SociosAlianza/`)
            .then(({ data }) => {
                context.commit(SET_SOCIO_INSTRUMENTO_ALIANZA,data.data);
                //console.log(this.instituciones)
                resolve(data)
            })
            .catch((error) => {
                
              resolve({
                status: 500,
                message: "Error",
                data: error
              });
                //context.commit(SET_ERROR, response.data.errors);
            });
    });
  },

  /**
   * Registrar una socioAlianza directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_SOCIO_INSTRUMENTO_ALIANZA](context, datos) {
    return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=SociosAlianza`, datos)
                .then(({ data }) => {
                    context.commit(SET_SOCIO_INSTRUMENTO_ALIANZA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },

  async [ACTUALIZAR_SOCIO_INSTRUMENTO_ALIANZA](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=SociosAlianza/`,
        datos.datosItem
      )
        .then(({ data }) => {
          context.commit(SET_SOCIO_INSTRUMENTO_ALIANZA, data.data);
          resolve(data);
        })
        .catch(error => {
            resolve({
              status: 500,
              message: error
          })
        });
    });
  },

  async [ELIMINAR_SOCIO_INSTRUMENTO_ALIANZA](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=SociosAlianza/`)
          .then(({ data }) => {
              context.commit(SET_SOCIO_INSTRUMENTO_ALIANZA, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
}

  


};

const mutations = {
  [SET_SOCIOS_INSTRUMENTO_ALIANZA](state, data) {
    state.sociosAlianza = data;
  },

  [SET_SOCIO_INSTRUMENTO_ALIANZA](state, data) {
    state.socioAlianza = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
