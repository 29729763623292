//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_SECTORES_COOPERANTE = "obtenerSectoresCooperante";
export const OBTENER_SECTOR_COOPERANTE = "obtenerSectorCooperante";

export const REGISTRAR_SECTOR_COOPERANTE = "registrarSectorCooperante";
export const ELIMINAR_SECTOR_COOPERANTE = "eliminarSectorCooperante";



// mutation types
export const SET_SECTORES_COOPERANTE = "setSectoresCooperante";
export const SET_SECTOR_COOPERANTE = "setSectorCooperante";


const state = {
  sectoresCooperante: [],
  sectorCooperante: {}
};

const getters = {
  obtnenerSectoresCooperante(state) {
    return state.sectoresCooperante;
  }
};

const actions = {
  async [OBTENER_SECTORES_COOPERANTE](context, parametros) {
    return new Promise(resolve => {
        let endpoint = `CooperantesSectores/all/${parametros.estadoId}/${parametros.cooperanteId}`;
        

      ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
        .then(({ data }) => {
          context.commit(SET_SECTORES_COOPERANTE, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [OBTENER_SECTOR_COOPERANTE](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=CooperantesSectores/`)
        .then(({ data }) => {
          context.commit(SET_SECTOR_COOPERANTE, data.data);
          //console.log(this.sectoresCooperante)
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });;
        });
    });
  },

  

  /**
   * Registrar una institución
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_SECTOR_COOPERANTE](context, parametros) {
    //console.log(datosEmpresa)
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=CooperantesSectores`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_SECTOR_COOPERANTE, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [ELIMINAR_SECTOR_COOPERANTE](context, datos) {
    
    return new Promise(resolve => {
        ApiService.delete(`apiconsume/delete/${datos.id}?endpoint=CooperantesSectores/`)
            .then(({ data }) => {
                context.commit(SET_SECTOR_COOPERANTE, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });  
  },
 

};

const mutations = {
  [SET_SECTORES_COOPERANTE](state, data) {
    state.sectoresCooperante = data;
  },

  [SET_SECTOR_COOPERANTE](state, data) {
    state.sectorCooperante = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
