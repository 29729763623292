//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_SECTORES_SIDCSS = "obtenerSectorSIDCSS";
export const ELIMINAR_SECTOR_SIDCSS = "eliminarSectorSidCss";
export const OBTENER_SECTOR_SIDCSS = "obtenerSectorSidCss";
export const REGISTRAR_SECTOR_SIDCSS = "registrarSectorSidCss";
export const ACTUALIZAR_SECTOR_SIDCSS = "actualizarSectorSidCss";

// mutation types
export const SET_SECTORES_SIDCSS = "setSectorSIDCSS";
export const SET_SECTOR_SIDCSS = "setSectorSidCss";

const state = {
    sectoresSidCss: [],
    sectorSidCss: {}
};

const getters = {
  obtnenerSectorSIDCSS(state) {
    return state.sectoresSidCss;
  }
};


const actions = {
  async [OBTENER_SECTORES_SIDCSS](context, estadoId) {
    return new Promise(resolve => {
        
            ApiService.query(`apiconsume/obtener?endpoint=SectorSIDCSS`, estadoId)
            .then(({ data }) => {
                context.commit(SET_SECTORES_SIDCSS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: error
              })
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
    });
  },

  async [ELIMINAR_SECTOR_SIDCSS](context, datos) {
    return new Promise(resolve => {
        
            ApiService.delete(`apiconsume/delete/${datos.nuevoEstadoId}?endpoint=SectorSIDCSS/${datos.id}/`, )
            .then(({ data }) => {
                context.commit(SET_SECTOR_SIDCSS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: error
              })
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
    });
  },

  

  async [OBTENER_SECTOR_SIDCSS](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=SectorSIDCSS/`)
        .then(({ data }) => {
          context.commit(SET_SECTOR_SIDCSS, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una SectorSidCss directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_SECTOR_SIDCSS](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=SectorSIDCSS`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_SECTOR_SIDCSS, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_SECTOR_SIDCSS](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=SectorSIDCSS/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_SECTOR_SIDCSS, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_SECTORES_SIDCSS](state, data) {
    state.sectoresSidCss = data;
  },

  [SET_SECTOR_SIDCSS](state, data) {
    state.sectorSidCss = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
