//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types

export const OBTENER_PRESUPUESTO_GENERAL_INICIATIVA_CSS = "obtenerPresupuestoGeneralIniciativaCss";
export const OBTENER_COSTOS_GLOBALES = "obtenerObtenerDatosCostosRol";
export const REGISTRAR_PRESUPUESTO_GENERAL = "registrarPresupuestoGeneralIniciativaCss";
export const ACTUALIZAR_PRESUPUESTO_GENERAL = "actualizarPresupuestoGeneralIniciativaCss";
export const REGISTRAR_PRESUPUESTO_ACTORES = "registrarRegistrarCostosRol"
export const ELIMINAR_PRESUPUESTO_GENERAL = "eliminarPresupuestoGeneral";


//Anual
export const OBTENER_COSTOS_ANUALES = "obtenerCostosAnuales";
export const REGISTRAR_PRESUPUESTO_AUAL = "registrarPresupuestoAnual";
export const ELIMINAR_PRESUPUESTO_ANUAL = "eliminarPresupuestoAnual";


export const SET_COSTOS_ROL = "setCostosRol";
export const SET_COSTO_ROL = "setCostoRol";

const state = {
    costosRol: [],
    costoRol: []
};

const getters = {
  obtnenerPresupuestos(state) {
    return state.costosRol;
  }
};


const actions = {



    /**
     * Obtener el presupuesto general
     * @param {*} context 
     * @param {*} parametros 
     * @returns 
     */
     async [OBTENER_PRESUPUESTO_GENERAL_INICIATIVA_CSS](context, parametros){
        return new Promise(resolve => {

            let iniciativaId = parametros.iniciativaId;
           // let estadoId = parametros.estadoId;
         
     
            ApiService.query(`apiconsume/obtener?endpoint=/PresupuestoGeneral/${iniciativaId}`)
            .then(({ data }) => {
                context.commit(SET_COSTOS_ROL, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


    /**
     * Obtener los costos globales de presupuest (Actores y Roles)
     * @param {*} context 
     * @param {*} parametros 
     * @returns 
     */
    async [OBTENER_COSTOS_GLOBALES](context, parametros){
        return new Promise(resolve => {

            let iniciativaId = parametros.iniciativaId;
            let estadoId = parametros.estadoId;
         
     
            ApiService.query(`apiconsume/obtener?endpoint=/Presupuesto/all/${estadoId}/${iniciativaId}`)
            .then(({ data }) => {
                context.commit(SET_COSTOS_ROL, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


     /**
     * Obtener los costos globales de presupuest (Actores y Roles)
     * @param {*} context 
     * @param {*} parametros 
     * @returns 
     */
      async [OBTENER_COSTOS_ANUALES](context, parametros){
        return new Promise(resolve => {

            let presupuestoId = parametros.presupuestoId;
            let estadoId = parametros.estadoId;
         
     
            ApiService.query(`apiconsume/obtener?endpoint=/PresupuestoPorAnio/all/${estadoId}/${presupuestoId}`)
            .then(({ data }) => {
                context.commit(SET_COSTOS_ROL, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


    /**
   * Registrar presupuesto general
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_PRESUPUESTO_GENERAL](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=PresupuestoGeneral`, datos)
            .then(({ data }) => {
                context.commit(SET_COSTO_ROL, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


   /**
   * ACTUALIZAR presupuesto general
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [ACTUALIZAR_PRESUPUESTO_GENERAL](context, parametros) {

        let datos = parametros.datos;
         
    
        return new Promise(resolve => {
            ApiService.update(`apiconsume/update/${datos.id}?endpoint=PresupuestoGeneral/`, datos)
                .then(({ data }) => {
                    context.commit(SET_COSTO_ROL, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });   
      },


  

   /**
   * Registrar presupuesto por actores
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_PRESUPUESTO_ACTORES](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=Presupuesto`, datos)
            .then(({ data }) => {
                context.commit(SET_COSTO_ROL, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


  /**
   * Registrar el presupuesto anual
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
   async [REGISTRAR_PRESUPUESTO_AUAL](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=PresupuestoPorAnio`, datos)
            .then(({ data }) => {
                context.commit(SET_COSTO_ROL, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  
  async [ELIMINAR_PRESUPUESTO_GENERAL](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=Presupuesto/`)
          .then(({ data }) => {
              context.commit(SET_COSTO_ROL, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
},

/**
 * Eliominar regitro de presupuesto anual
 * @param {} context 
 * @param {*} id 
 * @returns 
 */

async [ELIMINAR_PRESUPUESTO_ANUAL](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=PresupuestoPorAnio/`)
          .then(({ data }) => {
              context.commit(SET_COSTO_ROL, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
},




};

const mutations = {
  [SET_COSTOS_ROL](state, data) {
    state.contactos = data;
  },

  [SET_COSTO_ROL](state, data) {
    state.contacto = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

