//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_PERIODOS = "obtenerPeriodos";
export const ELIMINAR_PERIODO = "eliminarPeriodo";
export const OBTENER_PERIODO = "obtenerPeriodo";
export const REGISTRAR_PERIODO = "registrarPeriodo";
export const ACTUALIZAR_PERIODO = "actualizarPeriodo";

// mutation types
export const SET_PERIODOS = "setPeriodos";
export const SET_PERIODO = "setPeriodo";

const state = {
    periodos: [],
    periodo: {}
};

const getters = {
  obtnenerPeriodos(state) {
    return state.periodos;
  }
};


const actions = {
  async [OBTENER_PERIODOS](context, estadoId) {
    return new Promise(resolve => {
        
            ApiService.query(`apiconsume/obtener?endpoint=Periodos/all/${estadoId}`, '')
            .then(({ data }) => {
                context.commit(SET_PERIODOS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: error
              })
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
    });
  },

  async [ELIMINAR_PERIODO](context, datos) {
    return new Promise(resolve => {
        
            ApiService.delete(`apiconsume/delete/${datos.nuevoEstadoId}?endpoint=Periodos/${datos.id}/`, )
            .then(({ data }) => {
                context.commit(SET_PERIODO, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: error
              })
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
    });
  },

  

  async [OBTENER_PERIODO](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=Periodos/`)
        .then(({ data }) => {
          context.commit(SET_PERIODO, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una Periodo directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PERIODO](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=Periodos`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PERIODO, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_PERIODO](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=Periodos/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PERIODO, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_PERIODOS](state, data) {
    state.periodos = data;
  },

  [SET_PERIODO](state, data) {
    state.periodo = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
