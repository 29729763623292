//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_ESTIMACIONES_ENTIDAD = "obtenerEstimacionesEntidad";
export const REGISTRAR_ESTIMACION_ANUAL_CNS = "registrarEstimacionAnualCNS";
export const ELIMINAR_ESTIMACION_ANUALES_CNS = "eliminarEstimacionAnualCNS";
export const OBTENER_ESTIMACION_ANUAL_CNS = "obtenerEstimacionAnualCNS";
export const ACTUALIZAR_ESTIMACION_ANUAL_CNS =
  "actualizarEstadoEstimacionAnualCNS";
export const GENERAR_CONSOLIDADO_ESTIMACION_ANUAL_CNS =
  "generarConsolidadoEstimacionAnulaCns";
export const OBTENER_CONSOLIDADO_ESTIMACIONES_ENTIDAD =
  "obtenerConsoliddaoEstimacionesEntidadCns";
export const ACTUALIZAR_ESTADO_ESTIMACION_ANUAL_CNS =
  "activarDesactivarEstimacionAnualCns";
export const CONFIRMAR_ENMIENDA_FECHAS_CNS = "validarEstimacionesAnualesCns";
export const CONFIRMAR_ESTIMACION_ANUAL_CNS = "confirmarEstimacionAnualCns";
export const SET_ESTIMACIONES_ANUALES_CNS = "setEstimacionesAnualesCns";
export const SET_ESTIMACION_ANUAL_CNS = "setEstimacionAnualCNS";

const state = {
  estimacionAnualCns: {},
  estimacionesAnualesCns: []
};

const getters = {
  obtnenerEstimacionesAnualesCns(state) {
    return state.estimacionesAnualesCns;
  }
};

const actions = {
  //OBTENER LOS MECANISMOS DE EJECUCION REGISTRADOS DEL PROYECTO
  async [OBTENER_ESTIMACIONES_ENTIDAD](context, parametros) {
    return new Promise(resolve => {
      //console.log(id + " " + estadoId);

      ApiService.query(
        `apiconsume/obtener?endpoint=EstimacionAnualCNS/${parametros.entidadId}/${parametros.tipoEstimacion}/${parametros.estadoId}`
      )
        .then(({ data }) => {
          context.commit(SET_ESTIMACIONES_ANUALES_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //REGUSTRAR MECANISMO DE EJECUCION
  async [REGISTRAR_ESTIMACION_ANUAL_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EstimacionAnualCNS`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_ESTIMACION_ANUAL_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //Obtener el avance del proyecto CNS
  async [OBTENER_ESTIMACION_ANUAL_CNS](context, parametros) {
    let id = parametros.id;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=EstimacionAnualCNS/`)
        .then(({ data }) => {
          context.commit(SET_ESTIMACION_ANUAL_CNS, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /*
   * Eliminar un mecanismo de ejecucion del proyecto
   */
  async [ELIMINAR_ESTIMACION_ANUALES_CNS](context, datos) {
    let id = datos.id;
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=EstimacionAnualCNS/`)
        .then(({ data }) => {
          context.commit(SET_ESTIMACION_ANUAL_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /*
   * Eliminar un mecanismo de ejecucion del proyecto
   */
  async [CONFIRMAR_ESTIMACION_ANUAL_CNS](context, datos) {
    let id = datos.id;
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=EstimacionAnualCNS/Confirmar/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_ESTIMACION_ANUAL_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /*
   * Actualizar el estado de una enmienda de fechas
   */
  async [ACTUALIZAR_ESTIMACION_ANUAL_CNS](context, datos) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${datos.estadoId}?endpoint=EstimacionAnualCNS/ActualizarEstado/${datos.id}/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_ESTIMACION_ANUAL_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /*
   * Actualizar el estado de una estimacion anual
   */
  async [ACTUALIZAR_ESTADO_ESTIMACION_ANUAL_CNS](context, datos) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${datos.estadoId}?endpoint=EstimacionAnualCNS/ActualizarEstado/${datos.id}/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_ESTIMACION_ANUAL_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },


  

  async [CONFIRMAR_ENMIENDA_FECHAS_CNS](context, datos) {
    let id = datos.id;
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=EstimacionAnualCNS/Confirmar/`, datos)
        .then(({ data }) => {
          context.commit(SET_ESTIMACION_ANUAL_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  //GENERAR CONSOLIDADO DE ESTIMACIONES
  async [GENERAR_CONSOLIDADO_ESTIMACION_ANUAL_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EstimacionAnualCNS/Consolidado`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_ESTIMACION_ANUAL_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //OBTENER LOS CONSOLIDADOS DE ESTIMACION POR ENTIDAD
  async [OBTENER_CONSOLIDADO_ESTIMACIONES_ENTIDAD](context, parametros) {
    return new Promise(resolve => {
      //console.log(id + " " + estadoId);

      ApiService.query(
        `apiconsume/obtener?endpoint=EstimacionAnualCNS/Consolidado/${parametros.entidadId}`
      )
        .then(({ data }) => {
          context.commit(SET_ESTIMACIONES_ANUALES_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_ESTIMACION_ANUAL_CNS](state, data) {
    state.estimacionAnualCns = data;
  },

  [SET_ESTIMACIONES_ANUALES_CNS](state, data) {
    state.estimacionesAnualesCns = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
