//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_DOCUMENTOS_PROYECTO_CNS = "obtenerDocumentosAsignadosCNS";
export const ASIGNAR_DOCUMENTO_CNS = "asignarDocumentoProyectoCNS";
export const ACTUALIZAR_ASIGNACION_DOCUMENTO_CNS = "actualizarAsignacionDocumentoCns";
export const ELIMINAR_ASIGNACION_DOCUMENTO_CNS = "eliminarAsignacionDocumentoCns";

// mutation types
export const SET_DOCUMENTOS_CNS = "setDocumentosAsignadosCNS";
export const SET_DOCUMENTO_CNS = "setDocumentoAsignadoCNS";



const state = {
    documentosCNS: [],
    documentoCNS: [],
};

const getters = {
  obtnenerDocumentosProyectoCns(state) {
    return state.beneficiarios;
  }
};

/*const beneficiario = {
  id: 1,
  numeroBeneficiados: "beneficiario 1",
  nombreEtnia: 'Indigena 1",
  nombreEstado: "Registrada",
  estadosId: 1
};

*/


const actions = {
  async [OBTENER_DOCUMENTOS_PROYECTO_CNS](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=AsigDocumentosCNS/all/${parametros.estadoId}/${parametros.proyectoId}/${parametros.tipoProyectoId}`)
        .then(({ data }) => {
          context.commit(SET_DOCUMENTO_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },


  /**
   * Registrar una condicion
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [ASIGNAR_DOCUMENTO_CNS](context, parametros) {
    //console.log(datosEmpresa)
    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=AsigDocumentosCNS`, datos)
            .then(({ data }) => {
                context.commit(SET_DOCUMENTO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  async [ACTUALIZAR_ASIGNACION_DOCUMENTO_CNS](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=AsigDocumentosCNS/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_DOCUMENTO_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },

  //Eliminar un documento cargado
  async [ELIMINAR_ASIGNACION_DOCUMENTO_CNS](context, id){
    
    return new Promise(resolve => {
    ApiService.delete(`apiconsume/delete/${id}?endpoint=AsigDocumentosCNS/`)
        .then(({ data }) => {
            context.commit(SET_DOCUMENTO_CNS, data.data);
            resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
        });
    });
},  
  
}

const mutations = {
  [SET_DOCUMENTOS_CNS](state, data) {
    state.documentoCNS = data;
  },

  [SET_DOCUMENTO_CNS](state, data) {
    state.documentosCNS = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
