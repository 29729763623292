//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_INDICADORES_CNS = "obtenerIndicadoresCns";
export const REGISTRAR_INDICADOR_CNS = "registrarIndicadorCns";
export const ELIMINAR_INDICADOR_CNS = "eliminarIndicadorCns";
export const SET_INDICADORES_CNS = "setIndicadoresCns";
export const SET_INDICADOR_CNS = "setIndicadorCns";

const state = {
    indicadores: [],
    indicador: []
};

const getters = {
  obtnenerindicadoresCns(state) {
    return state.indicadores;
  }
};


const actions = {

    async [OBTENER_INDICADORES_CNS](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
     
            ApiService.query(`apiconsume/obtener?endpoint=Indicadores/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_INDICADORES_CNS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar un indicador
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_INDICADOR_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=Indicadores`, datos)
            .then(({ data }) => {
                context.commit(SET_INDICADOR_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


  /*
  * Eliminar un indicador
  */
  async [ELIMINAR_INDICADOR_CNS](context, id){
    
    return new Promise(resolve => {
    ApiService.delete(`apiconsume/delete/${id}?endpoint=Indicadores/`)
        .then(({ data }) => {
            context.commit(SET_INDICADOR_CNS, data.data);
            resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
        });
    });
},  

};

const mutations = {
  [SET_INDICADORES_CNS](state, data) {
    state.indicadores = data;
  },

  [SET_INDICADOR_CNS](state, data) {
    state.indicador = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

