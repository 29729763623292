//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_NEGOCIACIONES = "obtenerNegociacionesDirectas";
export const OBTENER_NEGOCIACION = "obtenerNegociacionDirecta";
export const REGISTRAR_NEGOCIACION_DIRECTA = "registrarNegociacionDirecta";
export const ACTUALIZAR_NEGOCIACION = "actualizarNegociacionDirecta";
export const ENVIAR_NEGOCIACION = "enviarNegociacionDirecta";
export const APROBAR_RECHAZAR_NEGOCIACION = "aprobarRechazarNegociacionDirecta";

// mutation types
export const SET_NEGOCIACIONES_DIRECTAS = "setNegociacionesDirectas";
export const SET_NEGOCIACION_DIRECTA = "setInstitucion";

const state = {
  negociacionesDirectas: [],
  negociacion: []
};

const getters = {
  obtnenerNegociacionesDirectas(state) {
    return state.negociacionesDirectas;
  }
};

/**
 * Tipo 1 es directa y 2 es comixta
 */
const actions = {
  async [OBTENER_NEGOCIACIONES](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=Negociacion/all/${parametros.estado}/${parametros.tipo}`, '')
        .then(({ data }) => {
            context.commit(SET_NEGOCIACIONES_DIRECTAS, data.data);
            
            resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error
          });
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },

  async [OBTENER_NEGOCIACION](context, id) {
    return new Promise(resolve => {
      
        ApiService.query(`apiconsume/edit/${id}?endpoint=Negociacion/`)
            .then(({ data }) => {
                context.commit(SET_NEGOCIACION_DIRECTA,data.data);
                //console.log(this.instituciones)
                resolve(data)
            })
            .catch((error) => {
                
              resolve({
                status: 500,
                message: "Error",
                data: error
              });
                //context.commit(SET_ERROR, response.data.errors);
            });
    });
  },

  /**
   * Registrar una negociacion directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_NEGOCIACION_DIRECTA](context, datos) {
    return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=Negociacion`, datos)
                .then(({ data }) => {
                    context.commit(SET_NEGOCIACION_DIRECTA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },

  async [ACTUALIZAR_NEGOCIACION](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=Negociacion/`,
        datos.datosItem
      )
        .then(({ data }) => {
          context.commit(SET_NEGOCIACION_DIRECTA, data.data);
          resolve(data);
        })
        .catch(error => {
            resolve({
              status: 500,
              message: error
          })
        });
    });
  },

  /**
   * Enviar una negociacion
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [ENVIAR_NEGOCIACION](context, datos) {
    return new Promise(resolve => {
            ApiService.update(`apiconsume/update2?endpoint=Negociacion/Estado/${datos.id}`, datos)
                .then(({ data }) => {
                    context.commit(SET_NEGOCIACION_DIRECTA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        }); 
  },

  /**
   * aprobar o rechazar una negociacion
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [APROBAR_RECHAZAR_NEGOCIACION](context, datos) {
    context.commit(SET_NEGOCIACION_DIRECTA, datos);

    if (datos)
      return {
        status: 200,
        message: "Se ha enviado la negociacion con éxito"
      };
    /* return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=api/Institucion`, datos)
                .then(({ data }) => {
                    context.commit(SET_NEGOCIACION_DIRECTA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    console.log(error)
                    //context.commit(SET_ERROR, response.data.errors);
                });
        });   */
  }
};

const mutations = {
  [SET_NEGOCIACIONES_DIRECTAS](state, data) {
    state.negociacionesDirectas = data;
  },

  [SET_NEGOCIACION_DIRECTA](state, data) {
    state.negociacionDirecta = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
