//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_EJES_KATUN = "obtenerEjesKatun";
export const ELIMINAR_EJE_KATUN = "eliminarEjeKatun";
export const OBTENER_EJE_KATUN = "obtenerEjeKatun";
export const REGISTRAR_EJE_KATUN = "registrarEjeKatun";
export const ACTUALIZAR_EJE_KATUN = "actualizarEjeKatun";

// mutation types
export const SET_EJES_KATUN = "setEjesKatun";
export const SET_EJE_KATUN = "setEjeKatun";

const state = {
  ejeKatun: {},
  ejesKatun: []
};

const getters = {
  obtnenerEjesKatun(state) {
    return state.EjesKatun;
  }
};

const actions = {
  async [OBTENER_EJES_KATUN](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=EjesKatun/all/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_EJES_KATUN, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_EJE_KATUN](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=EjesKatun/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_EJE_KATUN, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_EJE_KATUN](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=EjesKatun/`)
        .then(({ data }) => {
          context.commit(SET_EJE_KATUN, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una Pnds directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_EJE_KATUN](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=EjesKatun`, datos)
        .then(({ data }) => {
          context.commit(SET_EJE_KATUN, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_EJE_KATUN](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=EjesKatun/`, datos)
        .then(({ data }) => {
          context.commit(SET_EJE_KATUN, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_EJES_KATUN](state, data) {
    state.ejesKatun = data;
  },

  [SET_EJE_KATUN](state, data) {
    state.ejeKatun = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
