//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_PROYECTOS_GEORREFERENCIACION = "obtenerProyectosGeorreferenciacion";


// mutation types
export const SET_PROYECTOS_GEORREF = "setProyectosGeoRref";
export const SET_PROYECTO_GEORREF = "setProyectoGeoRref";

const state = {
  proyectosGeoRref: [],
  proyectoGeoRref: {}
};

const getters = {
  obtenerProyectosGeoRref(state) {
    return state.roles;
  }
};


const actions = {
    /**
   * Registrar un proyecto desde la unidad ejecutora
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [OBTENER_PROYECTOS_GEORREFERENCIACION](context, filtros) {

    
     return new Promise(resolve => {
          ApiService.post(`apiconsume/create?endpoint=ReporteGeorreferenciacion/all`, filtros)
              .then(({ data }) => {
                  context.commit(SET_PROYECTOS_GEORREF, data.data);
                  resolve(data)
              })
              .catch((error) => {
                resolve({
                  status: 500,
                  message: error
                })
              });
        });   
  },
};

const mutations = {
    [SET_PROYECTOS_GEORREF](state, data) {
      state.proyectosGeoRref = data;
    },
  
    [SET_PROYECTO_GEORREF](state, data) {
      state.proyectoGeoRref = data;
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };