//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_BENEFICIARIOS_ACL = "obtenerBeneficiariosAcl";
export const REGISTRAR_BENEFICIARIO_ACL = "registrarBeneficiarioAcl";
export const ACTUALIZAR_BENEFICIARIO_ACL = "actualizarBeneficiarioAcl";
export const ELIMINAR_BENEFICIARIO_ACL = "eliminarBeneficiarioDirectoAcl";

export const ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS =
  "actualizarCuentaBeneficiariosDirectosAcl";
export const ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNS =
  "actualizarCuentaInstitucionesBeneficiariasAcl";

// mutation types
export const SET_BENEFICIARIO_ACLS_ACL = "setBeneficiariosAcl";
export const SET_BENEFICIARIO_ACL = "setBeneficiarioAcl";

export const SET_TIPO_BENEFICIARIO_ACL = "setTipoBeneficiarioAcl";

const state = {
  beneficiariosAcl: [],
  beneficiarioAcl: [],
  tipoBeneficiarioAcl: {},
};

const getters = {
  obtnenerBeneficiariosAcl(state) {
    return state.beneficiariosAcl;
  },
};

/*const beneficiario = {
  id: 1,
  numeroBeneficiados: "beneficiario 1",
  nombreEtnia: 'Indigena 1",
  nombreEstado: "Registrada",
  estadosId: 1
};

*/

const actions = {
  async [OBTENER_BENEFICIARIOS_ACL](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=BeneficiariosProyectosActoresLocales/all/${parametros.estadoId}/${parametros.proyectoId}`
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_ACLS_ACL, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Registrar una condicion
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_BENEFICIARIO_ACL](context, parametros) {
    //console.log(datosEmpresa)
    let datos = parametros.datos;

    return new Promise((resolve) => {
      ApiService.post(`apiconsume/create?endpoint=BeneficiariosProyectosActoresLocales`, datos)
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  },

  async [ACTUALIZAR_BENEFICIARIO_ACL](context, parametros) {
    let datos = parametros.datos;
    let id = datos.id;
    return new Promise((resolve) => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=BeneficiariosProyectosActoresLocales/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  },

  async [ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS](context, parametros) {
    //console.log("cns")
    let id = parametros.datos.id;

    return new Promise((resolve) => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCNS/Beneficiarios/Directos/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_TIPO_BENEFICIARIO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  },

  async [ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNS](
    context,
    parametros
  ) {
    let id = parametros.datos.id;

    return new Promise((resolve) => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCNS/Cuenta/Institucion/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_TIPO_BENEFICIARIO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  },

  async [ELIMINAR_BENEFICIARIO_ACL](context, id) {
    return new Promise((resolve) => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=BeneficiariosProyectosActoresLocales/`)
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  }
};

const mutations = {
  [SET_BENEFICIARIO_ACLS_ACL](state, data) {
    state.beneficiarios = data;
  },

  [SET_BENEFICIARIO_ACL](state, data) {
    state.beneficiario = data;
  },

  [SET_TIPO_BENEFICIARIO_ACL](state, data) {
    state.tipoBeneficiario = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
