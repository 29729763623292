//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_PROYECTOS_ACL = "obtenerProyectosACL";
export const OBTENER_PROYECTO_ACL = "obtenerProyectoACL";
export const OBTENER_PROYECTOS_ACL_SICOOPERA_INSTITUCION_ENTIDAD =
  "obtenerProyectosACLInstitucionEntidad";

export const OBTENER_PROYECTOS_ACL_USUARIO_ESPECIALISTA =
  "obtenerProyectosACLEspecialista";

export const OBTENER_AVANCE_PROYECTO_ACL = "obtenerAvanceProyectoACL";
export const REGISTRAR_PROYECTO_ACL = "registrarProyectoACL";

export const ACTUALIZAR_DATOS_GENERALES_PROYECTO_ACL =
  "actualizarProyectoACL";

export const ACTUALIZAR_DATOS_INSTITUCION_PROYECTO_ACL =
  "actualizarDatosInstitucionProyectoACL";

export const ACTUALIZAR_DATOS_FINANCIAMIENTO_ACL =
  "actualizarFinanciamientoACL";

export const REGISTRAR_PAIS_SECUNDARIO_ACL = "registrarPaisSecundarioAcl";
export const ELIMINAR_PAIS_SECUNDARIO_ACL = "eliminarPaisSecundarioAcl";

export const ACTUALIZAR_VINCULACION_INSTRUMENTOS_TERRITORIALES_ACL =
  "actualizarVinculacionInstrumentosTerritorialesACL";

export const ACTUALIZAR_VINCULACION_INSTRUMENTOS_NACIONALES_ACL =
  "actualizarVinculacionInsturmentosNacionalesACL";

export const ACTUALIZAR_VINCULACION_INSTRUMENTOS_INTERNACIONALES_ACL =
  "actualizarVinculacionInsturmentosInternacionalesACL";

export const ACTUALIZAR_VINCULACION_POLITICA_PUBLICA_ACL =
  "actualizarPoliticaPublicaACL";

export const CONFIRMAR_PROYECTO_ACL = "confirmarProyectoACL";


//Seguimiento
export const OBTENER_SEGUIMIENTOS_PROYECTOS_ACL =
  "obtenerSeguimientosProyectoACL";
export const OBTENER_SEGUIMIENTO_PROYECTO_ACL =
  "obtenerSeguimientoProyectoACL";
export const REGISTRAR_SEGUIMIENTO_PROYECTO_ACL =
  "registrarSeguimientoProyectoACL";
export const ACTUALIZAR_ESTADO_SEGUIMIENTO_PROYECTO_ACL =
  "actualizarrSeguimientoProyectoACL";
export const REGISTRAR_EJECUCION_FISICA_SEGUIMIENTO_PROYECTO_ACL =
  "registrarEjeeucionFisicaSeguimientoProyectoACL";
export const REGISTRAR_EJECUCION_FINANCIERA_SEGUIMIENTO_PROYECTO_ACL =
  "registrarEjeeucionFinancieraSeguimientoProyectoACL";
  
export const OBTENER_DOCUMENTOS_SEGUIMIENTO_PROYECTOS_ACL =
  "obtenerDocumentosSeguimientoProyectoACL";
export const REGISTRAR_DOCUMENTO_SEGUIMIENTO_PROYECTO_ACL =
  "registrarDocumentoSeguimientoProyectoACL";

export const SET_PROYECTOS_ACL = "setProyectosACL";
//export const SET_CANTIDAD_PROYECTOS_CNS = "setcantidadACL";
export const SET_PROYECTO_ACL = "setProyectoACL";
//export const SET_DATOS_SECCION_CNS = "setDatosSeccion";

const state = {
  proyectos: [],
  proyecto: {},
  datosSeccion: [],
  mecanismosEjecucion: [],
  cantidadCns: 0
};

const getters = {
  obtnenerproyectosACL(state) {
    return state.proyectos;
  }
};

const actions = {
  async [OBTENER_PROYECTOS_ACL](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosActoresLocales/all/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_PROYECTO_ACL](context, parametros) {
    let id = parametros.id;
    // console.log(datos)

    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/edit/${id}?endpoint=ProyectosActoresLocales/`
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [OBTENER_PROYECTOS_ACL_SICOOPERA_INSTITUCION_ENTIDAD](
    context,
    parametros
  ) {
    // console.log(datos)

    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosActoresLocales/all/${parametros.estadoId}`
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_ACL, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [OBTENER_PROYECTOS_ACL_USUARIO_ESPECIALISTA](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosActoresLocales/Especialista/Usuario/${parametros.estadoProyectoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_ACL, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_AVANCE_PROYECTO_ACL](context, parametros) {
    let id = parametros.id;
    // console.log(datos)

    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/edit/${id}?endpoint=ProyectosActoresLocales/Avance/`
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Registrar un proyecto desde la unidad ejecutora
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PROYECTO_ACL](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProyectosActoresLocales`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar datos Generales Cooperacion Descentralizada
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_DATOS_GENERALES_PROYECTO_ACL](context, parametros) {
    let id = parametros.datos.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosActoresLocales/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar datos Generales Cooperacion Descentralizada
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_DATOS_INSTITUCION_PROYECTO_ACL](context, parametros) {
    let id = parametros.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosActoresLocales/DatosInstitucion/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Regsitrar pais secundario
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_PAIS_SECUNDARIO_ACL](context, parametros) {
    let datos = parametros.datos;
    let id = datos.proyectoId;
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProyectosActoresLocales/Financiamiento/PaisSecundario/${id}`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /*
   * Eliminar ejecucion por actividad para departamento y municipio proyectos cns
   */
  async [ELIMINAR_PAIS_SECUNDARIO_ACL](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=ProyectosActoresLocales/Financiamiento/PaisSecundario/Eliminar/`
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Actualizar datos Generales Cooperacion Descentralizada
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_DATOS_FINANCIAMIENTO_ACL](context, parametros) {
    let id = parametros.datos.proyectosCooperacionDescId;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosActoresLocales/Financiamiento/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar la vinculación a instrumentos territoriales
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_VINCULACION_INSTRUMENTOS_TERRITORIALES_ACL](
    context,
    parametros
  ) {
    let id = parametros.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosActoresLocales/Vinculacion/Instrumentos/Territoriales/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar la vinculación a instrumentos nacionales
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_VINCULACION_INSTRUMENTOS_NACIONALES_ACL](
    context,
    parametros
  ) {
    let id = parametros.datos.proyectosCooperacionDescId;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosActoresLocales/VinculacionEstrategica/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar la vinculación a instrumentos internacionales
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_VINCULACION_INSTRUMENTOS_INTERNACIONALES_ACL](
    context,
    parametros
  ) {
    let id = parametros.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosActoresLocales/Vinculacion/Instrumentos/Internacional/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar la vinculación a la política pública
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_VINCULACION_POLITICA_PUBLICA_ACL](context, parametros) {
    let id = parametros.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosActoresLocales/Vinculacion/PoliticaPublica/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Confirmar el proyecto
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [CONFIRMAR_PROYECTO_ACL](context, parametros) {
    let id = parametros.id;
    let datos = parametros;
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosActoresLocales/Confirmar/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Obtener los seguimientos por proyecto
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */
  async [OBTENER_SEGUIMIENTOS_PROYECTOS_ACL](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosActoresLocales/Seguimiento/all/${parametros.proyectoId}/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar un seguimiento para cooperacion desc
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */
  async [REGISTRAR_SEGUIMIENTO_PROYECTO_ACL](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProyectosActoresLocales/Seguimiento/Registro`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [ACTUALIZAR_ESTADO_SEGUIMIENTO_PROYECTO_ACL](context, parametros) {
    let id = parametros.id;
    let datos = parametros;
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=ProyectosActoresLocales/Seguimiento/Estado/${id}/${parametros.estadoId}`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [REGISTRAR_EJECUCION_FISICA_SEGUIMIENTO_PROYECTO_ACL](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/update2?endpoint=ProyectosActoresLocales/Seguimiento/EjeuccionFisica/${datos.id}`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [REGISTRAR_EJECUCION_FINANCIERA_SEGUIMIENTO_PROYECTO_ACL](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/update2?endpoint=ProyectosActoresLocales/Seguimiento/EjeuccionFinanciera/${datos.id}`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  

  /**
   * Obtener los documentos de seguimiento
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */
  async [OBTENER_DOCUMENTOS_SEGUIMIENTO_PROYECTOS_ACL](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosActoresLocales/Seguimiento/Documentos/all/${parametros.seguimientoId}/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [REGISTRAR_DOCUMENTO_SEGUIMIENTO_PROYECTO_ACL](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProyectosActoresLocales/Seguimiento/Documentos/Registro`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },


};

const mutations = {
  [SET_PROYECTOS_ACL](state, data) {
    state.proyectos = data;
  },

  [SET_PROYECTO_ACL](state, data) {
    state.proyecto = data;
  }

  /*[SET_DATOS_SECCION_ACL](state, data) {
    state.datosSeccion = data;
  },

  [SET_CANTIDAD_PROYECTOS_ACL](state, data) {
    state.cantidadCns = data;
  }*/
};

export default {
  state,
  getters,
  actions,
  mutations
};
