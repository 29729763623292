import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
//import { resolve } from "core-js/fn/promise";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  roles: [],
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },

  currentRoles(state) {
    return state.roles;
  },

  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    context.commit(PURGE_AUTH);
    return new Promise(resolve => {
      //console.log(credentials)

      /*if(credentials.email === 'admin@demo.com' && credentials.password==='Segeplan@2022')
      {
        context.commit(SET_AUTH, {'correo': 'admin@demo.com', 'nombreUsuario': 'Jairon', 'apellidoUsuario': 'García',  'token': "afgj8fjdfk43", 'roles':[101, 600]});
        resolve({
          status: 200,
          data: state.user,
          message: 'Inicio de sesion'
        });
      } 
      else if(credentials.email === 'unidadejecutora@demo.com' && credentials.password==='admin')
      {
        context.commit(SET_AUTH, {'correo': 'unidadejecutora@demo.com', 'nombreUsuario': 'Dennis', 'apellidoUsuario': 'Valverth',  'token': "bfgj8fjdfk43", 'roles':[201]});
        resolve({
          status: 200,
          data: state.user,
          message: 'Inicio de sesion'
        });
      }
      else if(credentials.email === 'cooperacionbi@demo.com' && credentials.password==='admin')
      {
        context.commit(SET_AUTH, {'correo': 'cooperacionbi@demo.com', 'nombreUsuario': 'Regina', 'apellidoUsuario': 'Escobar', 'token': "cfgj8fjdfk43", 'cooperantesId': 1, "fuentesBilateralesId": 1, 'roles':[301]});
        resolve({
          status: 200,
          data: state.user,
          message: 'Inicio de sesion'
        });
      }
      else if(credentials.email === 'cooperacionmulti22@demo.com' && credentials.password==='admin')
      {
        context.commit(SET_AUTH, {'correo': 'cooperacionmulti@demo.com', 'nombreUsuario': 'Joel', 'apellidoUsuario': 'Sandoval', 'cooperantesId': 1, "fuentesMultilateralesId": 1,   'token': "dfgj8fjdfk43", 'roles':[301]});
        resolve({
          status: 200,
          data: state.user,
          message: 'Inicio de sesion'
        });
      }
      else if(credentials.email === 'sursur@demo.com' && credentials.password==='admin')
      {
        context.commit(SET_AUTH, {'correo': 'sursur@demo.com','nombreUsuario': 'María Luz', 'apellidoUsuario': 'Hernández',  'token': "efgj8fjdfk43", 'roles':[401]});
        resolve({
          status: 200,
          data: state.user,
          message: 'Inicio de sesion'
        });
      }
      else if(credentials.email === 'alianzas@demo.com' && credentials.password==='admin')
      {
        context.commit(SET_AUTH, {'correo': 'alianzas@demo.com','nombreUsuario': 'Sandy', 'apellidoUsuario': 'Cardona',   'token': "ffgj8fjdfk43", 'roles':[501]});
        resolve({
          status: 200,
          data: state.user,
          message: 'Inicio de sesion'
        });
      }
      else if(credentials.email === 'utcguatemala@demo.com' && credentials.password==='admin')
      {
        context.commit(SET_AUTH, {'correo': 'alianzas@demo.com','nombreUsuario': 'Claudia', 'apellidoUsuario': 'Cardona',   'token': "ffgj8fjdfk43", 'roles':[601]});
        resolve({
          status: 200,
          data: state.user,
          message: 'Inicio de sesion'
        });
      }

      else{
        context.commit(SET_ERROR, {error: 'email y/o contraseñas incorrecto(s)'});
        resolve({
          status: 401,
          data: null,
          message: 'Usuario y/o contraseñas incorrectos'
        });
      }*/

      ApiService.post(
        "apiconsume/create?endpoint=Users/authenticate",
        credentials
      )
        .then(({ data }) => {
          //console.log(data)
          if (data.status === 200) {
            context.commit(SET_AUTH, data.data);
          } else {
            context.commit(PURGE_AUTH);
            resolve({ status: 401, message: data.message });
          }
          resolve(data);
        })
        .catch(({ error }) => {
          //console.log(error);
          context.commit(SET_ERROR, error);
          resolve(error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      let data = {
        token: JwtService.getToken()
      };

      return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=Users/verify`, data)
          .then(({ data }) => {
            if (data.status == 200) {
              //console.log(data.data.roles)

              //Verificar que el token no haya expirado
              if (typeof data.data === "string") {
                if (
                  data.data.includes("IDX10223") ||
                  data.data.includes("IDX12741")
                ) {
                  context.commit(PURGE_AUTH);
                  resolve({
                    status: 401,
                    message: "Usuario sin permisos de acceso"
                  });
                } else {
                  context.commit(SET_AUTH, data.data);
                  resolve(data.data);
                  //context.commit(SET_CURRENT_ROLES, data.data.roles);
                }
              } else {
                context.commit(SET_AUTH, data.data);
                resolve(data.data);
                //context.commit(SET_CURRENT_ROLES, data.data.roles);
              }
              /* let stRoles = window.localStorage.getItem('roles');
              if(stRoles.includes(5)){
                context.commit(SET_CREADOR_DEPARTAMENTAL_VERIFY);
              } */
            } else {
              //console.log(data)
              if (typeof data.message === "string") {
                if (
                  data.message.includes("IDX10223") ||
                  data.message.includes("IDX12741")
                ) {
                  context.commit(PURGE_AUTH);
                  resolve({
                    status: 401,
                    message: "Usuario sin permisos de acceso"
                  });
                }
              }
              //window.location.replace('404')
              resolve(data.data);
            }
          })
          .catch(error => {
            //console.log(error)
            context.commit(SET_ERROR, error);
          });
      });
    } else {
      context.commit(PURGE_AUTH);
      return {
        status: 404,
        message: "No se ha podido verificar el token!",
        roles: null
      };
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    let arrRoles = user.roles.map(a => a.nombreRol);
    state.isAuthenticated = true;
    state.user = user;
    state.roles = arrRoles;
    state.errors = {};
    JwtService.saveToken(state.user.token);
    JwtService.saveRoles(state.user.roles); // Quitar
    JwtService.saveUserData(state.user); ///Quitar
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.roles = [];
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyRoles(state.user.roles); // Quitar
    JwtService.removeUserData(state.user); ///Quitar
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
