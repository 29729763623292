//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_ASIGNACIONES_ESPECIALISTA_ACL =
  "obtenerasignacionesSeguimientoAcl";
export const OBTENER_ASIGNACIONES_ADMINISTRADOR_ACL =
  "obtenerAsignacionesSeguimientoAdministradorAcl";
export const OBTENER_ASIGNACION_ESPECIALISTA_ACL = "obtenerasignacionSeguimientoAcl";
export const REGISTRAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_ACL =
  "registrarAsignacionSeguimientoAcl";
export const ACTUALIZAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_ACL =
  "actualizarAsignacionSeguimientoAcl";
export const ELIMINAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_ACL =
  "eliminarAsignacionSeguimientoAcl";

export const SET_ASIGNACIONES_SEGUIMIENTOS_ACL = "setAsignacioneseguimientoAcl";
export const SET_ASIGNACION_SEGUIMIENTO_ACL = "setAsignacionSeguimientoAcl";

const state = {
  asignacionSeguimientoAcl: {},
  asignacionesSeguimientoAcl: []
};

const getters = {
  obtnenerasignacionSeguimientoAcl(state) {
    return state.asignacionesSeguimientoAcl;
  }
};

const actions = {
  /*
   * Obtener las asignados especificas de un usuario
   */
  async [OBTENER_ASIGNACIONES_ESPECIALISTA_ACL](context, parametros) {
    return new Promise(resolve => {
      let id = parametros.proyectoId;
      ApiService.query(
        `apiconsume/obtener?endpoint=AsignacionSeguimientoAcl/Especialista/0/${id}`
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACIONES_SEGUIMIENTOS_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /*
   * Obtener todas las asignados para el administrador 
   */
  async [OBTENER_ASIGNACIONES_ADMINISTRADOR_ACL](context, parametros) {
    return new Promise(resolve => {
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=AsignacionSeguimientoAcl/all/${estadoId}`
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACIONES_SEGUIMIENTOS_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /*
   *Obtener la asignacion especifica de un especialista
   */
  async [OBTENER_ASIGNACION_ESPECIALISTA_ACL](context, usuario) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=AsignacionSeguimientoAcl/Usuario/${usuario}`
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACION_SEGUIMIENTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
        });
    });
  },

  /**
   * Registrar una Asignacion de seguimiento
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_ACL](context, parametros) {
    console.log(parametros)
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=AsignacionSeguimientoAcl`, datos)
        .then(({ data }) => {
          context.commit(SET_ASIGNACION_SEGUIMIENTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [ACTUALIZAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_ACL](context, parametros) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=AsignacionSeguimientoAcl/${parametros.id}`,
        parametros
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACION_SEGUIMIENTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /*
   * Eliminar un AsignacionSeguimientoAcl
   */
  async [ELIMINAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_ACL](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=AsignacionSeguimientoAcl/`
      )
        .then(({ data }) => {
          context.commit(SET_ASIGNACION_SEGUIMIENTO_ACL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  }
};

const mutations = {
  [SET_ASIGNACIONES_SEGUIMIENTOS_ACL](state, data) {
    state.asignacionesSeguimientoAcl = data;
  },

  [SET_ASIGNACION_SEGUIMIENTO_ACL](state, data) {
    state.asignacionSeguimientoAcl = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
