//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_PRIORIDADES_KATUN = "obtenerPrioridadesKatun";
export const ELIMINAR_PRIORIDAD_KATUN = "eliminarPrioridadKatun";
export const OBTENER_PRIORIDAD_KATUN = "obtenerPrioridadKatun";
export const REGISTRAR_PRIORIDAD_KATUN = "registrarPrioridadKatun";
export const ACTUALIZAR_PRIORIDAD_KATUN = "actualizarPrioridadKatun";

// mutation types
export const SET_PRIORIDADES_KATUN = "setPrioridadesKatun";
export const SET_PRIORIDAD_KATUN = "setPrioridadKatun";

const state = {
  prioridadKatun: {},
  prioridadesKatun: []
};

const getters = {
  obtnenerPrioridadesKatun(state) {
    return state.prioridadesKatun;
  }
};

const actions = {
  async [OBTENER_PRIORIDADES_KATUN](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=PrioridadesKatun/all/${parametros.estadoId}/${parametros.ejesKatunId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PRIORIDADES_KATUN, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_PRIORIDAD_KATUN](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=PrioridadesKatun/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_PRIORIDAD_KATUN, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_PRIORIDAD_KATUN](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=PrioridadesKatun/`)
        .then(({ data }) => {
          context.commit(SET_PRIORIDAD_KATUN, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una Pnds directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PRIORIDAD_KATUN](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=PrioridadesKatun`, datos)
        .then(({ data }) => {
          context.commit(SET_PRIORIDAD_KATUN, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_PRIORIDAD_KATUN](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=PrioridadesKatun/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PRIORIDAD_KATUN, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_PRIORIDADES_KATUN](state, data) {
    state.prioridadesKatun = data;
  },

  [SET_PRIORIDAD_KATUN](state, data) {
    state.prioridadKatun = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
