//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_INSTRUMENTOS_ALIANZA = "obtenerInstrumentosAlianza";
export const OBTENER_INSTRUMENTO_ALIANZA = "obtenerInstrumentoAlianza";
export const REGISTRAR_INSTRUMENTO_ALIANZA = "registrarInstrumentoAlianza";
export const ACTUALIZAR_INSTRUMENTO_ALIANZA = "actualizarInstrumentoAlianza";
export const ENVIAR_ALIANZA = "enviarAlianza";

// mutation types
export const SET_INSTRUMENTOS_ALIANZA = "setInstrumentosAlianza";
export const SET_INSTRUMENTO_ALIANZA = "setInstrumentoAlianza";

const state = {
  instrumentosAlianza: [],
  instrumentoAlianza: []
};

const getters = {
  obtnenerInstrumentosAlianza(state) {
    return state.instrumentosAlianza;
  }
};

/**
 * Tipo 1 es directa y 2 es comixta
 */
const actions = {
  async [OBTENER_INSTRUMENTOS_ALIANZA](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=InstrumentoAlianza/all/${parametros.estadoId}`, '')
        .then(({ data }) => {
            context.commit(SET_INSTRUMENTOS_ALIANZA, data.data);
            
            resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error
          });
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },

  async [OBTENER_INSTRUMENTO_ALIANZA](context, id) {
    return new Promise(resolve => {
      
        ApiService.query(`apiconsume/edit/${id}?endpoint=InstrumentoAlianza/`)
            .then(({ data }) => {
                context.commit(SET_INSTRUMENTO_ALIANZA,data.data);
                //console.log(this.instituciones)
                resolve(data)
            })
            .catch((error) => {
                
              resolve({
                status: 500,
                message: "Error",
                data: error
              });
                //context.commit(SET_ERROR, response.data.errors);
            });
    });
  },

  /**
   * Registrar una instrumentoAlianza directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_INSTRUMENTO_ALIANZA](context, datos) {
    return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=InstrumentoAlianza`, datos)
                .then(({ data }) => {
                    context.commit(SET_INSTRUMENTO_ALIANZA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },

  async [ACTUALIZAR_INSTRUMENTO_ALIANZA](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=InstrumentoAlianza/`, datos)
        .then(({ data }) => {
          context.commit(SET_INSTRUMENTO_ALIANZA, data.data);
          resolve(data);
        })
        .catch(error => {
            resolve({
              status: 500,
              message: error
          })
        });
    });
  },


  async [ENVIAR_ALIANZA](context, datos) {
    return new Promise(resolve => {
        ApiService.update(`apiconsume/update2?endpoint=InstrumentoAlianza/estado/${datos.instrumentoAlianzaId}`, datos)
            .then(({ data }) => {
                context.commit(SET_INSTRUMENTO_ALIANZA, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                  status: 500,
                  message: error
              })
            });
    }); 
  },



  


};

const mutations = {
  [SET_INSTRUMENTOS_ALIANZA](state, data) {
    state.instrumentosAlianza = data;
  },

  [SET_INSTRUMENTO_ALIANZA](state, data) {
    state.instrumentoAlianza = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
