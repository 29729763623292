//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_BENEFICIARIOS_CNSC = "obtenerBeneficiariosCnsc";
export const REGISTRAR_BENEFICIARIO_CNSC = "registrarBeneficiarioCnsc";
export const ACTUALIZAR_BENEFICIARIO_CNSC = "actualizarBeneficiarioCnsc";

export const ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNSC = "actualizarCuentaBeneficiariosDirectosCnsc";
export const ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNSC = "actualizarCuentaInstitucionesBeneficiariasCnsc";

// mutation types
export const SET_BENEFICIARIO_CNSCS_CNSC = "setBeneficiariosCNSC";
export const SET_BENEFICIARIO_CNSC = "setBeneficiarioCNSC";

export const SET_TIPO_BENEFICIARIO = "setTipoBeneficiarioCNSC";

const state = {
  beneficiariosCnsc: [],
  beneficiarioCnsc: [],
  tipoBeneficiarioCnsc: {}
};

const getters = {
  obtnenerBeneficiariosCnsc(state) {
    return state.beneficiariosCnsc;
  }
};

/*const beneficiario = {
  id: 1,
  numeroBeneficiados: "beneficiario 1",
  nombreEtnia: 'Indigena 1",
  nombreEstado: "Registrada",
  estadosId: 1
};

*/


const actions = {
  async [OBTENER_BENEFICIARIOS_CNSC](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=BeneficiariosCNSCooperantes/all/${parametros.estadoId}/${parametros.proyectoId}`)
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_CNSCS_CNSC, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },


  /**
   * Registrar una condicion
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_BENEFICIARIO_CNSC](context, parametros) {
    //console.log(datosEmpresa)
    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=BeneficiariosCNSCooperantes`, datos)
            .then(({ data }) => {
                context.commit(SET_BENEFICIARIO_CNSC, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  async [ACTUALIZAR_BENEFICIARIO_CNSC](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=BeneficiariosCNSCooperantes/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_CNSC, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },


  async [ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNSC](context, parametros) {
    //console.log(parametros)
    let id = parametros.datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectoCNSC/Cuenta/Beneficiarios/Directos/`, parametros.datos)
        .then(({ data }) => {
          context.commit(SET_TIPO_BENEFICIARIO, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },


  async [ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNSC](context, parametros) {
    
    let id = parametros.datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectoCNSC/Cuenta/Instituciones/Beneficiarias/`, parametros.datos)
        .then(({ data }) => {
          context.commit(SET_TIPO_BENEFICIARIO, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },



  
}

const mutations = {
  [SET_BENEFICIARIO_CNSCS_CNSC](state, data) {
    state.beneficiariosCns = data;
  },

  [SET_BENEFICIARIO_CNSC](state, data) {
    state.beneficiarioCns = data;
  },

  [SET_TIPO_BENEFICIARIO](state, data){
    state.tipoBeneficiarioCnsc = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
