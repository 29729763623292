//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_PROYECTOS_CDES = "obtenerProyectosCdes";
export const OBTENER_PROYECTO_CDES = "obtenerProyectoCdes";
export const OBTENER_PROYECTOS_CDES_SICOOPERA_INSTITUCION_ENTIDAD =
  "obtenerProyectosCdesInstitucionEntidad";

export const OBTENER_PROYECTOS_CDES_USUARIO_ESPECIALISTA =
  "obtenerProyectosCdesEspecialista";

export const OBTENER_AVANCE_PROYECTO_CDES = "obtenerAvanceProyectoCdes";
export const REGISTRAR_PROYECTO_CDES = "registrarProyectoCdes";

export const ACTUALIZAR_DATOS_GENERALES_PROYECTO_CDES =
  "actualizarProyectoCdes";

export const ACTUALIZAR_DATOS_INSTITUCION_PROYECTO_CDES =
  "actualizarDatosInstitucionProyectoCdes";

export const ACTUALIZAR_DATOS_FINANCIAMIENTO_CDES =
  "actualizarFinanciamientoCdes";
export const ACTUALIZAR_VINCULACION_INSTRUMENTOS_TERRITORIALES_CDES =
  "actualizarVinculacionInstrumentosTerritorialesCdes";

export const ACTUALIZAR_VINCULACION_INSTRUMENTOS_NACIONALES_CDES =
  "actualizarVinculacionInsturmentosNacionalesCdes";

export const ACTUALIZAR_VINCULACION_INSTRUMENTOS_INTERNACIONALES_CDES =
  "actualizarVinculacionInsturmentosInternacionalesCdes";

export const ACTUALIZAR_VINCULACION_POLITICA_PUBLICA_CDES =
  "actualizarPoliticaPublicaCdes";

export const CONFIRMAR_PROYECTO_CDES = "confirmarProyectoCdes";


//Seguimiento
export const OBTENER_SEGUIMIENTOS_PROYECTOS_CDES =
  "obtenerSeguimientosProyectoCdes";
export const OBTENER_SEGUIMIENTO_PROYECTO_CDES =
  "obtenerSeguimientoProyectoCdes";
export const REGISTRAR_SEGUIMIENTO_PROYECTO_CDES =
  "registrarSeguimientoProyectoCdes";
export const ACTUALIZAR_ESTADO_SEGUIMIENTO_PROYECTO_CDES =
  "actualizarrSeguimientoProyectoCdes";
export const REGISTRAR_EJECUCION_FISICA_SEGUIMIENTO_PROYECTO_CDES =
  "registrarEjeeucionFisicaSeguimientoProyectoCdes";
export const REGISTRAR_EJECUCION_FINANCIERA_SEGUIMIENTO_PROYECTO_CDES =
  "registrarEjeeucionFinancieraSeguimientoProyectoCdes";
  
export const OBTENER_DOCUMENTOS_SEGUIMIENTO_PROYECTOS_CDES =
  "obtenerDocumentosSeguimientoProyectoCdes";
export const REGISTRAR_DOCUMENTO_SEGUIMIENTO_PROYECTO_CDES =
  "registrarDocumentoSeguimientoProyectoCdes";

export const SET_PROYECTOS_CDES = "setProyectosCdes";
//export const SET_CANTIDAD_PROYECTOS_CNS = "setcantidadCdes";
export const SET_PROYECTO_CDES = "setProyectoCdes";
//export const SET_DATOS_SECCION_CNS = "setDatosSeccion";

const state = {
  proyectos: [],
  proyecto: {},
  datosSeccion: [],
  mecanismosEjecucion: [],
  cantidadCns: 0
};

const getters = {
  obtnenerproyectosCdes(state) {
    return state.proyectos;
  }
};

const actions = {
  async [OBTENER_PROYECTOS_CDES](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCooperacionDescentralizada/all/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_PROYECTO_CDES](context, parametros) {
    let id = parametros.id;
    // console.log(datos)

    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/edit/${id}?endpoint=ProyectosCooperacionDescentralizada/`
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [OBTENER_PROYECTOS_CDES_SICOOPERA_INSTITUCION_ENTIDAD](
    context,
    parametros
  ) {
    // console.log(datos)

    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCooperacionDescentralizada/all/${parametros.entidadId}/${parametros.unidadEjecutoraId}/${parametros.estadoId}`
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CDES, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [OBTENER_PROYECTOS_CDES_USUARIO_ESPECIALISTA](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCooperacionDescentralizada/Especialista/Usuario/${parametros.estadoProyectoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CDES, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_AVANCE_PROYECTO_CDES](context, parametros) {
    let id = parametros.id;
    // console.log(datos)

    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/edit/${id}?endpoint=ProyectosCooperacionDescentralizada/Avance/`
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Registrar un proyecto desde la unidad ejecutora
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PROYECTO_CDES](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProyectosCooperacionDescentralizada`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar datos Generales Cooperacion Descentralizada
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_DATOS_GENERALES_PROYECTO_CDES](context, parametros) {
    let id = parametros.datos.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCooperacionDescentralizada/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar datos Generales Cooperacion Descentralizada
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_DATOS_INSTITUCION_PROYECTO_CDES](context, parametros) {
    let id = parametros.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCooperacionDescentralizada/DatosInstitucion/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },


  

  /**
   * Actualizar datos Generales Cooperacion Descentralizada
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_DATOS_FINANCIAMIENTO_CDES](context, parametros) {
    let id = parametros.datos.proyectosCooperacionDescId;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCooperacionDescentralizada/Financiamiento/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar la vinculación a instrumentos territoriales
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_VINCULACION_INSTRUMENTOS_TERRITORIALES_CDES](
    context,
    parametros
  ) {
    let id = parametros.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCooperacionDescentralizada/Vinculacion/Instrumentos/Territoriales/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar la vinculación a instrumentos nacionales
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_VINCULACION_INSTRUMENTOS_NACIONALES_CDES](
    context,
    parametros
  ) {
    let id = parametros.datos.proyectosCooperacionDescId;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCooperacionDescentralizada/Vinculacion/Instrumentos/Nacional/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar la vinculación a instrumentos internacionales
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_VINCULACION_INSTRUMENTOS_INTERNACIONALES_CDES](
    context,
    parametros
  ) {
    let id = parametros.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCooperacionDescentralizada/Vinculacion/Instrumentos/Internacional/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Actualizar la vinculación a la política pública
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTUALIZAR_VINCULACION_POLITICA_PUBLICA_CDES](context, parametros) {
    let id = parametros.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCooperacionDescentralizada/Vinculacion/PoliticaPublica/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Confirmar el proyecto
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [CONFIRMAR_PROYECTO_CDES](context, parametros) {
    let id = parametros.id;
    let datos = parametros;
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCooperacionDescentralizada/Confirmar/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  /**
   * Obtener los seguimientos por proyecto
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */
  async [OBTENER_SEGUIMIENTOS_PROYECTOS_CDES](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCooperacionDescentralizada/Seguimiento/all/${parametros.proyectoId}/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar un seguimiento para cooperacion desc
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */
  async [REGISTRAR_SEGUIMIENTO_PROYECTO_CDES](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProyectosCooperacionDescentralizada/Seguimiento/Registro`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [ACTUALIZAR_ESTADO_SEGUIMIENTO_PROYECTO_CDES](context, parametros) {
    let id = parametros.id;
    let datos = parametros;
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=ProyectosCooperacionDescentralizada/Seguimiento/Estado/${id}/${parametros.estadoId}`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [REGISTRAR_EJECUCION_FISICA_SEGUIMIENTO_PROYECTO_CDES](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/update2?endpoint=ProyectosCooperacionDescentralizada/Seguimiento/EjeuccionFisica/${datos.id}`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  async [REGISTRAR_EJECUCION_FINANCIERA_SEGUIMIENTO_PROYECTO_CDES](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/update2?endpoint=ProyectosCooperacionDescentralizada/Seguimiento/EjeuccionFinanciera/${datos.id}`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },

  

  /**
   * Obtener los documentos de seguimiento
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */
  async [OBTENER_DOCUMENTOS_SEGUIMIENTO_PROYECTOS_CDES](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ProyectosCooperacionDescentralizada/Seguimiento/Documentos/all/${parametros.seguimientoId}/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTOS_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [REGISTRAR_DOCUMENTO_SEGUIMIENTO_PROYECTO_CDES](context, parametros) {
    let datos = parametros.datos;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProyectosCooperacionDescentralizada/Seguimiento/Documentos/Registro`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
        });
    });
  },


};

const mutations = {
  [SET_PROYECTOS_CDES](state, data) {
    state.proyectos = data;
  },

  [SET_PROYECTO_CDES](state, data) {
    state.proyecto = data;
  }

  /*[SET_DATOS_SECCION_CDES](state, data) {
    state.datosSeccion = data;
  },

  [SET_CANTIDAD_PROYECTOS_CDES](state, data) {
    state.cantidadCns = data;
  }*/
};

export default {
  state,
  getters,
  actions,
  mutations
};
