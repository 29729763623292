//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_BENEFICIARIOS_CDES = "obtenerBeneficiariosCdes";
export const REGISTRAR_BENEFICIARIO_CDES = "registrarBeneficiarioCdes";
export const ACTUALIZAR_BENEFICIARIO_CDES = "actualizarBeneficiarioCdes";
export const ELIMINAR_BENEFICIARIO_CDES = "eliminarBeneficiarioDirectoCdes";

export const ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS =
  "actualizarCuentaBeneficiariosDirectosCdes";
export const ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNS =
  "actualizarCuentaInstitucionesBeneficiariasCdes";

// mutation types
export const SET_BENEFICIARIO_CDESS_CDES = "setBeneficiariosCDes";
export const SET_BENEFICIARIO_CDES = "setBeneficiarioCdes";

export const SET_TIPO_BENEFICIARIO_CDES = "setTipoBeneficiarioCdes";

const state = {
  beneficiariosCdes: [],
  beneficiarioCdes: [],
  tipoBeneficiarioCdes: {},
};

const getters = {
  obtnenerBeneficiariosCdes(state) {
    return state.beneficiariosCdes;
  },
};

/*const beneficiario = {
  id: 1,
  numeroBeneficiados: "beneficiario 1",
  nombreEtnia: 'Indigena 1",
  nombreEstado: "Registrada",
  estadosId: 1
};

*/

const actions = {
  async [OBTENER_BENEFICIARIOS_CDES](context, parametros) {
    return new Promise((resolve) => {
      ApiService.query(
        `apiconsume/obtener?endpoint=BeneficiariosProyectosCooperacionDesc/all/${parametros.estadoId}/${parametros.proyectoId}`
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_CDESS_CDES, data.data);

          resolve(data);
        })
        .catch((error) => {
          resolve({
            status: 400,
            message: error.message,
          });
        });
    });
  },

  /**
   * Registrar una condicion
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_BENEFICIARIO_CDES](context, parametros) {
    //console.log(datosEmpresa)
    let datos = parametros.datos;

    return new Promise((resolve) => {
      ApiService.post(`apiconsume/create?endpoint=BeneficiariosProyectosCooperacionDesc`, datos)
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  },

  async [ACTUALIZAR_BENEFICIARIO_CDES](context, parametros) {
    let datos = parametros.datos;
    let id = datos.id;
    return new Promise((resolve) => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=BeneficiariosProyectosCooperacionDesc/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  },

  async [ACTUALIZAR_CUENTA_BENEFICIARIOS_DIRECTOS_CNS](context, parametros) {
    //console.log("cns")
    let id = parametros.datos.id;

    return new Promise((resolve) => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCNS/Beneficiarios/Directos/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_TIPO_BENEFICIARIO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  },

  async [ACTUALIZAR_CUENTA_INSTITUCIONES_BENEFICIARIAS_CNS](
    context,
    parametros
  ) {
    let id = parametros.datos.id;

    return new Promise((resolve) => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProyectosCNS/Cuenta/Institucion/`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_TIPO_BENEFICIARIO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  },

  async [ELIMINAR_BENEFICIARIO_CDES](context, id) {
    return new Promise((resolve) => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=BeneficiariosProyectosCooperacionDesc/`)
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_CDES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message + " REF:" + error
          });
        });
    });
  }
};

const mutations = {
  [SET_BENEFICIARIO_CDESS_CDES](state, data) {
    state.beneficiarios = data;
  },

  [SET_BENEFICIARIO_CDES](state, data) {
    state.beneficiario = data;
  },

  [SET_TIPO_BENEFICIARIO_CDES](state, data) {
    state.tipoBeneficiario = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
