//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_BENEFICIARIOS_INSTITUCION_CNSC = "obtenerBeneficiariosInstitucionCnsc";
export const REGISTRAR_BENEFICIARIO_INSTITUCION_CNSC = "registrarBeneficiarioInstitucionCnsc";
export const ACTUALIZAR_BENEFICIARIO_INSTITUCION_CNSC = "actualizarBeneficiarioInstitucionCnsc";

// mutation types
export const SET_BENEFICIARIOS_INSTITUCION_CNSC = "setBeneficiariosInstitucionCnsc";
export const SET_BENEFICIARIO_INSTITUCION_CNSC = "setBeneficiarioInstitucionCnsc";

const state = {
  beneficiariosInstitucionCnsc: [],
  beneficiarioInstitucionCnsc: []
};

const getters = {
  obtnenerBeneficiariosInstitucionCnsc(state) {
    return state.beneficiariosInstitucion;
  }
};

/*const beneficiario = {
  id: 1,
  nombreInstitucion: "beneficiario 1",
  nombreTipoIngreso: "1",
  nombreEstado: "Registrada",
  estadosId: 1
};

*/



const actions = {
  async [OBTENER_BENEFICIARIOS_INSTITUCION_CNSC](context, parametros) {
    return new Promise(resolve => {

      ApiService.query(`apiconsume/obtener?endpoint=InstitucionesBeneficiariasCNSC/all/${parametros.estadoId}/${parametros.proyectoId}`)
      .then(({ data }) => {
        context.commit(SET_BENEFICIARIOS_INSTITUCION_CNSC, data.data);

        resolve(data);
      })
      .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
      });
  });
  },


  /**
   * Registrar una condicion
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_BENEFICIARIO_INSTITUCION_CNSC](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {

     
      ApiService.post(
        `apiconsume/create?endpoint=InstitucionesBeneficiariasCNSC`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_INSTITUCION_CNSC, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
      });
    
  },

  async [ACTUALIZAR_BENEFICIARIO_INSTITUCION_CNSC](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=InstitucionesBeneficiariasCNSC/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_INSTITUCION_CNSC, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
}

const mutations = {
  [SET_BENEFICIARIOS_INSTITUCION_CNSC](state, data) {
    state.beneficiariosInstitucionCnsc = data;
  },

  [SET_BENEFICIARIO_INSTITUCION_CNSC](state, data) {
    state.beneficiarioInstitucionCnsc = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
