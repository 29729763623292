//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_SOCIOS_IMPLEMENTADORES_CSS = "obtenerSociosImplementadores";
export const OBTENER_SOCIO_IMPLEMENTADOR_CSS = "obtenerSocioImplementador";

export const REGISTRAR_SOCIO_IMPLEMENTADOR_CSS = "registrarSocioImplementador";
export const ACTUALIZAR_SOCIO_IMPLEMENTADOR_CSS = "actualizarSocioImplementador";



// mutation types
export const SET_SOCIOS_IMPLEMENTADORES_CSS = "setSociosImplementadores";
export const SET_SOCIO_IMPLEMENTADOR_CSS = "setSocioImplementador";


const state = {
  sociosImplementadores: [],
  socioImplementador: {}
};

const getters = {
  obtnenerSociosImplementadores(state) {
    return state.sociosImplementadores;
  }
};

const actions = {
  async [OBTENER_SOCIOS_IMPLEMENTADORES_CSS](context, parametros) {
    return new Promise(resolve => {
        let endpoint = `SocioImplementador/all/${parametros.estadoId}`;
        

      ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
        .then(({ data }) => {
          context.commit(SET_SOCIOS_IMPLEMENTADORES_CSS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [OBTENER_SOCIO_IMPLEMENTADOR_CSS](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=SocioImplementador/`)
        .then(({ data }) => {
          context.commit(SET_SOCIO_IMPLEMENTADOR_CSS, data.data);
          //console.log(this.sociosImplementadores)
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });;
        });
    });
  },

  

  /**
   * Registrar una institución
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_SOCIO_IMPLEMENTADOR_CSS](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=SocioImplementador`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_SOCIO_IMPLEMENTADOR_CSS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },

  async [ACTUALIZAR_SOCIO_IMPLEMENTADOR_CSS](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=SocioImplementador/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_SOCIO_IMPLEMENTADOR_CSS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({ 
            status: 500,
            message: `Ha ocurrido un error inesperado, por favor póngase en contacto con el administrador del sistema. REF: ${error}`
          });
        });
    });
  },
 

};

const mutations = {
  [SET_SOCIOS_IMPLEMENTADORES_CSS](state, data) {
    state.sociosImplementadores = data;
  },

  [SET_SOCIO_IMPLEMENTADOR_CSS](state, data) {
    state.socioImplementador = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
