//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_PROYECCIONES_DESEMBOLSOS_CNSCOO = "obtenerProyeccionesDesembolsosCnsCoo";
export const REGISTRAR_PROYECCION_DESEMBOLSO_CNSCOO = "registrarProyeccionDesembolsoCnsCoo";
export const ELIMINAR_PROYECCION_DESEMBOLSO_CNSCOO = "eliminarProyeccioDesembolsonCnsCoo";

//export const OBTENER_DISTRIBUCIONES_FUENTE_COOPERANTE_CNS = "obtenerDistribucionesFuenteCooperanteCns";


export const OBTENER_MONTO_SUSCRITO_CNSCOO = "obtenerMontoSuscritoCnsCoo";
export const REGISTRAR_MONTO_SUSCRITO_CNSCOO = "registrarMontoSuscritoCnsCoo";
export const ACTUALIZAR_MONTO_SUSCRITO_CNSCOO = "actualizarMontoSuscritoCnsCoo";


export const SET_DATOS_FINANCIEROS_CNSCOO = "setDatosFinancierosCnsCoo";
export const SET_DATO_FINANCIERO_CNSCOO = "setDatoFinancieroCnsCoo";


//export const SET_MONTOS_SUSCRITOS_CNSCOO = "setDatosFinancierosCnsCoo";
export const SET_MONTO_SUSCRITO_CNSCOO = "setMontoSuscritoCnsCoo";


const state = {
    datosFinancierosCnsCoo: [],
    datoFinancieroCnsCoo: []
};

const getters = {
  obtnenerDatosFinancierosCnsCoo(state) {
    return state.datosFinancierosCnsCoo;
  }
};


const actions = {

    /**
     * Obtener las proyecciones de desembolso de un proyecto cnsc
     * @param {*} context 
     * @param {*} parametros 
     * @returns 
     */
    async [OBTENER_PROYECCIONES_DESEMBOLSOS_CNSCOO](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
     
            ApiService.query(`apiconsume/obtener?endpoint=ProyeccionDesembolsoCNSC/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_DATOS_FINANCIEROS_CNSCOO, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar una proyeccion de desembolso
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [REGISTRAR_PROYECCION_DESEMBOLSO_CNSCOO](context, parametros) {

        let datos = parametros.datos;
        

        return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=ProyeccionDesembolsoCNSC`, datos)
                .then(({ data }) => {
                    context.commit(SET_DATO_FINANCIERO_CNSCOO, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });   
    },


    /**
     * Eliminar un registro de proyeccion de desembolsos cnsc
     * @param {*} context 
     * @param {*} id 
     * @returns 
     */
    async [ELIMINAR_PROYECCION_DESEMBOLSO_CNSCOO](context, id){
    
        return new Promise(resolve => {
            ApiService.delete(`apiconsume/delete/${id}?endpoint=ProyeccionDesembolsoCNSC/`)
                .then(({ data }) => {
                    context.commit(SET_DATO_FINANCIERO_CNSCOO, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });  
    
    },


    ///////////////////// SECCION MONTO SUSCRITO /////////////////////////
    
    /**
     * Obtener el monto suscrito registrado del proyecto cnsc
     * @param {*} context 
     * @param {*} parametros 
     * @returns 
     */
    async [OBTENER_MONTO_SUSCRITO_CNSCOO](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
     
            ApiService.query(`apiconsume/obtener?endpoint=MontoSuscritoCNSC/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_MONTO_SUSCRITO_CNSCOO, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar un monto suscrito
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
     async [REGISTRAR_MONTO_SUSCRITO_CNSCOO](context, parametros) {

        let datos = parametros.datos;
        

        return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=MontoSuscritoCNSC`, datos)
                .then(({ data }) => {
                    context.commit(SET_MONTO_SUSCRITO_CNSCOO, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                        status: 400,
                        message: error.message
                    });
                });
        });   
    },



    /**
     * Actualizar el monto suscrito del proyecto cnsc
     * @param {*} context 
     * @param {*} datos 
     * @returns 
     */
    async [ACTUALIZAR_MONTO_SUSCRITO_CNSCOO](context, parametros) {
        //console.log(parametros)
        let datos = parametros.datos;
        let id = datos.proyectoCNSCId;
    
        return new Promise(resolve => {
          ApiService.update(
            `apiconsume/update/${id}?endpoint=MontoSuscritoCNSC/`,
            datos
          )
            .then(({ data }) => {
              context.commit(SET_MONTO_SUSCRITO_CNSCOO, data.data);
              resolve(data);
            })
            .catch(error => {
              console.log(error);
              //context.commit(SET_ERROR, response.data.errors);
            });
        });
      },

    




}


const mutations = {
    [SET_DATOS_FINANCIEROS_CNSCOO](state, data) {
      state.datosFinancierosCnsCoo = data;
    },
  
    [SET_DATO_FINANCIERO_CNSCOO](state, data) {
      state.datoFinancieroCnsCoo = data;
    },

    [SET_MONTO_SUSCRITO_CNSCOO](state, data){
        state.montoSuscritoCoo = data;
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };
  
  
