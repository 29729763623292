//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_COBERTURAS_CNS = "obtenerCoberturasCNS";
export const REGISTRAR_COBERTURA_CNS = "registrarCoberturaCNS";
export const ACTUALIZAR_COBERTURA_CNS = "actualizarCoberturaVinculacionCns";
export const ELIMINAR_COBERTURA_CNS = "eliminarCoberturaCNS";

export const REGISTRAR_ODS_SECUNDARIO = "registrarOdsSecundario";
export const OBTENER_ODS_SECUNDARIOS = "obtenerOdsSecundarios";
export const ELIMINAR_ODS_SECUNDARIO= "eliminarOdsSecundario";
6
export const SET_COBERTURAS_CNS = "setCoberturasCNS";
export const SET_COBERTURA_CNS = "setRCoberturaCNS";
export const SET_ODS_SECUNDARIO = "setOdsSecundario";
export const SET_ODS_SECUNDARIOS = "setOdsSecundarios";

const state = {
    coberturas: [],
    cobertura: [],
    odsSecundario: {},
    odsSecundarios: []
};

const getters = {
  obtnenerCoberturasCNS(state) {
    return state.coberturas;
  }
};


const actions = {

    async [OBTENER_COBERTURAS_CNS](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
     
            ApiService.query(`apiconsume/obtener?endpoint=CoberturaCNS/all/1/${id}/${parametros.tipo}`)
            .then(({ data }) => {
                context.commit(SET_COBERTURAS_CNS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar un cobertura
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_COBERTURA_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=CoberturaCNS`, datos)
            .then(({ data }) => {
                context.commit(SET_COBERTURA_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  
  async [ACTUALIZAR_COBERTURA_CNS](context, parametros){
    let datos = parametros.datos;
    let id = datos.id;
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update2?endpoint=CoberturaCNS/${id}`, datos)
                  .then(({ data }) => {
                      context.commit(SET_COBERTURA_CNS, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },

  
  async [ELIMINAR_COBERTURA_CNS](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=CoberturaCNS/`)
          .then(({ data }) => {
              context.commit(SET_COBERTURA_CNS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
},


   /**
   * Registrar ods secundario para los proyectos
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
    async [REGISTRAR_ODS_SECUNDARIO](context, parametros) {

      let datos = parametros.datos;
       
  
      return new Promise(resolve => {
          ApiService.post(`apiconsume/create?endpoint=AsigOdsSecundarios`, datos)
              .then(({ data }) => {
                  context.commit(SET_ODS_SECUNDARIO, data.data);
                  resolve(data)
              })
              .catch((error) => {
                  resolve({
                      status: 400,
                      message: error.message
                  });
              });
      });   
    },

    /**
     * Obtener los ods secundarios de los proyectos
     * @param {*} context 
     * @param {*} parametros 
     * @returns 
     */

    async [OBTENER_ODS_SECUNDARIOS](context, parametros){
      return new Promise(resolve => {

          let id = parametros.id;
   
          ApiService.query(`apiconsume/obtener?endpoint=AsigOdsSecundarios/all/${parametros.tipo}/${parametros.estadoId}/${id}`)
          .then(({ data }) => {
              context.commit(SET_ODS_SECUNDARIOS, data.data);
              
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
              
              //context.commit(SET_ERROR, response.data.errors);
          });
              
      });
  },

  /**
   * Eliminar un ods secundario (baja)
   * @param {*} context 
   * @param {*} id 
   * @returns 
   */

  async [ELIMINAR_ODS_SECUNDARIO](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=AsigOdsSecundarios/`)
          .then(({ data }) => {
              context.commit(SET_ODS_SECUNDARIO, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
},

};

const mutations = {
  [SET_COBERTURAS_CNS](state, data) {
    state.coberturas = data;
  },

  [SET_COBERTURA_CNS](state, data) {
    state.cobertura = data;
  },

  [SET_ODS_SECUNDARIO](state, data){
    state.odsSecundario = data;
  },

  [SET_ODS_SECUNDARIOS](state, data){
    state.odsSecundarios = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

