//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_INICIATIVAS = "obtenerIniciativas";
export const OBTENER_INICIATIVA = "obtenerIniciativa";
export const REGISTRAR_INICIATIVA = "registrarIniciativa";
export const ACTUALIZAR_INICIATIVA = "actualizarIniciativa";
export const REGISTRAR_INSTITUCIONES_ESTRANJERAS_CSS = "registrarInstitucionesExtranjeras";
export const ACTUALIZAR_ESTADO_INICIATIVA = "actualizarEstadoIniciativaCss";

// mutation types
export const SET_INICIATIVAS = "setIniciativas";
export const SET_INICIATIVA = "setIniciativa";
export const SET_INSTITUCIONES_EXTRANJERAS = "setInstitucionesExtranjeras"
const state = {
  iniciativas: [],
  iniciativa: [],
  institucionesExtranjeras: ""
};

const getters = {
  obtnenerIniciativas(state) {
    return state.iniciativas;
  }
};

const actions = {

  async [OBTENER_INICIATIVAS](context, parametros) {
    return new Promise(resolve => {
     
            ApiService.query(`apiconsume/obtener?endpoint=Iniciativa/all/${parametros.estadoId}/${parametros.negociacionId}`, '')
            .then(({ data }) => {
                context.commit(SET_INICIATIVAS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
              resolve({
                status: 500,
                message: error,
                data: null
              });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
            
    });
  },

  async [OBTENER_INICIATIVA](context, id) {
    return new Promise(resolve => {
      
      ApiService.query(`apiconsume/edit/${id}?endpoint=Iniciativa/`)
          .then(({ data }) => {
              context.commit(SET_INICIATIVA,data.data);
              //console.log(this.instituciones)
              resolve(data)
          })
          .catch((error) => {
              
              resolve(error)
              //context.commit(SET_ERROR, response.data.errors);
          });
    });
  },

  /**
   * Registrar una negociacion directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_INICIATIVA](context, datos) {
    //console.log(datos)
   return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=Iniciativa/${datos.estadosIniciativaId}`, datos)
                .then(({ data }) => {
                    context.commit(SET_INICIATIVA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                  resolve({
                    status: 500,
                    message: "Error",
                    data: error
                  });
                });
        });  
  },

  async [ACTUALIZAR_INICIATIVA](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=Iniciativa/${id}/${datos.datosItem.estadosIniciativaId}`,
        datos.datosItem
      )
        .then(({ data }) => {
          context.commit(SET_INICIATIVA, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },


  async [REGISTRAR_INSTITUCIONES_ESTRANJERAS_CSS](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=Iniciativa/instituciones/`,
        datos.datosItem
      )
        .then(({ data }) => {
          context.commit(SET_INSTITUCIONES_EXTRANJERAS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          })
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },


  async [ACTUALIZAR_ESTADO_INICIATIVA](context, parametros) {
    return new Promise(resolve => {
    let id = parametros.id;
    let estadoId = parametros.estadoId;
   

    ApiService.update(`apiconsume/update2?endpoint=Iniciativa/ActualizarEstado/${id}/${estadoId}`, parametros)
              .then(({ data }) => {
                  context.commit(SET_INICIATIVA, data.data);
                  resolve(data)
              })
              .catch((error) => {
                  resolve({
                    status: 400,
                    data: error})
                  //context.commit(SET_ERROR, response.data.errors);
              });
      });

},


  
};

const mutations = {
  [SET_INICIATIVAS](state, data) {
    state.iniciativas = data;
  },

  [SET_INICIATIVA](state, data) {
    state.iniciativa = data;
  },

  [SET_INSTITUCIONES_EXTRANJERAS](state, data){
    state.institucionesExtranjeras = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
