//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_METAS_ESTRATEGICAS_PGG = "obtenerMetasEstrategicasPgg";
export const ELIMINAR_META_ESTRATEGICA_PGG = "eliminarMetaEstrategicaPgg";
export const OBTENER_META_ESTRATEGICA_PGG = "obtenerMetaEstrategicaPgg";
export const REGISTRAR_META_ESTRATEGICA_PGG = "registrarMetaEstrategicaPgg";
export const ACTUALIZAR_META_ESTRATEGICA_PGG = "actualizarMetaEstrategicaPgg";

// mutation types
export const SET_METAS_ESTRATEGICAS_PGG = "setMetasEstrategicasPgg";
export const SET_META_ESTRATEGICA_PGG = "setMetaEstrategicaPgg";

const state = {
  metasEstrategicasPgg: {},
  metaEstrategicaPgg: []
};

const getters = {
  obtnenerMetasEstrategicasPgg(state) {
    return state.metasEstrategicasPgg;
  }
};

const actions = {
  async [OBTENER_METAS_ESTRATEGICAS_PGG](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=MetasEstrategicasPGG/all/${parametros.estadoId}/${parametros.pilarPggId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_METAS_ESTRATEGICAS_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_META_ESTRATEGICA_PGG](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=MetasEstrategicasPGG/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_META_ESTRATEGICA_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_META_ESTRATEGICA_PGG](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=MetasEstrategicasPGG/`)
        .then(({ data }) => {
          context.commit(SET_META_ESTRATEGICA_PGG, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una Pggs directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_META_ESTRATEGICA_PGG](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=MetasEstrategicasPGG`, datos)
        .then(({ data }) => {
          context.commit(SET_META_ESTRATEGICA_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_META_ESTRATEGICA_PGG](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=MetasEstrategicasPGG/`, datos)
        .then(({ data }) => {
          context.commit(SET_META_ESTRATEGICA_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_METAS_ESTRATEGICAS_PGG](state, data) {
    state.metasEstrategicasPgg = data;
  },

  [SET_META_ESTRATEGICA_PGG](state, data) {
    state.metaEstrategicaPgg = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
