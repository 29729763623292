//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_CNSC_ACTORES_IMPLEMENTADORES = "obtenerCnscActoresImplementadores";
export const REGISTRAR_CNSC_ACTOR_IMPLEMENTADOR = "registrarCnscActorImplementador";
export const ACTUALIZAR_CNSC_ACTOR_IMPLEMENTADOR = "actualizarCnscActorImplementador";
export const ELIMINAR_CNSC_ACTOR_IMPLEMENTADOR = "eliminarCnscActorImplementador";


// mutation types
export const SET_CNS_ACTORES_IMPLEMENTADORES = "setBeneficiariosCNSC";
export const SET_CNS_ACTOR_IMPLEMENTADOR = "setBeneficiarioCNSC";



const state = {
  cnscActoresImplementadores: [],
  cnscActorImplementador: [],

};

const getters = {
  obtnenerCnscActoresImplementadores(state) {
    return state.cnscActoresImplementadores;
  }
};

/*const beneficiario = {
  id: 1,
  numeroBeneficiados: "beneficiario 1",
  nombreEtnia: 'Indigena 1",
  nombreEstado: "Registrada",
  estadosId: 1
};

*/


const actions = {
  async [OBTENER_CNSC_ACTORES_IMPLEMENTADORES](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=CNSCSocioImplementador/all/${parametros.estadoId}/${parametros.proyectoId}`)
        .then(({ data }) => {
          context.commit(SET_CNS_ACTORES_IMPLEMENTADORES, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },


  /**
   * Registrar una condicion
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_CNSC_ACTOR_IMPLEMENTADOR](context, parametros) {
    //console.log(datosEmpresa)
    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=CNSCSocioImplementador`, datos)
            .then(({ data }) => {
                context.commit(SET_CNS_ACTOR_IMPLEMENTADOR, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  async [ACTUALIZAR_CNSC_ACTOR_IMPLEMENTADOR](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=CNSCSocioImplementador/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_CNS_ACTOR_IMPLEMENTADOR, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
        });
        });
    });
  },


       /*
  * Eliminar ejecucion por actividad para departamento y municipio proyectos cns
  */
       async [ELIMINAR_CNSC_ACTOR_IMPLEMENTADOR](context, id){
    
        return new Promise(resolve => {
          ApiService.delete(`apiconsume/delete/${id}?endpoint=CNSCSocioImplementador/`)
              .then(({ data }) => {
                  context.commit(SET_CNS_ACTOR_IMPLEMENTADOR, data.data);
                  resolve(data)
              })
              .catch((error) => {
                  resolve({
                      status: 400,
                      message: error.message
                  });
              });
      });  
      
    },


  

  
}

const mutations = {
  [SET_CNS_ACTORES_IMPLEMENTADORES](state, data) {
    state.cnscActoresImplementadores = data;
  },

  [SET_CNS_ACTOR_IMPLEMENTADOR](state, data) {
    state.cnscActorImplementador = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
