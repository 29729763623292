//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_BENEFICIARIOS_INSTITUCION = "obtenerBeneficiariosInstitucion";
export const REGISTRAR_BENEFICIARIO_INSTITUCION = "registrarBeneficiarioInstitucion";
export const ACTUALIZAR_BENEFICIARIO_INSTITUCION = "actualizarBeneficiarioInstitucion";
export const ELIMINAR_INSTITUCION_BENEFICIARIA_CNS = "eliminarInstitucionBeneficiariaCns";
// mutation types
export const SET_BENEFICIARIOS_INSTITUCION = "setBeneficiariosInstitucion";
export const SET_BENEFICIARIO_INSTITUCION = "setBeneficiarioInstitucion";

const state = {
  beneficiariosInstitucion: [],
  beneficiarioInstitucion: []
};

const getters = {
  obtnenerBeneficiariosInstitucion(state) {
    return state.beneficiariosInstitucion;
  }
};

/*const beneficiario = {
  id: 1,
  nombreInstitucion: "beneficiario 1",
  nombreTipoIngreso: "1",
  nombreEstado: "Registrada",
  estadosId: 1
};

*/



const actions = {
  async [OBTENER_BENEFICIARIOS_INSTITUCION](context, parametros) {
    return new Promise(resolve => {

      ApiService.query(`apiconsume/obtener?endpoint=InstitucionesBeneficiariasCNS/all/${parametros.estadoId}/${parametros.tipoProyecto}/${parametros.proyectoId}`)
      .then(({ data }) => {
        context.commit(SET_BENEFICIARIOS_INSTITUCION, data.data);

        resolve(data);
      })
      .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
      });
  });
  },


  /**
   * Registrar una condicion
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_BENEFICIARIO_INSTITUCION](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {

     
      ApiService.post(
        `apiconsume/create?endpoint=InstitucionesBeneficiariasCNS`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_INSTITUCION, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
      });
    
  },

  async [ACTUALIZAR_BENEFICIARIO_INSTITUCION](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=InstitucionesBeneficiariasCNS/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_BENEFICIARIO_INSTITUCION, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_INSTITUCION_BENEFICIARIA_CNS](context, id){
      return new Promise(resolve => {
        ApiService.delete(`apiconsume/delete/${id}?endpoint=InstitucionesBeneficiariasCNS/`)
            .then(({ data }) => {
                context.commit(SET_BENEFICIARIO_INSTITUCION, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });  
  }

}

const mutations = {
  [SET_BENEFICIARIOS_INSTITUCION](state, data) {
    state.beneficiariosInstitucion = data;
  },

  [SET_BENEFICIARIO_INSTITUCION](state, data) {
    state.beneficiarioInstitucion = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
