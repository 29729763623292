//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_ENMIENDAS_COMPONENTES_CNS =
  "obtenerEnmiendasComponentesCns";
export const REGISTRAR_ENMIENDA_COMPONENTES_CNS =
  "registrarEnmiendaComponentesCns";
export const ELIMINAR_ENMIENDA_COMPONENTES_CNS =
  "eliminarEnmiendaComponentesCns";
export const OBTENER_ENMIENDA_COMPONENTES_CNS = "obtenerEnmiendaComponentesCns";
export const VALIDAR_ENMIENDA_COMPONENTES_CNS = "validarEnmiendaComponentesCns";
export const ACTUALIZAR_ESTADO_ENMIENDA_COMPONENTES_CNS =
  "actualizarEstadoEnmiendaComponentessCns";

export const OBTENER_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS =
  "obtenerSubdivisionesFCComponentesEnmiendaCns";
export const REGISTRAR_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS =
  "registrarSubdivisionFCComponentesEnmiendaCns";
export const ELIMINAR_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS =
  "eliminarSubdivisionFCEnmiendasComponentesCns";

export const OBTENER_SUBDIVISIONES_CP_ENMIENDA_COMPONENTES_CNS =
  "obtenerSubdivisionContrapartidaEnmiendaComponentesCns";

export const REGISTRAR_SUBDIVISION_CP_ENMIENDA_COMPONENTES_CNS =
  "registrarSubdivisionContrapartidaEnmiendaComponentesCns";
export const ELIMINARR_SUBDIVISION_CP_ENMIENDA_COMPONENTES_CNS =
  "eliminarSubdivisionContrapartidaEnmiendaComponentesCns";

export const OBTENER_REASIGNACIONES_ENMIENDA_COMPONENTES_FC_CNS =
  "obtenerReasignacionesFuenteCooperanteEnmiendaComponentesCns";

export const REGISTRAR_REASIGNACION_ENMIENDA_COMPONENTES_FC_CNS =
  "registrarReasignacionFuenteCooperanteEnmiendaComponentesCns";
export const ELIMINAR_REASIGNACION_ENMIENDA_COMPONENTES_FC_CNS =
  "eliminareasignacionFuenteCooperanteEnmiendaComponentesCns";

export const OBTENER_REASIGNACIONES_ENMIENDA_COMPONENTES_CP_CNS =
  "obtenerReasignacionesContrapartidaEnmiendaComponentesCns";

export const REGISTRAR_REASIGNACION_ENMIENDA_COMPONENTES_CP_CNS =
  "registrarReasignacionContrapartidaEnmiendaComponentesCns";

export const ELIMINAR_REASIGNACION_ENMIENDA_COMPONENTES_CP_CNS =
  "eliminareasignacionContrapartidaEnmiendaComponentesCns";

// MUTACIONES
export const SET_ENMIENDAS_COMPONENTESS_CNS = "setEnmiendasComponentesCns";
export const SET_ENMIENDA_COMPONENTESS_CNS = "setEnmiendaComponentesCns";

export const SET_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS =
  "setSubdivisionesFuenteCooperanteEnmiendaComponentesCns";

export const SET_SUBDIVISION_FC_ENMIENDA_COMPONENTES_CNS =
  "setSubdivisionFuenteCooperanteEnmiendaComponentesCns";

export const SET_SUBDIVISIONES_CP_ENMIENDA_COMPONENTES_CNS =
  "setSubdivisionesContrapartidaEnmiendaComponentesCns";

export const SET_SUBDIVISION_CP_ENMIENDA_COMPONENTES_CNS =
  "setSubdivisionContrapartidaEnmiendaComponentesCns";

export const SET_REASIGNACIONES_FC_ENMIENDA_COMPONENTES =
  "setReasignacionFuenteCooperanteEnmiendaComponentesComponentesCns";

export const SET_REASIGNACION_FC_ENMIENDA_COMPONENTES =
  "setReasignacionFuenteCooperanteEnmiendaComponentesComponentesCns";

export const SET_REASIGNACIONES_CP_ENMIENDA_COMPONENTES =
  "setReasignacionesCPEnmiendaComponentes";

export const SET_REASIGNACION_CP_ENMIENDA_COMPONENTES =
  "setReasignacionCPEnmiendaComponentes";

const state = {
  enmiendaComponentes: {},
  enmiendasComponentes: [],
  subdivisionFuenteCooperanteEnmienda: {},
  subdivisionesFuenteCooperanteEnmienda: [],
  subdivisionesContrapartidaEnmienda: [],
  subdivisionContrapartidaEnmienda: {},

  reasignacionFuenteCooperanteEnmiendaComponentes: {},
  reasignacionesFuenteCooperanteEnmiendaComponentes: [],
  reasignacionContrapartidaEnmiendaComponentes: {},
  reasignacionesContrapartidaEnmiendaComponentes: []
};

const getters = {
  obtnenerEnmiendasComponentesCns(state) {
    return state.enmiendasComponentes;
  }
};

const actions = {
  //OBTENER LOS MECANISMOS DE EJECUCION REGISTRADOS DEL PROYECTO
  async [OBTENER_ENMIENDAS_COMPONENTES_CNS](context, parametros) {
    return new Promise(resolve => {
      let id = parametros.id;
      let estadoId = parametros.estadoId;
      //console.log(id + " " + estadoId);

      ApiService.query(
        `apiconsume/obtener?endpoint=EnmiendasComponentesCns/all/${estadoId}/${id}`
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDAS_COMPONENTESS_CNS, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //REGUSTRAR MECANISMO DE EJECUCION
  async [REGISTRAR_ENMIENDA_COMPONENTES_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EnmiendasComponentesCns`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_COMPONENTESS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  //Obtener el avance del proyecto CNS
  async [OBTENER_ENMIENDA_COMPONENTES_CNS](context, parametros) {
    let id = parametros.id;
    // console.log(datos)
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/edit/${id}?endpoint=EnmiendasComponentesCns/`
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_COMPONENTESS_CNS,data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [VALIDAR_ENMIENDA_COMPONENTES_CNS](context, datos) {
    let id = datos.id;
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=EnmiendasComponentesCns/Validar/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_COMPONENTESS_CNS, data.data);
          resolve(data);;
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /*
   * Eliminar un mecanismo de ejecucion del proyecto
   */
  async [ELIMINAR_ENMIENDA_COMPONENTES_CNS](context, datos) {
    let id = datos.id;
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=EnmiendasComponentesCns/`
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_COMPONENTESS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**************************************** SUBDIVISION FUENTE COOPERANTE *******************************************/

  //Obtener los datos financieros de la enmienda de componentes
  async [OBTENER_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS](
    context,
    parametros
  ) {
    return new Promise(resolve => {
      let id = parametros.id;
      ApiService.query(
        `apiconsume/obtener?endpoint=EjecucionesPorActividadCNS/all/EnmiendaComponentes/${parametros.estadoId}/${id}`
      )
        .then(({ data }) => {
          context.commit(
            SET_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS,
            data.data
          );
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar un datoFinancieroCns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS](
    context,
    parametros
  ) {
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=SubdivisionEnmiendaFuenteCooperante`,
        datos
      )
        .then(({ data }) => {
          context.commit(
            SET_SUBDIVISION_FC_ENMIENDA_COMPONENTES_CNS,
            data.data
          );
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [ELIMINAR_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS](context, id){
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=SubdivisionEnmiendaFuenteCooperante/`
      )
        .then(({ data }) => {
          context.commit(SET_SUBDIVISION_FC_ENMIENDA_COMPONENTES_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**************************************** SUBDIVISION CONTRAPARTIDA *******************************************/
  //Obtener los datos financieros de la enmienda de componentes
  async [OBTENER_SUBDIVISIONES_CP_ENMIENDA_COMPONENTES_CNS](
    context,
    parametros
  ) {
    return new Promise(resolve => {
      let id = parametros.id;
      ApiService.query(
        `apiconsume/obtener?endpoint=ContrapartidasActividades/all/EnmiendaComponentes/${parametros.estadoId}/${id}`
      )
        .then(({ data }) => {
          context.commit(
            SET_SUBDIVISIONES_CP_ENMIENDA_COMPONENTES_CNS,
            data.data
          );
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar un datoFinancieroCns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_SUBDIVISION_CP_ENMIENDA_COMPONENTES_CNS](
    context,
    parametros
  ) {
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=SubdivisionEnmiendaContrapartida`,
        datos
      )
        .then(({ data }) => {
          context.commit(
            SET_SUBDIVISION_CP_ENMIENDA_COMPONENTES_CNS,
            data.data
          );
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [ELIMINARR_SUBDIVISION_CP_ENMIENDA_COMPONENTES_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=SubdivisionEnmiendaContrapartida/`
      )
        .then(({ data }) => {
          context.commit(
            SET_SUBDIVISION_CP_ENMIENDA_COMPONENTES_CNS,
            data.data
          );
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**************************************** REASIGNACION FUENTE COOPERANTE **************************************/
  //Obtener los datos financieros de la enmienda de componentes
  async [OBTENER_REASIGNACIONES_ENMIENDA_COMPONENTES_FC_CNS](
    context,
    parametros
  ) {
    return new Promise(resolve => {
      let id = parametros.id;
      ApiService.query(
        `apiconsume/obtener?endpoint=ReasignacionEnmiendaComponentesCooperante/all/${parametros.estadoId}/${id}`
      )
        .then(({ data }) => {
          context.commit(SET_REASIGNACIONES_FC_ENMIENDA_COMPONENTES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar un datoFinancieroCns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_REASIGNACION_ENMIENDA_COMPONENTES_FC_CNS](
    context,
    parametros
  ) {
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ReasignacionEnmiendaComponentesCooperante`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_REASIGNACION_FC_ENMIENDA_COMPONENTES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [ELIMINAR_REASIGNACION_ENMIENDA_COMPONENTES_FC_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=ReasignacionEnmiendaComponentesCooperante/`
      )
        .then(({ data }) => {
          context.commit(SET_REASIGNACION_FC_ENMIENDA_COMPONENTES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /******************************************* REASIGNACION CONTRAPARTIDA ***********************************************************/
  //Obtener los datos financieros de la enmienda de componentes
  async [OBTENER_REASIGNACIONES_ENMIENDA_COMPONENTES_CP_CNS](
    context,
    parametros
  ) {
    return new Promise(resolve => {
      let id = parametros.id;
      ApiService.query(
        `apiconsume/obtener?endpoint=ReasignacionEnmiendaComponentesContrapartida/all/${parametros.estadoId}/${id}`
      )
        .then(({ data }) => {
          context.commit(SET_REASIGNACIONES_CP_ENMIENDA_COMPONENTES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar un datoFinancieroCns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_REASIGNACION_ENMIENDA_COMPONENTES_CP_CNS](
    context,
    parametros
  ) {
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ReasignacionEnmiendaComponentesContrapartida`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_REASIGNACION_CP_ENMIENDA_COMPONENTES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [ELIMINAR_REASIGNACION_ENMIENDA_COMPONENTES_CP_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=ReasignacionEnmiendaComponentesContrapartida/`
      )
        .then(({ data }) => {
          context.commit(SET_REASIGNACION_CP_ENMIENDA_COMPONENTES, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /*
   * Actualizar el estado de una enmienda de componentes
   */
  async [ACTUALIZAR_ESTADO_ENMIENDA_COMPONENTES_CNS](context, datos) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${datos.estadoId}?endpoint=EnmiendasComponentesCns/ActualizarEstado/${datos.id}/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_ENMIENDA_COMPONENTESS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  }
};

const mutations = {
  [SET_ENMIENDA_COMPONENTESS_CNS](state, data) {
    state.enmiendaComponentes = data;
  },

  [SET_ENMIENDAS_COMPONENTESS_CNS](state, data) {
    state.enmiendasComponentes = data;
  },

  [SET_REASIGNACION_FC_ENMIENDA_COMPONENTES](state, data) {
    state.reasignacionFuenteCooperanteEnmiendaComponentes = data;
  },

  [SET_REASIGNACIONES_FC_ENMIENDA_COMPONENTES](state, data) {
    state.reasignacionesFuenteCooperanteEnmiendaComponentes = data;
  },

  [SET_REASIGNACION_CP_ENMIENDA_COMPONENTES](state, data) {
    state.reasignacionContrapartidaEnmiendaComponentes = data;
  },

  [SET_REASIGNACIONES_CP_ENMIENDA_COMPONENTES](state, data) {
    state.reasignacionesContrapartidaEnmiendaComponentes = data;
  },

  [SET_SUBDIVISIONES_FC_ENMIENDA_COMPONENTES_CNS](state, data) {
    state.subdivisionesFuenteCooperanteEnmienda = data;
  },

  [SET_SUBDIVISION_FC_ENMIENDA_COMPONENTES_CNS](state, data) {
    state.subdivisionFuenteCooperanteEnmienda = data;
  },

  [SET_SUBDIVISIONES_CP_ENMIENDA_COMPONENTES_CNS](state, data) {
    state.subdivisionesContrapartidaEnmienda = data;
  },

  [SET_SUBDIVISION_CP_ENMIENDA_COMPONENTES_CNS](state, data) {
    state.subdivisionContrapartidaEnmienda = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
