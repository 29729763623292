//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_ITEMS_CATALOGO = "obtenerItemsCatalogo";
export const OBTENER_ITEMS_CATALOGO_POST = "obtenerItemsCatalogoPost";

export const OBTENER_SUBITEMS_CATALOGO = "obtenerSubItemsCatalogo";

// mutation types
export const SET_ITEMS = "setItems";
export const SET_ITEM = "setItem";


const state = {
    items: [],
    item: []
  };
  
const getters = {
obtnenerItems(state) {
    return state.items;
}
};


/**
 * 
 */
const actions = {
    async [OBTENER_ITEMS_CATALOGO](context, parametros) {
      return new Promise(resolve => {
        
        let endpoint = parametros.endpoint;
 
          ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`)
          .then(({ data }) => {
            context.commit(SET_ITEMS, data.data);
  
            resolve(data);
          })
          .catch(error => {
           // console.log(error);
           // context.commit(SET_ERROR, response.data.errors);
            resolve({
                status: 500,
                message: error
            })
          });
      });
    },


    async [OBTENER_ITEMS_CATALOGO_POST](context, parametros) {
      return new Promise(resolve => {
        
        let endpoint = parametros.endpoint;
        let datos = parametros.datos;
          ApiService.post(`apiconsume/create?endpoint=${endpoint}`, datos)
          
          .then(({ data }) => {
            context.commit(SET_ITEMS, data.data);
  
            resolve(data);
          })
          .catch(error => {
           // console.log(error);
           // context.commit(SET_ERROR, response.data.errors);
            resolve({
                status: 500,
                message: error
            })
          });
      });
    },



    async [OBTENER_SUBITEMS_CATALOGO] (context, parametros){
        //parametros.endpoint y parametros.id
        //console.log(parametros)
        return new Promise(resolve => {
        
            let endpoint = parametros.endpoint;
            let id = parametros.id;
         
            ApiService.query(`apiconsume/obtener?endpoint=${endpoint}/${id}`)
            .then(({ data }) => {
            context.commit(SET_ITEMS, data.data);

            resolve(data);
            })
            .catch(error => {
            console.log(error);

                //context.commit(SET_ERROR, response.data.errors);
                resolve({
                    status: 500
                })
            });
        });
    },




    

}



const mutations = {
    [SET_ITEMS](state, data) {
      state.items = data;
    },
  
    [SET_ITEM](state, data) {
      state.item = data;
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };
  

