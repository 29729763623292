import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";

import catalogointerno from "./catalogos/catalogointerno.module";
import genericoget from "./genericoget.module";
import cargaarchivo from "./cargaarchivo.module";

import modalidadnegociaciondirecta from "./catalogos/modalidadesnegociaciondirecta/modalidadnegociaciondirecta.module";

import pais from "./catalogos/paises/pais.module";
import departamento from "./catalogos/departamentos/departamento.module";
import municipio from "./catalogos/municipios/municipio.module";

import institucion from "./instituciones/institucion.module";
import unidadejecutora from "./unidadesejecutoras/unidadejecutora.module";

import fuentebilateral from "./catalogos/fuentesbilaterales/fuentebilateral.module";
import agenciabilateral from "./catalogos/agenciasbilaterales/agenciabilateral.module";

import fuentemultilateral from "./catalogos/fuentesmultilaterales/fuentemultilateral.module";
import agenciamultilateral from "./catalogos/agenciasmultilaterales/agenciamultilateral.module";

import cooperante from "./cooperantes/cooperante.module";
import sectorcooperante from "./cooperantes/sectorescooperante/sectorcooperante.module";
import modalidadcooperacioncooperante from "./cooperantes/modalidadescooperacioncooperante/modalidadcooperacioncooperante.module";

import estrategia from "./cooperantes/estrategias/estrategia.module";

import proyectocns from "./proyectoscns/proyectocns.module";
import riesgoproyecto from "./proyectoscns/riesgos/riesgo.module";
import indicadorproyecto from "./proyectoscns/indicadores/indicador.module";

import actividadejecucionModule from "./catalogos/actividadesejecucion/actividadejecucion.module";
import periodos from "./catalogos/periodos/periodo.module";
import sectorsidcss from "./catalogos/sectoressidcss/sectorsidcss.module";

import pnd from "./catalogos/pnd/pnd.module";
import metapnd from "./catalogos/metaspnd/metapnd.module";
import ejekatun from "./catalogos/ejeskatun/ejekatun.module";
import prioridadeskatun from "./catalogos/ejeskatun/prioridadkatun.module.js";
import politicapublica from "./catalogos/polititcaspublicas/politicapublica.module";
import ods from "./catalogos/ods/ods.module";
import metaods from "./catalogos/ods/metasods/metaods.module";
import pgg from "./catalogos/pggs/pgg.module";
import pilarpgg from "./catalogos/pggs/pilarpgg.module";
import metaestrategicapgg from "./catalogos/pggs/metapgg.module.js";
import objetivoestrategicopgg from "./catalogos/pggs/objetivoestrategicopgg.module.js";
import objetivosectorialpgg from "./catalogos/pggs/objetivosectorialpgg.module.js";

import tipoaprobaciondonacion from "./catalogos/tiposaprobaciondonacion/tipoaprobaciondonacion.module";
import datofinancierocns from "./proyectoscns/datosfinancieros/datofinancierocns.module";
import mecanismoejecucioncns from "./proyectoscns/mecanismosejecucion/mecanismoejecucioncns.module";

import coberturacns from "./proyectoscns/coberturas/coberturacns.module";

import condiciondesembolso from "./proyectoscns/condicionesdesembolsos/condiciondesembolso.module";
import beneficiario from "./proyectoscns/beneficiarios/beneficiario.module";
import beneficiarioinstitucion from "./proyectoscns/beneficiarios/beneficiarioinstitucion.module";

import documentocns from "./proyectoscns/documentoscns/documentocns.module";
import asignaciondocumentocns from "./proyectoscns/documentoscns/asignaciondocumentocns.module";

import enmiendafechascns from "./proyectoscns/enmiendas/enmiendafechas.module";

import enmiendamontocns from "./proyectoscns/enmiendas/enmiendamontoscns.module";

import enmiendacomponentecns from "./proyectoscns/enmiendas/enmiendacomponentescns.module";

import programacionanualcns from "./proyectoscns/programacionesanuales/programacionanualcns.module";
import iaffcns from "./proyectoscns/iaff/iaffcns.module";
import estimacionanualpresupuesto from "./proyectoscns/estimacionanualpresupuesto/estimacionanualpresupuesto.module.js";
import proyeccionesestimacionanualcns from "./proyectoscns/estimacionanualpresupuesto/proyeccionesestimacionanualcns.module.js";

import negociacionDirecta from "./negociaciones/negociaciondirecta.module";
import iniciativadirecta from "./negociaciones/iniciativadirecta.module";
import asignacioninstitucion from "./negociaciones/iniciativas/instituciones/asignacioninstitucion.module.js";
import asignacionrolactor from "./negociaciones/iniciativas/rolesactores/asignacionrolactor.module.js";
import cobertura from "./negociaciones/iniciativas/cobertura/cobertura.module.js";
import actividadiniciativa from "./negociaciones/actividadiniciativa.module";
import productoiniciativa from "./negociaciones/iniciativas/productosactividades/productoactividad.module";
import presupuesto from "./negociaciones/iniciativas/presupuestos/presupuesto.module";

import articulacion from "./negociaciones/iniciativas/articulaciones/articulacion.module";
import seguimiento from "./negociaciones/iniciativas/seguimientos/seguimiento.module";

import contacto from "./contactos/contacto.module";

import instrumentoalianza from "./alianzas/instrumentoalianza.module";
import socioalianza from "./alianzas/socioalianza.module";

import proyectoalianza from "./alianzas/proyectos/proyectoalianza.module";

import coberturaproyectoalianza from "./alianzas/proyectos/coberturaproyectosalianza/coberturaproyectoalianza.module";

///Cooperantes
import socioimplementador from "./sociosimplementadores/socioimplementador.module";

import proyectocooperantes from "./proyectoscooperantes/proyectocooperantes.module";
import beneficiariocnsc from "./proyectoscooperantes/beneficiarioscooperante/beneficiariocnsc.module";
import beneficiarioinstitucioncnsc from "./proyectoscooperantes/beneficiarioscooperante/beneficiarioinstitucioncnsc.module";
import cnscactorimplementador from "./proyectoscooperantes/actoresimplementadorescooperante/actorimplementadorcooperante.module";
//import beneficiarioinstitucion from "./proyectoscns/beneficiarios/beneficiarioinstitucion.module";

import datofinancierocnsc from "./proyectoscooperantes/datosfinancieroscnsc/datofinancierocnsc.module";

import usuario from "./usuarios/usuario.module";
import rol from "./roles/rol.module";

//Cooperacion descentralizada
import proyectocooperaciondescentralizada from "./proyectoscooperaciondescentralizada/proyectocooperaciondescentralizada.module";
import beneficiariocdes from "./proyectoscooperaciondescentralizada/beneficiarios/beneficiariocdes.module";

//Actores locales
import proyectoactoreslocales from "./proyectosactoreslocales/proyectoactoreslocales.module.js";
import beneficiarioacl from "./proyectosactoreslocales/beneficiarios/beneficiarioacl.module";
import asignacionseguimientoacl from "./proyectosactoreslocales/seguimiento/asignacionseguimientoacl.module.js";

//Solicitudes usuarios
import solicitudusuario from "./solicitudesusuarios/solicitudusuario.module";

import proyectosicoopera from "./reportes/proyectossicoopera/proyectosicoopera.module";

import usuarioproyecto from "./usuarios/usuarioproyecto.module";
import asignacionseguimiento from "./proyectoscns/seguimiento/asignacionseguimiento.module";
import asignacionseguimientocodesc from "./proyectoscooperaciondescentralizada/seguimiento/asignacionseguimientocodesc.module";

import seguimientoespcialista from "./proyectoscns/seguimiento/seguimiento.module";
import solicitudcambioestado from "./proyectoscns/solicitudescambioestado/solicitudcambioestado.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    catalogointerno,
    genericoget,
    cargaarchivo,
    modalidadnegociaciondirecta,
    pais,
    departamento,
    municipio,

    institucion,
    unidadejecutora,
    fuentebilateral,
    agenciabilateral,
    fuentemultilateral,
    agenciamultilateral,
    cooperante,
    sectorcooperante,
    modalidadcooperacioncooperante,

    estrategia,

    proyectocns,
    riesgoproyecto,
    indicadorproyecto,
    datofinancierocns,
    actividadejecucionModule,
    periodos,
    sectorsidcss,
    pnd,
    metapnd,
    ejekatun,
    prioridadeskatun,
    politicapublica,
    ods,
    metaods,
    pgg,
    pilarpgg,
    metaestrategicapgg,
    objetivoestrategicopgg,
    objetivosectorialpgg,
    tipoaprobaciondonacion,
    mecanismoejecucioncns,

    coberturacns,

    condiciondesembolso,
    beneficiarioinstitucion,
    beneficiario,
    documentocns,
    asignaciondocumentocns,
    enmiendafechascns,
    enmiendamontocns,
    enmiendacomponentecns,

    programacionanualcns,
    iaffcns,
    estimacionanualpresupuesto,
    proyeccionesestimacionanualcns,
    negociacionDirecta,
    iniciativadirecta,
    asignacioninstitucion,
    asignacionrolactor,
    cobertura,
    actividadiniciativa,
    productoiniciativa,
    presupuesto,
    articulacion,

    seguimiento,
    contacto,

    instrumentoalianza,
    socioalianza,
    proyectoalianza,
    coberturaproyectoalianza,

    socioimplementador,

    proyectocooperantes,
    datofinancierocnsc,
    beneficiariocnsc,
    beneficiarioinstitucioncnsc,
    cnscactorimplementador,

    usuario,
    rol,

    proyectocooperaciondescentralizada,
    beneficiariocdes,
    proyectoactoreslocales,
    beneficiarioacl,
    asignacionseguimientoacl,
    solicitudusuario,
    proyectosicoopera,
    usuarioproyecto,
    asignacionseguimiento,
    asignacionseguimientocodesc,
    seguimientoespcialista,
    solicitudcambioestado
  }
});
