//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const REGISTRAR_IAFF_CNS = "registrarIaffCns";
export const ACTAULIZAR_CUENTA_DESEMBOLSOS_RECIBIDOS_IAFF = "actualizarCuentaConDesembolsosRecibidosIaff";
export const OBTENER_IAFFS_CNS = "obtenerIaffsCns";
export const ACTUALIZAR_IAFFS_CNS = "actualizarIaffCns";
export const REGISTRAR_DIFICULTADES_IAFF_CNS = "registrarDificultadesIaffCns";
export const OBTENER_IAFF_CNS = "obtenerIaffCns";

//********* SECCIONES ************/

//Seccion: Desembolsos Recibidos
export const OBTENER_DESEMBOLSOS_RECIBIDOS_FC =
  "obtenerDesembolsosRecibidosFuenteCooperante";
export const REGISTRAR_DESEMBOLSOS_RECIBIDOS_FC =
  "registrarDesembolsosRecibidosFuenteCooperante";
export const ELIMINAR_DESEMBOLSO_RECIBIDO_FC =
  "eliminarDesembolsoRecibidoFuenteCooperante";
export const OBTENER_DESEMBOLSOS_ANTERIORES_RECIBIDOS_FC =
  "obtenerDesembolsosAnterioresRecibidosFC";

/// Seccion: Ejecuciones Fuente Cooperante
export const OBTENER_EJECUCIONES_FC = "obtenerEjecucionesFuenteCooperante";
export const OBTENER_EJECUCIONES_FC_ANTERIORES =
  "obtenerEjecucionesFuenteCooperanteAnteriores";
export const REGISTRAR_EJECUCION_FC = "registrarEjecucionFuenteCooperante";
export const ELIMINAR_EJECUCION_FC = "eliminarEjecucionFuenteCooperante";

export const OBTENER_ACUMULADO_EJECUCIONES_FUENTE_COOPERANTE =
  "obtenerAcumuladoEjecucionesFC";

/// Seccion: Ejecuciones Contrapartida
export const OBTENER_EJECUCIONES_CP = "obtenerEjecucionesContraPartida";
export const REGISTRAR_EJECUCION_CP = "registrarEjecucionContraPartida";
export const ELIMINAR_EJECUCION_CP = "eliminarEjecucionContraPartida";

export const OBTENER_ACUMULADO_EJECUCIONES_CONTRAPARTIDA =
  "obtenerAcumuladoEjecucionesCP";

//Seccion: Ejecuciones Físicas Cooperante
export const OBTENER_EJECUCIONES_FISICAS_FC =
  "obtenerEjecucionesFisicasCooperanteIaff";
export const REGISTRAR_EJECUCION_FISICA_FC =
  "registrarEjecucionFisicaCooperanteIaff";
export const ELIMINAR_EJECUCION_FISICA_FC =
  "eliminarEjeculationFisicaCooperanteIaff";

//Seccion: Ejecuciones Físicas Contrapartida
export const OBTENER_EJECUCIONES_FISICAS_CP =
  "obtenerEjecucionesFisicasContrapartidaIaff";
export const REGISTRAR_EJECUCION_FISICA_CP =
  "registrarEjecucionFisicaContrapartidaIaff";
export const ELIMINAR_EJECUCION_FISICA_CP =
  "eliminarEjeculationFisicaContrapartidaIaff";

  //Seccion: Ejecuciones Presupuestarias Anuales IAff
export const REGISTRAR_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF =
  "registrarEjecucionPresupuestariaAnualIaff";
export const OBTENER_EJECUCIONES_PRESUPUESTARIAS_ANUALES_IAFF =
  "obtenerEjecucionesPresupuestariasAnualesIaff";
export const OBTENER_EJECUCIONES_PRESUPUESTARIAS_ANUALES_ANTERIORES_IAFF =
"obtenerEjecucionesPresupuestariasAnualesAnterioresIaff";

export const ELIMINAR_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF =
  "eliminarEjecucionesPresupuestariaAnualIff";

//Seccion: Ejecuciones territoriales IAff
export const REGISTRAR_EJECUCION_TERRITORIAL =
  "registrarEjecucionTerritorialIaff";
export const OBTENER_EJECUCIONES_TERRITORIALES =
  "obtenerEjecucionesTerritorialesIaff";
export const ELIMINAR_EJECUCION_TERRITORIAL =
  "eliminarEjecucionTerritorialIaff";

//Seccion: Evidencias IAff
export const REGISTRAR_EVIDENCIA_IAFF = "registrarEvidenciaIaff";
export const OBTENER_EVIDENCIAS_IAFF = "obtenerEjeciasIaff";
export const ELIMINAR_EVIDENCIA_IAFF = "eliminarEvidenciasIaff";

//Seccion confirmación y validación
export const VALIDAR_IAFF = "validarInformacionIaffCns";
export const CONFIRMAR_IAFF = "confirmarInformacionIaffCns";
export const ACTIVAR_IAFF = "activarIaffCns";

/*export const ACTUALIZAR_MONTO_ASIGNADO = "actualizarMontoAsignado";

export const REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CNS = "registrarPlanificacionAnualActividadesCns";
export const OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CNS  = "obtenerPlanificacionesAnualesActividadesCns";

export const REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CONTRAPARTIDA_CNS = "registrarPlanificacionAnulaContrapartidaCns";
export const OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CONTRAPARTIDA_CNS  = "obtenerPlanificacionesAnualesActividadesContrapartidaCns";

export const OBTENER_PLANIFICACIONES_ANUALES_CUATRIMESTRE_CNS = "obtenerPlanificacionesAnualesCuatrimestre";
export const REGISTRAR_PLANIFICACION_ANUAL_CUATRIMESTRE_CNS = "registrarPlanificacionAnualCuatrimestre";

*/
export const SET_IAFF_CNS = "setIaffCns";
export const SET_IAFFS_CNS = "setIaffsCns";

export const SET_DESEMBOLSO_IAFF_CNS = "setDesembolsoIaffCns";
export const SET_DESEMBOLSOS_IAFF_CNS = "setDesembolsosCns";

export const SET_EJECUCIONES_COOPERANTE_IAFF = "setEjecucionesCoooperanteIaff";
export const SET_EJECUCION_COOPERANTE_IAFF = "setEjecucionCooperanteIaff";

export const SET_EJECUCIONES_CONTRAPARTIDA_IAFF =
  "setEjecucionesContrapartidaIaff";
export const SET_EJECUCION_CONTRAPARTIDA_IAFF = "setEjecucionContrapartidaIaff";

export const SET_EJECUCIONES_FISICAS_COOPERANTE_IAFF =
  "setEjecucionesFisicasIaff";
export const SET_EJECUCION_FISICA_COOPERANTE_IAFF = "setEjecucionFisicaIaff";

export const SET_EJECUCIONES_FISICAS_CONTRAPARTIDA_IAFF =
  "setEjecucionesFisicasContraparitdaIaff";
export const SET_EJECUCION_FISICA_CONTRAPARTIDA_IAFF =
  "setEjecucionFisicaContraparitdaIaff";

export const SET_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF =
  "setEjecucionPresupuestariaAnualIaff";

export const SET_EJECUCIONES_PRESUPUESTARIAS_ANUALES_IAFF =
  "setEjecucionesPresupuestariasAnualesIaff";

export const SET_EJECUCIONES_TERRITORIALES_IAFF =
  "setEjecucionesTerritoralesIaff";
export const SET_EJECUCION_TERRITORIAL_IAFF = "setEjecucionTerritoralIaff";

export const SET_EVIDENCIAS_IAFF = "setEvidenciasIaff";
export const SET_EVIDENCIA_IAFF = "setEvidenciaIaff";

export const SET_EJECUCIONES_COOPERANTE_ANTERIORES_IAFF =
  "setEjecucionesCoopeanteAnterioresIaff";

const state = {
  iaffsCns: [],
  iaffCns: {},
  desembolsosRecibidos: [],
  desmbolsoRecibido: {},
  ejecucionesCooperante: [],
  ejecucionesCooperanteAnteriores: [],
  ejecucionCooperante: {},
  ejecucionesContrapartida: [],
  ejecucionContrapartida: {},
  ejecucionesFisicasCooperante: [],
  ejecucionFisicaCooperante: {},
  ejecucionesFisicasContrapartida: [],
  ejecucionFisicaContrapartida: {},
  ejecucionesPresupuestariasAnuales: [],
  ejecucionPresupuestariaAnual: {},
  ejecucionesTerritoriales: [],
  ejecucionTerritorial: {},
  evidenciasIaff: [],
  evidenciaIaff: {}
};

const getters = {
  obtnenerIaffsCns(state) {
    return state.iaffsCns;
  }
};

const actions = {
  /**
   * Obtener los iaff de un proyecto
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_IAFFS_CNS](context, parametros) {
    return new Promise(resolve => {
      let proyectoId = parametros.proyectoId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/IAFFCNS/all/${estadoId}/${proyectoId}`
      )
        .then(({ data }) => {
          if (data.status === 200) {
            context.commit(SET_IAFFS_CNS, data.data);
          } else {
            context.commit(SET_IAFFS_CNS, []);
          }
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Obtener  iaff por id
   * @param {*} context
   * @param {*} id
   * @returns
   */
  async [OBTENER_IAFF_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=IAFFCNS/`)
        .then(({ data }) => {
          context.commit(SET_IAFF_CNS, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar programacion anual cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_IAFF_CNS](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=IAFFCNS`, parametros)
        .then(({ data }) => {
          context.commit(SET_IAFF_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Actualizar si el iaff cuenta con desembolsos recibidos
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [ACTAULIZAR_CUENTA_DESEMBOLSOS_RECIBIDOS_IAFF](context, parametros) {
    let id = parametros.id;
    let cuentaConDesembolsos = parametros.cuentaConDesembolsos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=IAFFCNS/ActaulizarCuentaConDesembolsos/${id}/${cuentaConDesembolsos}`,
        parametros
      )
        .then(({ data }) => {
          context.commit(SET_IAFF_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Registrar dificultades de un iaff
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_DIFICULTADES_IAFF_CNS](context, parametros) {
    let id = parametros.id;
    let datos = parametros.datos;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=IAFFCNS/section/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_IAFF_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  ///////////// SUBSECCION AVANCE BIMESTRAL ////////////////////////

  /**
   * Registrar los desembolsos recibidos de un iaff para un proyecto cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [REGISTRAR_DESEMBOLSOS_RECIBIDOS_FC](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=DesembolsosIAFF`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_IAFF_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Obtener los desembolsos recibidos de un iaff para un proyecto cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_DESEMBOLSOS_RECIBIDOS_FC](context, parametros) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/DesembolsosIAFF/all/${estadoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_DESEMBOLSOS_IAFF_CNS, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Obtener los desembolsos anteriores recibidos del iaff
   * @param {*} context
   * @param {*} parametros
   */
  async [OBTENER_DESEMBOLSOS_ANTERIORES_RECIBIDOS_FC](context, parametros) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/DesembolsosIAFF/all/Anteriores/${estadoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_DESEMBOLSOS_IAFF_CNS, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar desembolso recibido FC
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_DESEMBOLSO_RECIBIDO_FC](context, id) {
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=DesembolsosIAFF/`)
        .then(({ data }) => {
          context.commit(SET_DESEMBOLSO_IAFF_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  ///////////////////////// Subseccion Ejecuciones fuente cooperante ////////////////////////////
  /**
   *
   */

  /**
   * Registrar los desembolsos recibidos de un iaff para un proyecto cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [REGISTRAR_EJECUCION_FC](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EjecucionCooperanteIAFFCNS`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_COOPERANTE_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Obtener los desembolsos recibidos de un iaff para un proyecto cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_EJECUCIONES_FC](context, parametros) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionCooperanteIAFFCNS/all/${estadoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_COOPERANTE_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Obtener las ejecuciones de la fuente cooperante anteriores al id especificado
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_EJECUCIONES_FC_ANTERIORES](context, parametros) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let proyectoId = parametros.proyectoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionCooperanteIAFFCNS/all/Anteriores/${proyectoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_COOPERANTE_ANTERIORES_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Obtener acumulado de ejecución de la fuente cooperante iaff y suscrito en el poa
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_ACUMULADO_EJECUCIONES_FUENTE_COOPERANTE](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionCooperanteIAFFCNS/Acumulado/${parametros.proyectoId}/${parametros.iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_COOPERANTE_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar desembolso recibido FC
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_EJECUCION_FC](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=EjecucionCooperanteIAFFCNS/`
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_COOPERANTE_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  ///////////////////////// Subseccion Ejecuciones contrapartida ////////////////////////////
  /**
   *
   */

  /**
   * Registrar ejecución contrapartida
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [REGISTRAR_EJECUCION_CP](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EjecucionContrapartidaIAFFCNS`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_CONTRAPARTIDA_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Obtener las ejecucuioens contrapartida
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_EJECUCIONES_CP](context, parametros) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionContrapartidaIAFFCNS/all/${estadoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_CONTRAPARTIDA_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Obtener el monto ejecutado y programado de la contrapartida
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_ACUMULADO_EJECUCIONES_CONTRAPARTIDA](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionContrapartidaIAFFCNS/Acumulado/${parametros.proyectoId}/${parametros.iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_CONTRAPARTIDA_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar ejecución contrapartida
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_EJECUCION_CP](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=EjecucionContrapartidaIAFFCNS/`
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_CONTRAPARTIDA_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  ////////////////////////////// EJECUCIONES FISICAS COOPEANTE ////////////////////////////
  /*
   *
   */

  /**
   * Registrar ejecución fisica cooperante
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [REGISTRAR_EJECUCION_FISICA_FC](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EjecucionFisicaFCIAFCNS`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_FISICA_COOPERANTE_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Obtener las ejecucuioens fisicas cooperante
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_EJECUCIONES_FISICAS_FC](context, parametros) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionFisicaFCIAFCNS/all/${estadoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_FISICAS_COOPERANTE_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar ejecución fisica cooperante
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_EJECUCION_FISICA_FC](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=EjecucionFisicaFCIAFCNS/`
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_FISICA_COOPERANTE_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  ////////////////////////////// EJECUCIONES FISICAS CONTRAPARTIDA ////////////////////////////
  /*
   *
   */

  /**
   * Registrar ejecución fisica contrapartida
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [REGISTRAR_EJECUCION_FISICA_CP](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EjecucionFisicaCPIAFCNS`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_FISICA_CONTRAPARTIDA_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Obtener las ejecucuioens fisicas contrapartida
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_EJECUCIONES_FISICAS_CP](context, parametros) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionFisicaCPIAFCNS/all/${estadoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_FISICAS_CONTRAPARTIDA_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar ejecución fisica contrapartida
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_EJECUCION_FISICA_CP](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=EjecucionFisicaCPIAFCNS/`
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_FISICA_CONTRAPARTIDA_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  ////////////////////////////// EJECUCION PRESUPUESTARIA ANUAL ///////////////////////////////////

  /**
   * Registrar ejecución fisica contrapartida
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [REGISTRAR_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EjecucionPresupuestariaIAFF`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Obtener las ejecucuioens presupuestarias anuales
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_EJECUCIONES_PRESUPUESTARIAS_ANUALES_IAFF](
    context,
    parametros
  ) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionPresupuestariaIAFF/all/${estadoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_PRESUPUESTARIAS_ANUALES_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_EJECUCIONES_PRESUPUESTARIAS_ANUALES_ANTERIORES_IAFF](
    context,
    parametros
  ) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionPresupuestariaIAFF/all/Anteriores/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_PRESUPUESTARIAS_ANUALES_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar ejecución presupuestaria anual iaff
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=EjecucionPresupuestariaIAFF/`
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /////////////////////////// FIN EJECUCION PRESUPUESTARIA ANUAL //////////////////////////////////

  ////////////////////////////// EJECUCIONES TERRITORIALES IAFF ////////////////////////////
  /*
   *
   */

  /**
   * Registrar ejecución territorial
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [REGISTRAR_EJECUCION_TERRITORIAL](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EjecucionTerritorialIAFFCNS`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_TERRITORIAL_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Obtener las ejecuciones territoriales del iaff
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_EJECUCIONES_TERRITORIALES](context, parametros) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/EjecucionTerritorialIAFFCNS/all/${estadoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EJECUCIONES_TERRITORIALES_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar ejecución teritorial
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_EJECUCION_TERRITORIAL](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=EjecucionTerritorialIAFFCNS/`
      )
        .then(({ data }) => {
          context.commit(SET_EJECUCION_TERRITORIAL_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  ////////////////////////////// SECCION EVIDENCIAS IAFF /////////////////////////////////////////////

  /*
   *
   */

  /**
   * Registrar evidencia iaff
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [REGISTRAR_EVIDENCIA_IAFF](context, parametros) {
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=EvidenciasIAFF`,
        parametros.datos
      )
        .then(({ data }) => {
          context.commit(SET_EVIDENCIA_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Obtener las evidencias del iaff
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [OBTENER_EVIDENCIAS_IAFF](context, parametros) {
    return new Promise(resolve => {
      let iaffId = parametros.iaffId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/EvidenciasIAFF/all/${estadoId}/${iaffId}`
      )
        .then(({ data }) => {
          let datos = [];
          if (data.status == 200) {
            datos = data.data;
          }
          context.commit(SET_EVIDENCIAS_IAFF, datos);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar evidencia
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_EVIDENCIA_IAFF](context, id) {
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=EvidenciasIAFF/`)
        .then(({ data }) => {
          context.commit(SET_EVIDENCIA_IAFF, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   *  //Validar y generear pdf del IAFF
   * @param {*} context
   * @param {*} iaffId
   * @returns
   */
  async [VALIDAR_IAFF](context, iaffId) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=IAFFCNS/Validar/${iaffId}`,
        { id: iaffId }
      )
        .then(({ data }) => {
          context.commit(SET_IAFF_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   *  Confirmar el IAFF
   * @param {*} context
   * @param {*} iaffId
   * @returns
   */
  async [CONFIRMAR_IAFF](context, iaffId) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=IAFFCNS/Confirmar/${iaffId}`,
        { id: iaffId }
      )
        .then(({ data }) => {
          context.commit(SET_IAFF_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   *  Activar nuevamente el IAFF
   * @param {*} context
   * @param {*} iaffId
   * @returns
   */
  async [ACTIVAR_IAFF](context, iaffId) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update2?endpoint=IAFFCNS/Activar/${iaffId}`,
        { id: iaffId }
      )
        .then(({ data }) => {
          context.commit(SET_IAFF_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  }
};

const mutations = {
  [SET_IAFFS_CNS](state, data) {
    state.iaffsCns = data;
  },

  [SET_IAFF_CNS](state, data) {
    state.iaffCns = data;
  },

  [SET_DESEMBOLSOS_IAFF_CNS](state, data) {
    state.desembolsosRecibidos = data;
  },

  [SET_DESEMBOLSO_IAFF_CNS](state, data) {
    state.desembolsoRecibido = data;
  },

  [SET_EJECUCIONES_COOPERANTE_IAFF](state, data) {
    state.ejecucionesCooperante = data;
  },

  [SET_EJECUCIONES_COOPERANTE_ANTERIORES_IAFF](state, data) {
    state.ejecucionesCooperanteAnteriores = data;
  },

  [SET_EJECUCION_COOPERANTE_IAFF](state, data) {
    state.ejecucionCooperante = data;
  },

  [SET_EJECUCIONES_CONTRAPARTIDA_IAFF](state, data) {
    state.ejecucionesContrapartida = data;
  },

  [SET_EJECUCION_CONTRAPARTIDA_IAFF](state, data) {
    state.ejecucionContrapartida = data;
  },

  [SET_EJECUCIONES_FISICAS_COOPERANTE_IAFF](state, data) {
    state.ejecucionesFisicasCooperante = data;
  },

  [SET_EJECUCION_FISICA_COOPERANTE_IAFF](state, data) {
    state.ejecucionFisicaCooperante = data;
  },

  [SET_EJECUCIONES_FISICAS_CONTRAPARTIDA_IAFF](state, data) {
    state.ejecucionesFisicasContrapartida = data;
  },

  [SET_EJECUCION_FISICA_CONTRAPARTIDA_IAFF](state, data) {
    state.ejecucionFisicaContrapartida = data;
  },

  [SET_EJECUCION_PRESUPUESTARIA_ANUAL_IAFF](state, data) {
    state.ejecucionPresupuestariaAnual = data;
  },

  [SET_EJECUCIONES_PRESUPUESTARIAS_ANUALES_IAFF](state, data) {
    state.ejecucionesPresupuestariasAnuales = data;
  },

  [SET_EJECUCIONES_TERRITORIALES_IAFF](state, data) {
    state.ejecucionesTerritoriales = data;
  },

  [SET_EJECUCION_TERRITORIAL_IAFF](state, data) {
    state.ejecucionTerritorial = data;
  },

  [SET_EVIDENCIAS_IAFF](state, data) {
    state.evidenciasIaff = data;
  },

  [SET_EVIDENCIA_IAFF](state, data) {
    state.evidenciaIaff = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
