//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_POLITICAS_PUBLICAS = "obtenerPoliticasPublicas";
export const ELIMINAR_POLITICA_PUBLICA = "eliminarPoliticaPublica";
export const OBTENER_POLITICA_PUBLICA = "obtenerPoliticaPublica";
export const REGISTRAR_POLITICA_PUBLICA = "registrarPoliticaPublica";
export const ACTUALIZAR_POLITICA_PUBLICA = "actualizarPoliticaPublica";

// mutation types
export const SET_POLITICAS_PUBLICAS = "setpoliticasPublicas";
export const SET_POLITICA_PUBLICA = "setpoliticaPublica";

const state = {
  politicaPublica: {},
  politicasPublicas: []
};

const getters = {
  obtnenerPoliticaPublica(state) {
    return state.politicaPublica;
  }
};

const actions = {
  async [OBTENER_POLITICAS_PUBLICAS](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=PoliticaPublica/all/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_POLITICAS_PUBLICAS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_POLITICA_PUBLICA](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=PoliticaPublica/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_POLITICA_PUBLICA, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_POLITICA_PUBLICA](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=PoliticaPublica/`)
        .then(({ data }) => {
          context.commit(SET_POLITICA_PUBLICA, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una politicasPublicas directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_POLITICA_PUBLICA](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=PoliticaPublica`, datos)
        .then(({ data }) => {
          context.commit(SET_POLITICA_PUBLICA, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_POLITICA_PUBLICA](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=PoliticaPublica/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_POLITICA_PUBLICA, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_POLITICAS_PUBLICAS](state, data) {
    state.politicasPublicas = data;
  },

  [SET_POLITICA_PUBLICA](state, data) {
    state.politicaPublica = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
