import JwtService from "@/core/services/jwt.service";

//import axios from "axios";
import ApiService from "@/core/services/api.service";

export const OBTENER_USUARIOS_ADSIS = "obtenerUsuariosAdsis";
export const OBTENER_USUARIOS_POR_ROL = "obtenerUsuarioPorRolSco";

export const REGISTRAR_USUARIO_ADSIS = "registrarUsuarioAdsis";

export const OBTENER_USUARIO_ADSIS = "obtenerUsuarioAdsis";
export const ACTUALIZAR_USUARIO_ADSIS = "actualizarUsuarioAdsis";
export const ACTUALIZAR_ESTADO_USUARIO_ADSIS = "actualizarEstadoUsuarioAdsis";

export const OBTENER_PERFIL_USUARIO_ADSIS = "obtenerPerfilUsuarioAdsis";

export const ASIGNAR_ROL_ADSIS = "asignarRolAdsis";


export const ACTUALIZAR_PASS_USUARIO_PERFIL = "actualizarPassPerfil";
export const ACTUALIZAR_PASS_USUARIO_EMAIL = "actualizarPassEmail";
// mutation types
export const SET_USUARIOS_ADSIS = "setUsuariosAdsis";
export const SET_USUARIO_ADSIS = "setUsuarioAdsis";

const state = {
  usuariosAdsis: [],
  usaurioAdsis: {}
};

const getters = {
  obtenerUsuariosAdsis(state) {
    return state.usuariosAdsis;
  }
};

const actions = {
  async [OBTENER_USUARIOS_ADSIS](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/obtener?endpoint=Users/${parametros.estadoId}/${parametros.tipoUsuario}`, '')
        .then(({ data }) => {
          context.commit(SET_USUARIOS_ADSIS, data.data);
          resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Obtener usuarios por rol especifico (solo sicoopera)
   */
  async [OBTENER_USUARIOS_POR_ROL](context, parametros) {
    return new Promise(resolve => {

      ApiService.query(`apiconsume/obtener?endpoint=Users/SCO/${parametros.estadoId}/${parametros.rolUsuario}`, '')
        .then(({ data }) => {
          context.commit(SET_USUARIOS_ADSIS, data.data); 
          resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar usuario en adsis
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_USUARIO_ADSIS](context, parametros) {
    return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=Users`, parametros.datos)
                .then(({ data }) => {
                    context.commit(SET_USUARIO_ADSIS, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },



   /**
   * Obtener un usuario en adsis
   * @param {*} context
   * @param {*} datos
   * @returns
   */
   async [OBTENER_USUARIO_ADSIS](context, parametros) {
    return new Promise(resolve => {
            ApiService.query(`apiconsume/obtener?endpoint=Users/${parametros.nick}`)
                .then(({ data }) => {
                    context.commit(SET_USUARIO_ADSIS, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },

  /**
   * Actualizar el usuario del sistema sicoopera en el esquema adsis
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */

  async [ACTUALIZAR_USUARIO_ADSIS](context, parametros){
    //console.log(parametros)
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update2?endpoint=Users/${parametros.datos.usuario}`, parametros.datos)
                  .then(({ data }) => {
                      context.commit(SET_USUARIO_ADSIS, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  /**
   * Actualizar el estado del usuario en el adsis
   * @param {*} context 
   * @param {*} parametros 
   * @returns 
   */

  async [ACTUALIZAR_ESTADO_USUARIO_ADSIS](context, parametros){

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update2?endpoint=Users/Actualizar/Estado`, parametros)
                  .then(({ data }) => {
                      context.commit(SET_USUARIO_ADSIS, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  /**
   * Obtener datos para el perfil de usuario
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [OBTENER_PERFIL_USUARIO_ADSIS](context, parametros) {
    return new Promise(resolve => {
            ApiService.query(`apiconsume/obtener?endpoint=Users/Perfil/${parametros.nick}`)
                .then(({ data }) => {
                    context.commit(SET_USUARIO_ADSIS, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },



   /**
   * asignar/eliminar un rol al usuario
   * @param {*} context
   * @param {*} datos
   * @returns
   */
      async [ASIGNAR_ROL_ADSIS](context, parametros) {
        return new Promise(resolve => {
                ApiService.post(`apiconsume/create?endpoint=UsuariosRoles`, parametros.datos)
                    .then(({ data }) => {
                        context.commit(SET_USUARIO_ADSIS, data.data);
                        resolve(data)
                    })
                    .catch((error) => {
                        resolve({
                          status: 500,
                          message: error
                      })
                    });
            });
      },



  /**
   * Actualizar contraseña desde el perfil
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [ACTUALIZAR_PASS_USUARIO_PERFIL](context, parametros) {
    parametros.datos.token = JwtService.getToken();
    return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=Users/Actualizar/Pass/Perfil`, parametros.datos)
                .then(({ data }) => {
                    context.commit(SET_USUARIO_ADSIS, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },


  
  /**
   * Actualizar contraseña desde el inicio de sesion (enviar correo)
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [ACTUALIZAR_PASS_USUARIO_EMAIL](context, parametros) {
   // parametros.datos.token = JwtService.getToken();
    return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=Users/Actualizar/Pass/Email`, parametros)
                .then(({ data }) => {
                    context.commit(SET_USUARIO_ADSIS, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },






  

     


  

};


const mutations = {
    [SET_USUARIOS_ADSIS](state, data) {
      state.usuariosAdsis = data;
    },
  
    [SET_USUARIO_ADSIS](state, data) {
      state.usaurioAdsis = data;
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };