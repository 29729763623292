//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_COBERTURAS_PROYECTO_ALIANZA = "obtenerCoberturaProyectosAlianza";

export const REGISTRAR_COBERTURA_PROYECTO_ALIANZA = "registrarCoberturaProyectoAlianza";

export const ELIMINAR_COBERTURA_PROYECTO_ALIANZA = "eliminarCoberturaProeyctoAlianza";

// mutation types
export const SET_COBERTURAS_PROYECTO_ALIANZA = "setCoberturaProyectosAlianza";
export const SET_COBERTURA_PROYECTO_ALIANZA = "setCoberturaProyectoAlianza";

const state = {
  coberturasProyectoAlianza: [],
  coberturaProyectoAlianza: []
};

const getters = {
  obtnenerCoberturasProyectoAlianza(state) {
    return state.coberturaProyectosAlianza;
  }
};

/**
 * Tipo 1 es directa y 2 es comixta
 */
const actions = {
  async [OBTENER_COBERTURAS_PROYECTO_ALIANZA](context, parametros) {
    return new Promise(resolve => {

        ApiService.query(`apiconsume/obtener?endpoint=Cobertura/all/proyecto/${parametros.estadoId}/${parametros.proyectoId}`, '')
        .then(({ data }) => {
            context.commit(SET_COBERTURAS_PROYECTO_ALIANZA, data.data);
            
            resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: "Error",
            data: error
          });
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },



  /**
   * Registrar una proyectoAlianza directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_COBERTURA_PROYECTO_ALIANZA](context, parametros) {
    return new Promise(resolve => {
            ApiService.post(`apiconsume/create?endpoint=Cobertura`, parametros.datos)
                .then(({ data }) => {
                    context.commit(SET_COBERTURA_PROYECTO_ALIANZA, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        });
  },

  async [ELIMINAR_COBERTURA_PROYECTO_ALIANZA](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=Cobertura/`)
          .then(({ data }) => {
              context.commit(SET_COBERTURA_PROYECTO_ALIANZA, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
}




  


};

const mutations = {
  [SET_COBERTURAS_PROYECTO_ALIANZA](state, data) {
    state.coberturasProyectoAlianza = data;
  },

  [SET_COBERTURA_PROYECTO_ALIANZA](state, data) {
    state.coberturaProyectoAlianza = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
