//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_PROYECTOS_CNSCOO = "obtenerProyectoCNSC";
export const OBTENER_PROYECTO_CNSCOO = "obtenerProyectoCNSCOO";
export const REGISTRAR_PROYECTO_CNSCOO = "registrarProyectoCNSCOO";
export const ACTUALIZAR_PROYECTO_CNSCOO = "actualizarProyectoCNSCOO";
export const ENVIAR_CNSC = "enviarProyectoCNSC";

export const ACTUALIZAR_EJECUTA_FORMA_DIRECTA_CNSC = "actualizarEjecutaFormaDirectaCNSCOO";
export const ACTUALIZAR_PARTE_PROGRAMA_CONJUNTO_CNSC = "actualizarParteProgramaConjuntoCNSCOO";
export const ACTUALIZAR_PARTE_COOPERACION_ESTRATEGIA_CNSC = "actualizarParteCooperacionEstrategiaCNSCOO";

export const VINCULAR_ESTRATEGIA_PROYECTO_CNSC = "vincularEstrategiaProyectoCnsc";

export const ACTUALIZAR_CLASIFICACION_OCDE_CNSC = "actualizarClasificacionOCDECNSC";
export const ACTUALIZAR_POLITICA_PUBLICA_CNSC = "actualizarPoliticaPublicaCNSC";
/*********** OBTENER DATOS ESPECIFICOS POR SECCION ***********/

export const OBTENER_DATOS_SECCIONES_PROYECTO_CNSCOO = "obtenerDatosSeccionesProyectoCNSCOO";




/*************************************************************/



//Para la unidad ejecutora
export const OBTENER_PROYECTOS_CNSCOO_EXTERNO = "obtenerProyectoCNSCExterno";
export const OBTENER_PROYECTO_CNSCOO_EXTERNO = "obtenerProyectoCNSCOOExterno";
export const REGISTRAR_PROYECTO_CNSCOO_EXTERNO = "registrarProyectoCNSCOOExterno";
export const ACTUALIZAR_PROYECTO_CNSCOO_EXTERNO = "actualizarProyectoCNSCOOExterno";
export const CONFIRMAR_PROYECTO_CNSCOO = "confirmarProyectoCNSCOO";

export const ACTUALIZAR_CUENTA_CONDICIONES_DESEMOLSOS = "actualizarCuentaCondicionesDesembolsos";
export const ACTUALIZAR_FORMA_OPERAR ="actualizarFormaOperar";
// mutation types
export const SET_PROYECTOS_CNSCOO = "setProyectoCNSC";
export const SET_PROYECTO_CNSCOO = "setInstitucion";
export const SET_DATOS_SECCION_CNSCOO = "setDatosSeccion";

const state = {
    proyectoCNSC: [],
    proyectosCNSC: [],
    datosSeccion:[]
};

const getters = {
  obtnenerProyectoCNSC(state) {
    return state.proyectoCNSC;
  }
};

const proyecto = 
{
    id: 1,
    codigo: "CNSCOO-2022-0001",
    nombreProyecto: "Nombre del proyecto 1",
    nombreInstitucion: "SEGEPLAN",
    nombreUnidadEjecutora: 'Nombre unidad ejecutora 1',
    unidadesEjecutorasId: 1,
    fechaSolicitudOpinionTecnica: '10/01/2022',
    nombreTipoInstrumento: 'Tipo Instrumento 1',
    tiposInstrumentoId: 1,
    tiposFuenteCooperanteId: 1,
    nombreTipoFuenteCooperante: 'Nombre tipo fuente cooperante 1',
    agenciasId: 1,
    paisesId: 1,
    tiposCooperacionId: 1,
    modalidadesEjecucionId: 1,
    descripcionProyecto: 'Esta es una descripción',
    objetivoGeneral: 'Este es el objetivo General',
    objetivoEspecifico1: 'Este es el objetivo Especifico 1',
    objetivoEspecifico2: 'Este es el objetivo Especifico 2',
    objetivoEspecifico3: 'Este es el objetivo Especifico 3',
    sectoresBeneficiadosId: 1,
    correoElectronico: 'correo@segeplan.gob.gt',
    nombreSolicitante: 'Este es el nombre del solicitante',
    pathDocumentoOt: 'ProyectoCNSC/documentos/documentoot01.pdf',
    antecedentes: 'Estos son los antecedentes del proyecto',
    problematica: 'Esta es la problemática',
    justificacion: 'Esta es la justificación del proyecto',
    situacionSinProyecto: 'Esta es la descripción de la situación sin proyecto',
    situacionConProyecto: 'Esta es la descripción de la situación con proyecto',
    riesgo1: 'Este es un ejemplo de riesgo',
    riesgo2: 'Este es un ejemplo de riesgo 2',
    riesgo3: 'Este es un ejemplo de riesgo 3',
    estado: "Seguimiento",
    estadosId: 2,
    fecha: "10/10/2021"
};




/******************************************** SECCION INTERNA SEGEPLAN DASCD /********************************************/
const actions = {
  async [OBTENER_PROYECTOS_CNSCOO](context, parametros) {
    return new Promise(resolve => {
      console.log(parametros  )
      let endpoint = "ProyectoCNSC";
      if(parametros && parametros.estadoProyectoId){
        endpoint = `ProyectoCNSC/all/${parametros.estadoProyectoId}`;
      }
    
        ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, '')
        .then(({ data }) => {
            context.commit(SET_PROYECTOS_CNSCOO, data.data);
            
            resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: error
          })
        });
            
    });
  },

  async [OBTENER_PROYECTO_CNSCOO](context, parametros) {

    let id = parametros.id;
   // console.log(datos)
    
    let endpoint = 'ProyectoCNSC';
   

    return new Promise(resolve => {
      //Quitar
      if (id) context.commit(SET_PROYECTO_CNSCOO, proyecto);

            ApiService.query(`apiconsume/edit/${parametros.id}?endpoint=${endpoint}/`)
                .then(({ data }) => {
                    context.commit(SET_PROYECTO_CNSCOO,data.data);
                    //console.log(this.instituciones)
                    resolve(data)
                })
                .catch((error) => {
                    
                  resolve({
                    status: 500,
                    message: error
                  })
                });
    });
  },

  /**
   * Registrar un proyecto desde la unidad ejecutora
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_PROYECTO_CNSCOO](context, parametros) {

    let datos = parametros.datos;
   // console.log(datos)
    
    let endpoint = 'ProyectoCNSC';
 

     return new Promise(resolve => {
          ApiService.post(`apiconsume/create?endpoint=${endpoint}`, datos)
              .then(({ data }) => {
                  context.commit(SET_PROYECTO_CNSCOO, data.data);
                  resolve(data)
              })
              .catch((error) => {
                resolve({
                  status: 500,
                  message: error
                })
              });
        });   
  },

  async [ACTUALIZAR_PROYECTO_CNSCOO](context, parametros) {
    let id = parametros.datos.id;
    let datos = parametros.datos;

    let endpoint = 'ProyectoCNSC';

    let seccion = parametros.endpoint;
    switch (seccion){
      case 'InformacionAdicional':
        endpoint = "InformacionAdicional"
      break;
      case 'InformacionGeneral' :
        endpoint = "ProyectoCNSC"
        break;
    }
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=${endpoint}/`, datos)
        .then(({ data }) => {
          context.commit(SET_PROYECTO_CNSCOO, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          })
        });
    });
  },


  /******************************************** SECCION EXTERNA UNIDAD EJECUTORA /********************************************/
  async [OBTENER_PROYECTOS_CNSCOO_EXTERNO](context, estadoProyectoId) {

   

    return new Promise(resolve => {
     
        ApiService.query(`apiconsume/obtener?endpoint=ProyectoCNSC/all/Usuario/${estadoProyectoId}`, '')
        .then(({ data }) => {
            context.commit(SET_PROYECTOS_CNSCOO, data.data);
            
            resolve(data)
        })
        .catch((error) => {
          resolve({
            status: 500,
            message: error
          })
            
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },


  async [OBTENER_DATOS_SECCIONES_PROYECTO_CNSCOO](context, parametros){

    //Seccion: define que datos de la sección se requieren
    let seccion = parametros.seccion;

    let endpoint = "ProyectoCNSC"

    let id = parametros.id;

    switch (seccion){
      case 'InformacionAdicional':
        endpoint = "InformacionAdicional"
      break;
      case 'DocumentosProyecto' :
        endpoint = "ProyectoCNSCOOSection5";
        break;
    }

    
    return new Promise(resolve => {
  
      ApiService.query(`apiconsume/edit/${id}?endpoint=${endpoint}/`)
      .then(({ data }) => {
          context.commit(SET_PROYECTO_CNSCOO, data.data);
          
          resolve(data)
      })
      .catch((error) => {
          console.log(error)
          
          //context.commit(SET_ERROR, response.data.errors);
      });
          
    });
  },



  /**
   * Registrar un proyecto CNSCOO desde usuario externo (Unidad ejecutora)
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
   async [REGISTRAR_PROYECTO_CNSCOO_EXTERNO](context, parametros) {

    //let id = parametros.id;
    let datos = parametros.datos;
    // console.log(datos)
    let endpoint = 'ProyectoCNSC';
    //console.log(parametros.seccion);
    let seccion = parametros.seccion;
    switch (seccion){
      case 'InformacionGeneral':
        endpoint = "ProyectoCNSC"
      break;
      case 'Alcance':
        endpoint = "ProyectoCNSCSection3"
      break;
      case 'FechasPlazos' :
        endpoint = "ProyectoCNSCSection4";
        break;
      case 'Documentos' :
        endpoint = "ProyectoCNSCSection5";
        break;
    }
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=${endpoint}`, datos)
                .then(({ data }) => {
                    context.commit(SET_PROYECTO_CNSCOO, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 400,
                      data: error})
                    //context.commit(SET_ERROR, response.data.errors);
                });
        });   
  },


    async [CONFIRMAR_PROYECTO_CNSCOO](context, parametros) {
      return new Promise(resolve => {
      let id = parametros.id;
      let codigo = parametros.codigo;

      context.commit(SET_PROYECTO_CNSCOO, parametros.data);
  
      resolve({
        status: 200,
        message: `Se ha confirmado el proyecto ${codigo} con éxito!` + id,
        data: null
      });
      /*
      ApiService.update(`apiconsume/update/${id}?endpoint=ProyectoCNSCOO/Confirmacion`)
                .then(({ data }) => {
                    context.commit(SET_PROYECTO_CNSCOO, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 400,
                      data: error})
                    //context.commit(SET_ERROR, response.data.errors);
                });*/
        });

  },

  /**
   * Enviar un proyecto a segeplan
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
   async [ENVIAR_CNSC](context, datos) {
    return new Promise(resolve => {
            ApiService.update(`apiconsume/update2?endpoint=ProyectoCNSC/Estado/${datos.id}`, datos)
                .then(({ data }) => {
                    context.commit(SET_PROYECTO_CNSCOO, data.data);
                    resolve(data)
                })
                .catch((error) => {
                    resolve({
                      status: 500,
                      message: error
                  })
                });
        }); 
  },

  


  async [ACTUALIZAR_CUENTA_CONDICIONES_DESEMOLSOS](context, parametros){
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update2?endpoint=ProyectoCNSC/proyecta/desembolsos/${parametros.id}/${parametros.proyectaDesembolsos}`, parametros)
                  .then(({ data }) => {
                      context.commit(SET_PROYECTO_CNSCOO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  //CTUALIZAR EL CAMPO DETALLE FORMA DE OPERAR
  async [ACTUALIZAR_FORMA_OPERAR](context, parametros){
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${parametros.id}?endpoint=ProyectoCNSC/formaoperar/`, parametros.datos)
                  .then(({ data }) => {
                      context.commit(SET_PROYECTO_CNSCOO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  //ACTUALIZAR EL CAMPO EJECUTA DE FORMA DIRECTA
  async [ACTUALIZAR_EJECUTA_FORMA_DIRECTA_CNSC](context, parametros){
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${parametros.id}?endpoint=ProyectoCNSC/EjecutaFD/`, parametros.datos)
                  .then(({ data }) => {
                      context.commit(SET_PROYECTO_CNSCOO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  //ACTUALIZAR EL CAMPO PARTE DE UN PROGRAMA CONJUNTO
  async [ACTUALIZAR_PARTE_PROGRAMA_CONJUNTO_CNSC](context, parametros){
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${parametros.id}?endpoint=ProyectoCNSC/PartePC/`, parametros.datos)
                  .then(({ data }) => {
                      context.commit(SET_PROYECTO_CNSCOO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  //ACTUALIZAR Proyecto es parte del marco de cooperación/estrategia/lineamiento
  async [ACTUALIZAR_PARTE_COOPERACION_ESTRATEGIA_CNSC](context, parametros){
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${parametros.id}?endpoint=ProyectoCNSC/ParteDeCooperacion/`, parametros)
                  .then(({ data }) => {
                      context.commit(SET_PROYECTO_CNSCOO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  //VINCULAR LA ESTRATEGIA A UN PROYECTO CNSC 
  async [VINCULAR_ESTRATEGIA_PROYECTO_CNSC](context, parametros){
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${parametros.id}?endpoint=ProyectoCNSC/Estrategia/`, parametros)
                  .then(({ data }) => {
                      context.commit(SET_PROYECTO_CNSCOO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  //VINCULAR LA CLASIFICACION OCDE CNSC 
  async [ACTUALIZAR_CLASIFICACION_OCDE_CNSC](context, parametros){
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${parametros.id}?endpoint=ProyectoCNSC/CRS/`, parametros)
                  .then(({ data }) => {
                      context.commit(SET_PROYECTO_CNSCOO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  //VINCULAR LA POLITICA PUBLICA CNSC 
  async [ACTUALIZAR_POLITICA_PUBLICA_CNSC](context, parametros){
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${parametros.id}?endpoint=ProyectoCNSC/PoliticaPublica/`, parametros.datos)
                  .then(({ data }) => {
                      context.commit(SET_PROYECTO_CNSCOO, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


  


  
  
};

const mutations = {
  [SET_PROYECTOS_CNSCOO](state, data) {
    state.proyectosCNSC = data;
  },

  [SET_PROYECTO_CNSCOO](state, data) {
    state.proyectoCNSCOO = data;
  },

  [SET_DATOS_SECCION_CNSCOO](state, data){
    state.datosSeccion = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
