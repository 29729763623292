//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_OBJETIVOS_SECTORIALES_PGG =
  "obtenerObjetivosSectorialesPGG";
export const ELIMINAR_OBJETIVO_SECTORIAL_PGG =
 "eliminarObjetivoSectorialPGG";
export const OBTENER_OBJETIVO_SECTORIAL_PGG = "obtenerObjetivoSectorialPGG";
export const REGISTRAR_OBJETIVO_SECTORIAL_PGG =
 "registrarObjetivoSectorialPGG";
export const ACTUALIZAR_OBJETIVO_SECTORIAL_PGG =
  "actualizarObjetivoSectorialPGG";

// mutation types
export const SET_OBJETIVO_SECTORIAL_PGG = "setObjetivosSectorialesPGG";
export const SET_OBJETIVOS_SECTORIALES_PGG = "setObjetivoSectorialPGG";

const state = {
  objetivosSectorialesPGG: {},
  objetivoSectorialPGG: []
};

const getters = {
  obtnenerObjetivosSectorialesPGG(state) {
    return state.ObjetivosSectorialesPGG;
  }
};

const actions = {
  async [OBTENER_OBJETIVOS_SECTORIALES_PGG](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=ObjetivosSectorialesPGG/all/${parametros.estadoId}/${parametros.objetivoEstrategicoPGGId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_OBJETIVO_SECTORIAL_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_OBJETIVO_SECTORIAL_PGG](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=ObjetivosSectorialesPGG/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_OBJETIVOS_SECTORIALES_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_OBJETIVO_SECTORIAL_PGG](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=ObjetivosSectorialesPGG/`)
        .then(({ data }) => {
          context.commit(SET_OBJETIVOS_SECTORIALES_PGG, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una Pggs directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_OBJETIVO_SECTORIAL_PGG](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=ObjetivosSectorialesPGG`, datos)
        .then(({ data }) => {
          context.commit(SET_OBJETIVOS_SECTORIALES_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_OBJETIVO_SECTORIAL_PGG](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(`apiconsume/update/${id}?endpoint=ObjetivosSectorialesPGG/`, datos)
        .then(({ data }) => {
          context.commit(SET_OBJETIVOS_SECTORIALES_PGG, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_OBJETIVO_SECTORIAL_PGG](state, data) {
    state.objetivoSectorialPGG = data;
  },

  [SET_OBJETIVOS_SECTORIALES_PGG](state, data) {
    state.objetivosSectorialesPGG = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
