//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_CONTACTOS = "obtenerContactos";
export const REGISTRAR_CONTACTO = "registrarContacto";
export const OBTENER_DATOS_CONTACTO = "obtenerDatosContactoCNS";
export const ACTUALIZAR_CONTACTO = "actualizarDatosContacto";
export const ELIMINAR_CONTACTO = "eliminarContacto";
export const SET_CONTACTOS = "setContactos";
export const SET_CONTACTO = "setContacto";

const state = {
    contactos: [],
    contacto: []
};

const getters = {
  obtnenerContactos(state) {
    return state.contactos;
  }
};


const actions = {

    async [OBTENER_CONTACTOS](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
            let estadoId = parametros.estadoId;
            let tipoContacto = parametros.tipoContacto;
     
            ApiService.query(`apiconsume/obtener?endpoint=/Contactos/all/${tipoContacto}/${id}/${estadoId}`)
            .then(({ data }) => {
                context.commit(SET_CONTACTOS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar un contacto
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_CONTACTO](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=Contactos`, datos)
            .then(({ data }) => {
                context.commit(SET_CONTACTO, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },


  
    /**
     * Obtener contacto especifico
     * @param {*} context 
     * @param {*} parametros 
     * @returns 
     */
    async [OBTENER_DATOS_CONTACTO](context, id){
      return new Promise(resolve => {     
        ApiService.query(`apiconsume/edit/${id}?endpoint=Contactos/`)
        .then(({ data }) => {
          if(data.status===200){
            context.commit(SET_CONTACTO, data.data);
            
          } else {
            context.commit(SET_CONTACTO, {});
          }
          resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
            
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },



     /**
   *  //Actualizar datos del contacto
   * @param {*} context
   * @param {*} iaffId
   * @returns
   */
     async [ACTUALIZAR_CONTACTO](context, parametros) {
      let datos = parametros.datos;
      return new Promise(resolve => {
         ApiService.update(`apiconsume/update2?endpoint=Contactos/${datos.id}`, datos)
             .then(({ data }) => {
                 context.commit(SET_CONTACTO, data.data);
                 resolve(data)
             })
             .catch((error) => {
                 resolve({
                     status: 400,
                     message: error.message
                 });
             });
         });   
     },

  
  async [ELIMINAR_CONTACTO](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=Contactos/`)
          .then(({ data }) => {
              context.commit(SET_CONTACTO, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
}

};

const mutations = {
  [SET_CONTACTOS](state, data) {
    state.contactos = data;
  },

  [SET_CONTACTO](state, data) {
    state.contacto = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

