//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const REGISTRAR_PLANIFICACION_ANUAL_CNS = "registrarPoaCns";
export const OBTENER_PLANIFICACIONES_ANUALES_CNS = "obtenerPoasCns";
export const OBTENER_PLANIFICACIONES_ANUALES_PERIODO_CNS =
  "obtenerPoaPeriodoCns";
export const ACTUALIZAR_POA_CNS = "actualizarPoaCns";
export const OBTENER_POA_CNS = "obtenerPoaCns";

export const OBTENER_PLANIFICACION_ANUAL_CNS = "obtenerProgramacionAnualCns";
export const ACTUALIZAR_MONTO_ASIGNADO = "actualizarMontoAsignado";

export const REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CNS =
  "registrarPlanificacionAnualActividadesCns";

export const OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CNS =
  "obtenerPlanificacionesAnualesActividadesCns";

export const ELIMINAR_SUBDIVISION_FC_PLANFICACION_ANUAL_CNS =
  "eliminarSubdivisionFCPlanificacionAnualCns";

export const REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CONTRAPARTIDA_CNS =
  "registrarPlanificacionAnulaContrapartidaCns";
export const OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CONTRAPARTIDA_CNS =
  "obtenerPlanificacionesAnualesActividadesContrapartidaCns";

export const ELIMINAR_SUBDIVISION_CP_PLANFICACION_ANUAL_CNS =
  "eliminarSubdivisionCPPlanificacionAnualCns";

export const OBTENER_PLANIFICACIONES_ANUALES_CUATRIMESTRE_CNS =
  "obtenerPlanificacionesAnualesCuatrimestre";

export const REGISTRAR_PLANIFICACION_ANUAL_CUATRIMESTRE_CNS =
  "registrarPlanificacionAnualCuatrimestre";

export const ELIMINAR_PROYECCION_CUATRIMESTRE_PLANFICACION_ANUAL_CNS =
  "eliminarProyeccionCuatrimestrePlanificacionAnualCns";

export const REGISTRAR_ASIGNADO_ANUAL_POA_CNS = "registrarAsignadoAnualPoaCns";

export const CONFIRMAR_PLANIFICACION_CNS = "confirmarPlanificacionCns";

export const SET_POA_CNS = "setPoaCns";
export const SET_POAS_CNS = "setPoasCns";

const state = {
  poasCns: [],
  poaCns: []
};

const getters = {
  obtnenerPoasCns(state) {
    return state.poasCns;
  }
};

const actions = {
  /**
   * Obtener las planificaciones (periodos) anuales de un proyecto cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_PLANIFICACIONES_ANUALES_CNS](context, parametros) {
    return new Promise(resolve => {
      let proyectoId = parametros.proyectoId;
      let estadoId = parametros.estadoId;

      ApiService.query(
        `apiconsume/obtener?endpoint=/ProgramacionAnualCns/all/${estadoId}/${proyectoId}`
      )
        .then(({ data }) => {
          context.commit(SET_POAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Obtener planificacion (periodos) anual por id
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_PLANIFICACION_ANUAL_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=/ProgramacionAnualCns/${id}`
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Obtener las planificaciones (periodos) anuales de un proyecto cns por estado y periodo
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_PLANIFICACIONES_ANUALES_PERIODO_CNS](context, parametros) {
    return new Promise(resolve => {
      let proyectoId = parametros.proyectoId;
      let estadoId = parametros.estadoId;
      let periodoId = parametros.periodoId;
      ApiService.query(
        `apiconsume/obtener?endpoint=/ProgramacionAnualCns/periodo/${estadoId}/${proyectoId}/${periodoId}`
      )
        .then(({ data }) => {
          context.commit(SET_POAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar programacion anual cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_PLANIFICACION_ANUAL_CNS](context, parametros) {
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=ProgramacionAnualCns`, datos)
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  ///////////// SUBSECCION 1 DE PLANIFICACION  ACTIVIDADES ////////////////////////

  /**
   * Obtener las planificaciones anuales de un proyecto cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CNS](context, parametros) {
    return new Promise(resolve => {
      let programacionAnualId = parametros.programacionAnualId;
      let estadoId = parametros.estadoId;
      ApiService.query(
        `apiconsume/obtener?endpoint=/ProgramacionAnualActividades/all/${estadoId}/${programacionAnualId}`
      )
        .then(({ data }) => {
          context.commit(SET_POAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar programacion anual cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CNS](context, parametros) {
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProgramacionAnualActividades`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Obtener las planificaciones anuales de un proyecto cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_PLANIFICACIONES_ANUALES_ACTIVIDADES_CONTRAPARTIDA_CNS](
    context,
    parametros
  ) {
    return new Promise(resolve => {
      let programacionAnualId = parametros.programacionAnualId;
      let estadoId = parametros.estadoId;
      ApiService.query(
        `apiconsume/obtener?endpoint=/ProgramacionAnualContrapartida/all/${estadoId}/${programacionAnualId}`
      )
        .then(({ data }) => {
          context.commit(SET_POAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar programacion anual para contrapartida cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_PLANIFICACION_ANUAL_ACTIVIDADES_CONTRAPARTIDA_CNS](
    context,
    parametros
  ) {
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProgramacionAnualContrapartida`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  ///////////// SUBSECCION 2 DE PLANIFICACION CUATRIMESTRE////////////////////////

  /**
   * Obtener las planificaciones anuales de un proyecto cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [OBTENER_PLANIFICACIONES_ANUALES_CUATRIMESTRE_CNS](
    context,
    parametros
  ) {
    return new Promise(resolve => {
      let proyectoId = parametros.proyectoId;
      let estadoId = parametros.estadoId;
      ApiService.query(
        `apiconsume/obtener?endpoint=/ProgramacionAnualCNSCuatrimestre/all/${estadoId}/${proyectoId}`
      )
        .then(({ data }) => {
          context.commit(SET_POAS_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar programacion anual cns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_PLANIFICACION_ANUAL_CUATRIMESTRE_CNS](context, parametros) {
    let datos = parametros.datos;
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=ProgramacionAnualCNSCuatrimestre`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [REGISTRAR_ASIGNADO_ANUAL_POA_CNS](context, datos) {
    let id = datos.programacionAnualCNSId;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=ProgramacionAnualCns/AsignacionAnual/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [ACTUALIZAR_MONTO_ASIGNADO](context, datos) {
    let id = datos.programacionAnualCNSId;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=Instituciones/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  async [CONFIRMAR_PLANIFICACION_CNS](context, datos) {
    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${datos.estadoId}?endpoint=ProgramacionAnualCns/ActualizarEstado/${datos.id}/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Eliminar subdivision Fuente Cooperante POA
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_SUBDIVISION_FC_PLANFICACION_ANUAL_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=ProgramacionAnualActividades/`
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Eliminar subdivision Contrapartida POA
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_SUBDIVISION_CP_PLANFICACION_ANUAL_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=ProgramacionAnualContrapartida/`
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

  /**
   * Eliminar Proyeccion anual cuatrimestre del POA
   * @param {*} context
   * @param {*} parametros
   * @returns
   */

  async [ELIMINAR_PROYECCION_CUATRIMESTRE_PLANFICACION_ANUAL_CNS](context, id) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${id}?endpoint=ProgramacionAnualCNSCuatrimestre/`
      )
        .then(({ data }) => {
          context.commit(SET_POA_CNS, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 400,
            message: error.message
          });
        });
    });
  },

};

const mutations = {
  [SET_POAS_CNS](state, data) {
    state.poasCns = data;
  },

  [SET_POA_CNS](state, data) {
    state.poaCns = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
