//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_ROLES_ACTORES_CSS = "obtenerRolesActoresCSS";
export const REGISTRAR_ROL_ACTOR_CSS = "registrarRolActorCSS";
export const ELIMINAR_ROL_ACTOR_CSS = "eliminarRolActorCSS";

export const SET_ROLES_ACTORES_CSS = "setRolesActoresCSS";
export const SET_ROL_ACTOR_CSS = "setRolActorCSS";

const state = {
    actoresRoles: [],
    actorRol: []
};

const getters = {
  obtneneractoresRolesCns(state) {
    return state.actoresRoles;
  }
};


const actions = {

    async [OBTENER_ROLES_ACTORES_CSS](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
     
            ApiService.query(`apiconsume/obtener?endpoint=AsignacionRolesActor/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_ROLES_ACTORES_CSS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar un actorRol
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_ROL_ACTOR_CSS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=AsignacionRolesActor`, datos)
            .then(({ data }) => {
                context.commit(SET_ROL_ACTOR_CSS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  async [ELIMINAR_ROL_ACTOR_CSS](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=AsignacionRolesActor/`)
          .then(({ data }) => {
              context.commit(SET_ROL_ACTOR_CSS, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
  }

};

const mutations = {
  [SET_ROLES_ACTORES_CSS](state, data) {
    state.actoresRoles = data;
  },

  [SET_ROL_ACTOR_CSS](state, data) {
    state.actorRol = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

