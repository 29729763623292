//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_MUNICIPIOS = "obtenerMunicipios";
export const OBTENER_MUNICIPIOS_DEPARTAMENTO = "obtenerMunicipiosmunicipios";
export const OBTENER_MUNICIPIO = "obtenerMunicipio";
export const REGISTRAR_MUNICIPIO = "registrarMunicipio";
export const ACTUALIZAR_MUNICIPIO = "actualizarMunicipio";

// mutation types
export const SET_MUNICIPIOS = "setMunicipios";
export const SET_MUNICIPIO = "setMunicipio";

const state = {
    municipios: [],
    municipio: []
};

const getters = {
  obtnenerMunicipios(state) {
    return state.municipios;
  }
};


const actions = {

  //Obtener municipios por departamento
  async [OBTENER_MUNICIPIOS_DEPARTAMENTO](context, deptoId){
    return new Promise(resolve => {

      ApiService.query(`apiconsume/obtener?endpoint=Municipios/all/1/${deptoId}`)
        .then(({ data }) => {
          context.commit(SET_MUNICIPIOS, data.data);

          resolve(data);
        })
        .catch(error => {
            resolve({
                status: 500,
                message: `Ha ocurrido un error en la transacción`,
                data: error
            });
        });
    });

  },

  /*
  async [OBTENER_MUNICIPIO](context, id) {
    return new Promise(resolve => {

      context.commit(SET_MUNICIPIO, municipio);

      resolve({
        status: 200,
        message: "Ok",
        others: id,
        data: municipio
      });

      
      ApiService.query(`apiconsume/edit/${id}?endpoint=api/Municipios/${id}`)
        .then(({ data }) => {
          context.commit(SET_MUNICIPIO, data.data);
          //console.log(this.unidadesEjecutoras)
          resolve(data);
        })
        .catch(error => {
          resolve(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },*/

 

  /**
   * Registrar una municipio
   * @param {*} context
   * @param {*} datosmunicipio
   * @returns
   */
  /*async [REGISTRAR_MUNICIPIO](context, datosmunicipio) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=UnidadesEjecutoras`,
        datosmunicipio
      )
        .then(({ data }) => {
          context.commit(SET_MUNICIPIO, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_MUNICIPIO](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=UnidadesEjecutoras/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_MUNICIPIO, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }*/
};

const mutations = {
  [SET_MUNICIPIOS](state, data) {
    state.municipios = data;
  },

  [SET_MUNICIPIO](state, data) {
    state.municipio = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
