//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_TIPOS_APROBACION_DONACION = "obtenerTiposAprobacionDonacions";
export const ELIMINAR_TIPO_APROBACION_DONACION = "eliminarTipoAprobacionDonacion";
export const OBTENER_TIPO_APROBACION_DONACION = "obtenerTipoAprobacionDonacion";
export const REGISTRAR_TIPO_APROBACION_DONACION = "registrarTipoAprobacionDonacion";
export const ACTUALIZAR_TIPO_APROBACION_DONACION = "actualizarTipoAprobacionDonacion";

// mutation types
export const SET_TIPOS_APROBACION_DONACION = "setTiposAprobacionDonacions";
export const SET_TIPO_APROBACION_DONACION = "setTipoAprobacionDonacion";

const state = {
  tipoAprobacionDonacion: {},
  tiposAprobacionDonacions: []
};

const getters = {
  obtnenerTiposAprobacionDonacion(state) {
    return state.tiposAprobacionDonacion;
  }
};

const actions = {
  async [OBTENER_TIPOS_APROBACION_DONACION](context, parametros) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/obtener?endpoint=TiposAprobacionDonacion/all/${parametros.estadoId}`,
        ""
      )
        .then(({ data }) => {
          context.commit(SET_TIPOS_APROBACION_DONACION, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ELIMINAR_TIPO_APROBACION_DONACION](context, datos) {
    return new Promise(resolve => {
      ApiService.delete(
        `apiconsume/delete/${datos.nuevoEstadoId}?endpoint=TiposAprobacionDonacion/${datos.id}/`
      )
        .then(({ data }) => {
          context.commit(SET_TIPO_APROBACION_DONACION, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [OBTENER_TIPO_APROBACION_DONACION](context, id) {
    return new Promise(resolve => {
      ApiService.query(
        `apiconsume/edit/${id}?endpoint=TiposAprobacionDonacion/`
      )
        .then(({ data }) => {
          context.commit(SET_TIPO_APROBACION_DONACION, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una TiposAprobacionDonacions directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_TIPO_APROBACION_DONACION](context, datos) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=TiposAprobacionDonacion`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_TIPO_APROBACION_DONACION, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_TIPO_APROBACION_DONACION](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=TiposAprobacionDonacion/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_TIPO_APROBACION_DONACION, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
            status: 500,
            message: error
          });
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_TIPOS_APROBACION_DONACION](state, data) {
    state.tiposAprobacionDonacions = data;
  },

  [SET_TIPO_APROBACION_DONACION](state, data) {
    state.tipoAprobacionDonacion = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
