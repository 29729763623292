//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_SEGUIMIENTOS_CNS_EXPECIALISTA = "obtenerSeguimientosCns";
export const OBTENER_SEGUIMIENTO_CNS_ADMINISTRADOR = "obtenerSeguimientoCnsAdministrador";
export const OBTENER_SEGUIMIENTO_CNS = "obtenerSeguimientoCns";
export const REGISTRAR_SEGUIMIENTO_CNS = "registrarSeguimientoCns";
export const ACTUALIZAR_SEGUIMIENTO_CNS = "actualizarSeguimientoCns";
export const ELIMINAR_SEGUIMIENTO_CNS = "eliminarSeguimientoCns";

export const SET_SEGUIMIENTOS_CNS = "setSeguimientosCnsCns";
export const SET_SEGUIMIENTO_CNS = "setSeguimientoCns";

const state = {
    seguimientosCns: [],
    seguimientoCns: []
};

const getters = {
  obtnenerSeguimientosCns(state) {
    return state.seguimientosCns;
  }
};


const actions = {

    /*
    * Obtener los seguimientos asignados al usuario
    */
    async [OBTENER_SEGUIMIENTOS_CNS_EXPECIALISTA](context, parametros){
        return new Promise(resolve => {

            let id = parametros.proyectoId;
     
            ApiService.query(`apiconsume/obtener?endpoint=SeguimientoCns/Especialista/0/${id}`)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTOS_CNS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar un seguimientoCns
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_SEGUIMIENTO_CNS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=SeguimientoCns`, datos)
            .then(({ data }) => {
                context.commit(SET_SEGUIMIENTO_CNS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  async [ACTUALIZAR_SEGUIMIENTO_CNS](context, parametros){
    return new Promise(resolve => {
      ApiService.update(`apiconsume/update2?endpoint=SeguimientoCns/${parametros.id}`, parametros)
                  .then(({ data }) => {
                      context.commit(SET_SEGUIMIENTO_CNS, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 400,
                        data: error})
                      //context.commit(SET_ERROR, response.data.errors);
                  });
      });   
  },


      /*
    *Obtener un seguimiento específico
    */
    async [OBTENER_SEGUIMIENTO_CNS](context, id){

      return new Promise(resolve => {
              ApiService.query(`apiconsume/obtener?endpoint=SeguimientoCns/${id}`)
                  .then(({ data }) => {
                      context.commit(SET_SEGUIMIENTO_CNS, data.data);
                      resolve(data)
                  })
                  .catch((error) => {
                      resolve({
                        status: 500,
                        message: error
                    })
                  });
          });
    },



  

   /*
  * Eliminar un seguimientoCns
  */
   async [ELIMINAR_SEGUIMIENTO_CNS](context, id){
    
    return new Promise(resolve => {
    ApiService.delete(`apiconsume/delete/${id}?endpoint=SeguimientoCns/`)
        .then(({ data }) => {
            context.commit(SET_SEGUIMIENTO_CNS, data.data);
            resolve(data)
        })
        .catch((error) => {
            resolve({
                status: 400,
                message: error.message
            });
        });
    });
},  

};

const mutations = {
  [SET_SEGUIMIENTOS_CNS](state, data) {
    state.seguimientosCns = data;
  },

  [SET_SEGUIMIENTO_CNS](state, data) {
    state.seguimientoCns = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

