//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_AGENCIAS_MULTILATERALES = "obtenerAgenciasMultilaterales";
export const OBTENER_AGENCIA_MULTILATERAL = "obtenerAgenciaMultilateral";
export const REGISTRAR_AGENCIA_MULTILATERAL = "registrarAgenciaMultilateral";
export const ACTUALIZAR_AGENCIA_MULTILATERAL = "actualizarAgenciaMultilateral";

// mutation types
export const SET_AGENCIAS_MULTILATERALES = "setAgenciasMultilaterales";
export const SET_AGENCIA_MULTILATERAL = "setAgenciasBilateral";

const state = {
  agenciasMultilaterales: [],
  agenciaMultilateral: []
};

const getters = {
  obtnenerAgenciasMultilaterales(state) {
    return state.agenciasMultilaterales;
  }
};


const actions = {
  async [OBTENER_AGENCIAS_MULTILATERALES](context, parametros) {
    return new Promise(resolve => {
        
        let endpoint = `AgenciaMultilateral`;
        if(parametros){
            endpoint = `AgenciaMultilateral/all/${parametros.estadoId}/${parametros.fuentesMultilateralesId}`;
        }
       // console.log(endpoint)


      ApiService.query(`apiconsume/obtener?endpoint=${endpoint}`, "")
        .then(({ data }) => {
          context.commit(SET_AGENCIAS_MULTILATERALES, data.data);

          resolve(data);
        })
        .catch(error => {
          resolve({
              status: 400,
              message: error.message
          });

          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },


  
  async [OBTENER_AGENCIA_MULTILATERAL](context, id) {
    return new Promise(resolve => {

      //context.commit(SET_AGENCIA_MULTILATERAL, AgenciaMultilateral);

      

      
      ApiService.query(`apiconsume/edit/${id}?endpoint=AgenciaMultilateral/`)
        .then(({ data }) => {
          context.commit(SET_AGENCIA_MULTILATERAL, data.data);
          //console.log(this.AgenciasMultilaterales)
          resolve(data);
        })
        .catch(error => {
          resolve({
          status: 500,
          message:error,
          others: id,
          data: error
        });
        });
    });
  },

 

  /**
   * Registrar una institución
   * @param {*} context
   * @param {*} datosAgenciaMultilateral
   * @returns
   */
  async [REGISTRAR_AGENCIA_MULTILATERAL](context, datosAgenciaMultilateral) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=AgenciaMultilateral`,
        datosAgenciaMultilateral
      )
        .then(({ data }) => {
          context.commit(SET_AGENCIA_MULTILATERAL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
              status: 400,
              message: error.message
          });
        });
    });
  },

  async [ACTUALIZAR_AGENCIA_MULTILATERAL](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=AgenciaMultilateral/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_AGENCIA_MULTILATERAL, data.data);
          resolve(data);
        })
        .catch(error => {
          resolve({
              status: 400,
              message: error.message
          });
        });
    });
  }
};

const mutations = {
  [SET_AGENCIAS_MULTILATERALES](state, data) {
    state.agenciasMultilaterales = data;
  },

  [SET_AGENCIA_MULTILATERAL](state, data) {
    state.agenciaMultilateral = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
