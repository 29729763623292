//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_MODALIDADES = "obtenerModalidades";
export const OBTENER_MODALIDAD = "obtenerModalidad";
export const REGISTRAR_MODALIDAD = "registrarModalidad";
export const ACTUALIZAR_MODALIDAD = "actualizarModalidad";

// mutation types
export const SET_MODALIDADES = "setModalidades";
export const SET_MODALIDAD = "setModalidad";

const state = {
  modalidades: [],
  modalidad: []
};

const getters = {
  obtnenerModalidades(state) {
    return state.modalidades;
  }
};


const actions = {
  async [OBTENER_MODALIDADES](context) {
    return new Promise(resolve => {
      
        ApiService.query("apiconsume/obtener?endpoint=ModalidadEjecucionCSS/all/1", '')
        .then(({ data }) => {
            context.commit(SET_MODALIDADES, data.data);
            
            resolve(data)
        })
        .catch((error) => {
            resolve({
              status: 500,
              message: error
            })
            
            //context.commit(SET_ERROR, response.data.errors);
        });
            
    });
  },

  async [OBTENER_MODALIDAD](context, id) {
    return new Promise(resolve => {
      ApiService.query(`apiconsume/edit/${id}?endpoint=api/Institucion/`)
        .then(({ data }) => {
          context.commit(SET_MODALIDAD, data.data);
          //console.log(this.instituciones)
          resolve(data);
        })
        .catch(error => {
          resolve(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Registrar una pais directa
   * @param {*} context
   * @param {*} datosInstitucion
   * @returns
   */
  async [REGISTRAR_MODALIDAD](context, datosInstitucion) {
    //console.log(datosEmpresa)
    return new Promise(resolve => {
      ApiService.post(
        `apiconsume/create?endpoint=api/Institucion`,
        datosInstitucion
      )
        .then(({ data }) => {
          context.commit(SET_MODALIDAD, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  async [ACTUALIZAR_MODALIDAD](context, datos) {
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=api/Institucion/`,
        datos.datosInstitucion
      )
        .then(({ data }) => {
          context.commit(SET_MODALIDAD, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_MODALIDADES](state, data) {
    state.modalidades = data;
  },

  [SET_MODALIDAD](state, data) {
    state.modalidad = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
