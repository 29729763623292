//import axios from "axios";
import ApiService from "@/core/services/api.service";

// action types
export const OBTENER_INSTITUCIONES_NACIONALES_CSS = "obtenerInstitucionesNacionalesCss";
export const REGISTRAR_INSTITUCION_NACIONAL_CSS = "registrarInstitucionNacionalCss";
export const ELIMINAR_INSTITUCION_NACIONAL_CSS = "eliminarInstitucionNacionalCss";
export const SET_INSTITUCIONES_NACIONALES_CSS = "setInstitucionesNacional";
export const SET_INSTITUCION_NACIONAL_CSS = "setInstitucionNacional";

const state = {
    institucionesNacionales: [],
    institucionNacional: []
};

const getters = {
  obtnenerInstitucionesNacionalesCSS(state) {
    return state.institucionesNacionales;
  }
};


const actions = {

    async [OBTENER_INSTITUCIONES_NACIONALES_CSS](context, parametros){
        return new Promise(resolve => {

            let id = parametros.id;
     
            ApiService.query(`apiconsume/obtener?endpoint=InstitucionesIniciativas/all/1/${id}`)
            .then(({ data }) => {
                context.commit(SET_INSTITUCIONES_NACIONALES_CSS, data.data);
                
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
                
                //context.commit(SET_ERROR, response.data.errors);
            });
                
        });
    },


   /**
   * Registrar un institucionNacional
   * @param {*} context
   * @param {*} parametros
   * @returns
   */
  async [REGISTRAR_INSTITUCION_NACIONAL_CSS](context, parametros) {

    let datos = parametros.datos;
     

    return new Promise(resolve => {
        ApiService.post(`apiconsume/create?endpoint=InstitucionesIniciativas`, datos)
            .then(({ data }) => {
                context.commit(SET_INSTITUCION_NACIONAL_CSS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });   
  },

  async [ELIMINAR_INSTITUCION_NACIONAL_CSS](context, id){
    
      return new Promise(resolve => {
        ApiService.delete(`apiconsume/delete/${id}?endpoint=InstitucionesIniciativas/`)
            .then(({ data }) => {
                context.commit(SET_INSTITUCION_NACIONAL_CSS, data.data);
                resolve(data)
            })
            .catch((error) => {
                resolve({
                    status: 400,
                    message: error.message
                });
            });
    });  
  }

};

const mutations = {
  [SET_INSTITUCIONES_NACIONALES_CSS](state, data) {
    state.institucionesNacionales = data;
  },

  [SET_INSTITUCION_NACIONAL_CSS](state, data) {
    state.institucionNacional = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

