//import axios from "axios";
import ApiService from "@/core/services/api.service";
// action types
export const OBTENER_CONDICIONES_DESEMBOLSOS = "obtenerCondicionesDesembolsos";
export const REGISTRAR_CONDICION_DESEMBOLSO = "registrarCondicionDesembolso";
export const ACTUALIZAR_CONDICION_DESEMBOLSO = "actualizarCondicionDesembolso";
export const ELIMINAR_CONDICION_DESEMBOLSO ="eliminarCondicionDesembolso";

// mutation types
export const SET_CONDICIONES_DESEMBOLSOS = "setCondicionesDesembolsos";
export const SET_CONDICION_DESEMBOLSO = "setCondicionDesembolso";

const state = {
  condicionesDesembolsos: [],
  condicionDesembolso: []
};

const getters = {
  obtnenerCondicionesDesembolsos(state) {
    return state.condicionesDesembolsos;
  }
};


const actions = {
  //Obtener las condiciones de desembolso por proyecto cns id
  async [OBTENER_CONDICIONES_DESEMBOLSOS](context, parametros) {
    return new Promise(resolve => {

      ApiService.query(`apiconsume/obtener?endpoint=CondicionesDesembolsos/all/${parametros.estadoId}/${parametros.proyectoId}`)
      .then(({ data }) => {
          context.commit(SET_CONDICIONES_DESEMBOLSOS, data.data);
          
          resolve(data)
      })
      .catch((error) => {
          resolve({
              status: 400,
              message: error.message
          });
          
          //context.commit(SET_ERROR, response.data.errors);
      });
          
  });
  },


  /**
   * Registrar una condicion de desembolso
   * @param {*} context
   * @param {*} datos
   * @returns
   */
  async [REGISTRAR_CONDICION_DESEMBOLSO](context, datos) {
    return new Promise(resolve => {
      ApiService.post(`apiconsume/create?endpoint=CondicionesDesembolsos`, datos)
          .then(({ data }) => {
              context.commit(SET_CONDICION_DESEMBOLSO, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });   
  },

  async [ACTUALIZAR_CONDICION_DESEMBOLSO](context, datos) {
    
    let id = datos.id;

    return new Promise(resolve => {
      ApiService.update(
        `apiconsume/update/${id}?endpoint=CondicionesDesembolsos/`,
        datos
      )
        .then(({ data }) => {
          context.commit(SET_CONDICION_DESEMBOLSO, data.data);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          //context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  /**
   * Eliminar una condición de desembolso
   */
  
  async [ELIMINAR_CONDICION_DESEMBOLSO](context, id){
    
    return new Promise(resolve => {
      ApiService.delete(`apiconsume/delete/${id}?endpoint=CondicionesDesembolsos/`)
          .then(({ data }) => {
              context.commit(SET_CONDICION_DESEMBOLSO, data.data);
              resolve(data)
          })
          .catch((error) => {
              resolve({
                  status: 400,
                  message: error.message
              });
          });
  });  
  
},
}

const mutations = {
  [SET_CONDICIONES_DESEMBOLSOS](state, data) {
    state.condicionesDesembolsos = data;
  },

  [SET_CONDICION_DESEMBOLSO](state, data) {
    state.condicionDesembolso = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
